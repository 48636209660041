var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var AK,CK,EK,HK,KK;$CLJS.uK=function(a){return $CLJS.Hh($CLJS.q($CLJS.eB),a,$CLJS.kF)};$CLJS.vK=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.wK=new $CLJS.M(null,"row","row",-570139521);$CLJS.xK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);$CLJS.yK=new $CLJS.M(null,"column-ref","column-ref",2018188376);$CLJS.zK=new $CLJS.M(null,"object-id","object-id",-754527291);
AK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.BK=new $CLJS.M(null,"operators","operators",-2064102509);CK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.DK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);EK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);
$CLJS.FK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.GK=new $CLJS.M(null,"dimensions","dimensions",-254818097);HK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.IK=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.JK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);KK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);
$CLJS.LK=new $CLJS.M(null,"null","null",-180137709);$CLJS.MK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(CK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,$CLJS.tD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mF,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.XF],null)],null)],null));$CLJS.Y(KK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,CK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pA,$CLJS.xD],null)],null)],null));$CLJS.Y(EK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.gF],null),KK,$CLJS.oD],null));
$CLJS.Y(HK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.gF],null),CK,$CLJS.cK],null));
$CLJS.FF.g($CLJS.gF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.gF],null),CK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,$CLJS.cK,$CLJS.oD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,2,[$CLJS.ui,function(a){return $CLJS.rd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.VA(a)):null},$CLJS.ps,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.hA,HK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gA,EK],null)],null)],null));$CLJS.mE($CLJS.gF,$CLJS.kF);$CLJS.ID.m(null,$CLJS.gF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.vz($CLJS.mi,$CLJS.pA)(b);return $CLJS.n(a)?a:$CLJS.VD});$CLJS.tE($CLJS.mA,$CLJS.H([$CLJS.oD]));$CLJS.ID.m(null,$CLJS.mA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.vz($CLJS.mi,$CLJS.pA)(b);return $CLJS.n(a)?a:$CLJS.VD});
$CLJS.mE($CLJS.mA,$CLJS.kF);$CLJS.Y(AK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,$CLJS.tD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.oD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.oD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xK,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.oD],null)],null)],null));
$CLJS.FF.g($CLJS.WE,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.WE],null),AK,$CLJS.sj],null));$CLJS.ID.m(null,$CLJS.WE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.vz($CLJS.mi,$CLJS.pA)(b);return $CLJS.n(a)?a:$CLJS.VD});$CLJS.mE($CLJS.WE,$CLJS.kF);$CLJS.tE($CLJS.EE,$CLJS.H([$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JJ],null)],null)]));$CLJS.mE($CLJS.EE,$CLJS.kF);
$CLJS.tE($CLJS.bF,$CLJS.H([$CLJS.Vr,$CLJS.VD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.HJ],null)],null)]));$CLJS.mE($CLJS.bF,$CLJS.kF);
$CLJS.Y($CLJS.kF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.KD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.As,function(){return["Valid reference, must be one of these clauses: ",$CLJS.ds(", ",$CLJS.yz($CLJS.Dd,$CLJS.vk.g($CLJS.q($CLJS.eB),$CLJS.kF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.uK(a)}],null)],null));