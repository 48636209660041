var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.util.js");require("./metabase.lib.util.js");require("./metabase.util.log.js");
'use strict';var l9,k9,dya,eya,fya,s9,gya,hya,iya,u9,jya,kya,m9,x9,lya,z9,mya,nya,oya;$CLJS.j9=function(a,b){return $CLJS.n($CLJS.n(a)?b in a:a)?$CLJS.Aa(a,b):null};l9=function(a,b){return k9(a,b,$CLJS.N)};
k9=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.j(c,m9,!0);if($CLJS.sd(b))return $CLJS.Wf.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=$CLJS.j9(b,"_plainObject");d=null==d?null:$CLJS.Iy(d);return null==d?null:$CLJS.Be(d)}():null;return $CLJS.n(c)?$CLJS.Wf.j($CLJS.N,a,c):$CLJS.Wf.j($CLJS.N,$CLJS.ak.j($CLJS.cf.h(function(d){return new $CLJS.P(null,2,5,$CLJS.Q,[d,$CLJS.j9(b,d)],null)}),$CLJS.bk.h(function(d){$CLJS.I(d,0,null);return"function"===typeof $CLJS.I(d,1,null)}),a),$CLJS.za(b))};
dya=function(a){var b=n9.h(a),c=o9.h(a),d=p9.h(a);return $CLJS.ak.j($CLJS.cf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=$CLJS.Q;f=$CLJS.mh.h(q9(f));if($CLJS.n(d)){var l=d.h?d.h(f):d.call(null,f);f=$CLJS.n(l)?l:f}return new $CLJS.P(null,2,5,k,[f,e],null)}),$CLJS.od(b)?$CLJS.Pd:$CLJS.bk.h(function(e){var f=$CLJS.I(e,0,null);$CLJS.I(e,1,null);return $CLJS.Cd(b,f)}),$CLJS.Sa(c)?$CLJS.Pd:$CLJS.cf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return new $CLJS.P(null,2,5,
$CLJS.Q,[f,c.g?c.g(f,e):c.call(null,f,e)],null)}))};eya=function(a){return l9($CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$CLJS.mh.h(q9(c));c=$CLJS.E.g(c,$CLJS.AU)?$CLJS.zD:c;b=$CLJS.E.g(c,$CLJS.zD)?$CLJS.mh.h(b):b;return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),a)};
fya=function(a){var b=$CLJS.nG(function(d){d=$CLJS.j9(d,"type");var e=new $CLJS.Rg(null,new $CLJS.h(null,2,["internal",null,"external",null],null),null);return e.h?e.h(d):e.call(null,d)},a);if($CLJS.n(b)){var c=$CLJS.mh.h($CLJS.j9(b,"type"));return $CLJS.sk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Fi,$CLJS.j9(b,"id"),$CLJS.U,$CLJS.j9(b,"name")],null),function(){var d=c instanceof $CLJS.M?c.S:null;switch(d){case "external":return new $CLJS.h(null,2,[$CLJS.Rs,$CLJS.eK,$CLJS.QJ,$CLJS.j9(b,"human_readable_field_id")],
null);case "internal":return new $CLJS.h(null,1,[$CLJS.Rs,$CLJS.ZJ],null);default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}()]))}return null};s9=function(a){return $CLJS.ck.g(r9.g($CLJS.gF,$CLJS.N),a)};
gya=function(a,b){var c=r9.g($CLJS.uM,new $CLJS.h(null,1,[m9,!1],null)),d=r9.g($CLJS.uM,$CLJS.N);return $CLJS.sk.l($CLJS.H([$CLJS.R.j(function(){var e=$CLJS.j9($CLJS.j9(a,"tables"),["card__",$CLJS.p.h(b)].join(""));return c.h?c.h(e):c.call(null,e)}(),$CLJS.Fi,b),function(){var e=$CLJS.j9($CLJS.j9(a,"questions"),$CLJS.p.h(b));var f=$CLJS.j9(e,"_card");e=$CLJS.n(f)?f:e;return d.h?d.h(e):d.call(null,e)}()]))};hya=new $CLJS.M(null,"plain-object","plain-object",-943264281);
$CLJS.t9=new $CLJS.M(null,"cards","cards",169174038);iya=new $CLJS.M(null,"binning-info","binning-info",-2117036653);u9=new $CLJS.M("metabase.lib.js.metadata","field-values","metabase.lib.js.metadata/field-values",-88384962);jya=new $CLJS.M(null,"db","db",993250759);$CLJS.v9=new $CLJS.M(null,"metrics","metrics",394093469);kya=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.w9=new $CLJS.M(null,"segments","segments",1937535949);m9=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);
x9=new $CLJS.M("metabase.lib.js.metadata","dimension","metabase.lib.js.metadata/dimension",2004773191);lya=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.y9=new $CLJS.M(null,"tables","tables",1334623052);z9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);mya=new $CLJS.M(null,"schema-name","schema-name",1666725119);nya=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);oya=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);
$CLJS.A9=new $CLJS.M(null,"databases","databases",2040134125);var q9=$CLJS.Ah($CLJS.aA),n9,pya=$CLJS.Se($CLJS.N),qya=$CLJS.Se($CLJS.N),rya=$CLJS.Se($CLJS.N),sya=$CLJS.Se($CLJS.N),tya=$CLJS.J.j($CLJS.N,$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null)),D9,Jya,Kya,Lya,Mya,Nya;n9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.mh,tya,pya,qya,rya,sya);n9.m(null,$CLJS.Oh,function(){return null});
var o9,uya=$CLJS.Se($CLJS.N),vya=$CLJS.Se($CLJS.N),wya=$CLJS.Se($CLJS.N),xya=$CLJS.Se($CLJS.N),yya=$CLJS.J.j($CLJS.N,$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));o9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.mh,yya,uya,vya,wya,xya);o9.m(null,$CLJS.Oh,function(){return null});var B9,zya=$CLJS.Se($CLJS.N),Aya=$CLJS.Se($CLJS.N),Bya=$CLJS.Se($CLJS.N),Cya=$CLJS.Se($CLJS.N),Dya=$CLJS.J.j($CLJS.N,$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
B9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","lib-type"),$CLJS.mh,Dya,zya,Aya,Bya,Cya);var p9,Eya=$CLJS.Se($CLJS.N),Fya=$CLJS.Se($CLJS.N),Gya=$CLJS.Se($CLJS.N),Hya=$CLJS.Se($CLJS.N),Iya=$CLJS.J.j($CLJS.N,$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));p9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Pd,Iya,Eya,Fya,Gya,Hya);p9.m(null,$CLJS.Oh,function(){return null});
var r9=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","parse-object-fn*"),function(f){return f},e,a,b,c,d)}();
r9.m(null,$CLJS.Oh,function(a,b){var c=dya(a),d=B9.h(a);return function(e){try{var f=$CLJS.R.j(k9(c,e,b),$CLJS.Rs,d),k=$CLJS.Iz($CLJS.xx);$CLJS.n($CLJS.Hz("metabase.lib.js.metadata",k))&&$CLJS.Gz("metabase.lib.js.metadata",k,$CLJS.sV("Parsed metadata %s %s\n%s",$CLJS.H([a,$CLJS.Fi.h(f),$CLJS.tV(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,k=$CLJS.Iz($CLJS.yx),$CLJS.n($CLJS.Hz("metabase.lib.js.metadata",k))&&(f instanceof Error?$CLJS.Gz("metabase.lib.js.metadata",k,$CLJS.sV("Error parsing %s %s: %s",
$CLJS.H([a,$CLJS.xh.l($CLJS.H([e])),$CLJS.rU(f)])),f):$CLJS.Gz("metabase.lib.js.metadata",k,$CLJS.sV(f,$CLJS.H(["Error parsing %s %s: %s",a,$CLJS.xh.l($CLJS.H([e])),$CLJS.rU(f)])),null)),null;throw l;}}});$CLJS.C9=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.mh.h(f)},e,a,b,c,d)}();
Jya=$CLJS.Se($CLJS.N);Kya=$CLJS.Se($CLJS.N);Lya=$CLJS.Se($CLJS.N);Mya=$CLJS.Se($CLJS.N);Nya=$CLJS.J.j($CLJS.N,$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));D9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.mh,Nya,Jya,Kya,Lya,Mya);
$CLJS.C9.m(null,$CLJS.Oh,function(a,b){var c=r9.g(a,$CLJS.N);return l9($CLJS.cf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rV(e),new $CLJS.uh(function(){return c.h?c.h(f):c.call(null,f)})],null)}),$CLJS.j9(b,D9.h(a)))});B9.m(null,$CLJS.TO,function(){return $CLJS.aK});n9.m(null,$CLJS.TO,function(){return new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.OD,null,$CLJS.y9,null],null),null)});
o9.m(null,$CLJS.TO,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "dbms-version":return $CLJS.Hy(b,$CLJS.H([$CLJS.hi,!0]));case "features":return $CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.mh),b);case "native-permissions":return $CLJS.mh.h(b);default:return b}}});D9.m(null,$CLJS.TO,function(){return"databases"});B9.m(null,$CLJS.MV,function(){return $CLJS.OJ});
n9.m(null,$CLJS.MV,function(){return new $CLJS.Rg(null,new $CLJS.h(null,5,[z9,null,$CLJS.OD,null,$CLJS.w9,null,$CLJS.TO,null,$CLJS.v9,null],null),null)});o9.m(null,$CLJS.MV,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "entity-type":return $CLJS.mh.h(b);case "field-order":return $CLJS.mh.h(b);case "initial-sync-status":return $CLJS.mh.h(b);case "visibility-type":return $CLJS.mh.h(b);default:return b}}});
$CLJS.C9.m(null,$CLJS.MV,function(a,b){var c=r9.g(a,$CLJS.N);return l9($CLJS.ak.g($CLJS.bk.h(function(d){var e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.cf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rV(e),new $CLJS.uh(function(){return c.h?c.h(f):c.call(null,f)})],null)})),$CLJS.j9(b,"tables"))});B9.m(null,$CLJS.gF,function(){return $CLJS.GJ});
n9.m(null,$CLJS.gF,function(){return new $CLJS.Rg(null,new $CLJS.h(null,6,[z9,null,$CLJS.MV,null,oya,null,$CLJS.TO,null,nya,null,$CLJS.v9,null],null),null)});p9.m(null,$CLJS.gF,function(){return new $CLJS.h(null,6,[$CLJS.Uy,$CLJS.IJ,$CLJS.xk,$CLJS.HV,$CLJS.YQ,$CLJS.nD,iya,$CLJS.NV,$CLJS.GK,x9,$CLJS.Or,u9],null)});
o9.m(null,$CLJS.gF,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "base-type":return $CLJS.mh.h(b);case "coercion-strategy":return $CLJS.mh.h(b);case "effective-type":return $CLJS.mh.h(b);case "fingerprint":return $CLJS.sd(b)?$CLJS.tU(b):$CLJS.Hy(b,$CLJS.H([$CLJS.hi,!0]));case "has-field-values":return $CLJS.mh.h(b);case "lib/source":switch(b){case "aggregation":return $CLJS.fK;case "breakout":return $CLJS.XJ;default:return $CLJS.mh.g("source",b)}case "metabase.lib.field/temporal-unit":return $CLJS.mh.h(b);
case "semantic-type":return $CLJS.mh.h(b);case "visibility-type":return $CLJS.mh.h(b);case "id":return $CLJS.ud(b)?$CLJS.dd(b):b;case "metabase.lib.field/binning":return eya(b);case "metabase.lib.js.metadata/field-values":return $CLJS.E.g($CLJS.j9(b,"type"),"full")?new $CLJS.h(null,2,[$CLJS.Or,$CLJS.Iy($CLJS.j9(b,"values")),$CLJS.NJ,$CLJS.Iy($CLJS.j9(b,"human_readable_values"))],null):null;case "metabase.lib.js.metadata/dimension":return fya(b);default:return b}}});
r9.m(null,$CLJS.gF,function(a,b){var c=function(){var d=$CLJS.gH(r9,$CLJS.Oh);return d.g?d.g(a,b):d.call(null,a,b)}();return function(d){d=c.h?c.h(d):c.call(null,d);var e=$CLJS.O(d);d=$CLJS.J.g(e,x9);var f=$CLJS.O(d),k=$CLJS.J.g(f,$CLJS.Rs),l=$CLJS.J.g(e,u9);return $CLJS.Tj.l(function(){switch(k instanceof $CLJS.M?k.S:null){case "metadata.column.remapping/external":return $CLJS.R.j(e,$CLJS.TJ,f);case "metadata.column.remapping/internal":return $CLJS.R.j(e,$CLJS.$J,$CLJS.sk.l($CLJS.H([f,l])));default:return e}}(),
x9,$CLJS.H([u9]))}});
$CLJS.C9.m(null,$CLJS.gF,function(a,b){var c=r9.g(a,$CLJS.N),d=$CLJS.j9(b,"fields");return l9($CLJS.ol(function(e){var f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null);e=function(){var l=$CLJS.rV(f);if($CLJS.n(l))return l;l=$CLJS.dh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);var m=null==$CLJS.j9(d,l);return m?$CLJS.rV(l):m}return null}();return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[e,new $CLJS.uh(function(){return c.h?c.h(k):c.call(null,k)})],null):null}),d)});
B9.m(null,$CLJS.uM,function(){return $CLJS.dK});n9.m(null,$CLJS.uM,function(){return new $CLJS.Rg(null,new $CLJS.h(null,11,[z9,null,$CLJS.Jk,null,kya,null,$CLJS.MV,null,jya,null,hya,null,$CLJS.w9,null,$CLJS.TO,null,lya,null,$CLJS.v9,null,mya,null],null),null)});
o9.m(null,$CLJS.uM,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "result-metadata":return $CLJS.n($CLJS.vz($CLJS.rd,$CLJS.Qa)(b))?s9(b):$CLJS.Hy(b,$CLJS.H([$CLJS.hi,!0]));case "fields":return s9(b);case "visibility-type":return $CLJS.mh.h(b);case "dataset-query":return $CLJS.Hy(b,$CLJS.H([$CLJS.hi,!0]));case "dataset":return b;default:return b}}});
$CLJS.C9.m(null,$CLJS.uM,function(a,b){return $CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.uh(function(){return gya(b,c)})],null)}),$CLJS.Wf.j($CLJS.Wf.j($CLJS.Sg,$CLJS.ol($CLJS.EV),$CLJS.za($CLJS.j9(b,"tables"))),$CLJS.cf.h($CLJS.rV),$CLJS.za($CLJS.j9(b,"questions"))))});B9.m(null,$CLJS.bF,function(){return $CLJS.MJ});n9.m(null,$CLJS.bF,function(){return new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.MV,null,$CLJS.TO,null],null),null)});
o9.m(null,$CLJS.bF,function(){return function(a,b){return b}});D9.m(null,$CLJS.bF,function(){return"metrics"});B9.m(null,$CLJS.EE,function(){return $CLJS.KJ});n9.m(null,$CLJS.EE,function(){return new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.MV,null,$CLJS.TO,null],null),null)});o9.m(null,$CLJS.EE,function(){return function(a,b){return b}});D9.m(null,$CLJS.EE,function(){return"segments"});$CLJS.E9=r9.g($CLJS.gF,$CLJS.N);