var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var yy=function(){},zy=function(a){return $CLJS.J.g($CLJS.ox,a).value},wfa=function(a){var b=zy(a);return $CLJS.n(function(){var c=zy($CLJS.Fx);return $CLJS.Wj.g?$CLJS.Wj.g(c,b):$CLJS.Wj.call(null,c,b)}())?$CLJS.jy:$CLJS.n(function(){var c=zy($CLJS.Ex);return $CLJS.Wj.g?$CLJS.Wj.g(c,b):$CLJS.Wj.call(null,c,b)}())?$CLJS.ty:$CLJS.n(function(){var c=zy($CLJS.Cx);return $CLJS.Wj.g?$CLJS.Wj.g(c,b):$CLJS.Wj.call(null,c,b)}())?$CLJS.my:$CLJS.n(function(){var c=zy($CLJS.wx);return $CLJS.Wj.g?
$CLJS.Wj.g(c,b):$CLJS.Wj.call(null,c,b)}())?$CLJS.sy:$CLJS.n(function(){var c=zy($CLJS.Dx);return $CLJS.Wj.g?$CLJS.Wj.g(c,b):$CLJS.Wj.call(null,c,b)}())?$CLJS.uy:$CLJS.n(function(){var c=zy($CLJS.zx);return $CLJS.Wj.g?$CLJS.Wj.g(c,b):$CLJS.Wj.call(null,c,b)}())?$CLJS.py:$CLJS.n(function(){var c=zy($CLJS.tx);return $CLJS.Wj.g?$CLJS.Wj.g(c,b):$CLJS.Wj.call(null,c,b)}())?$CLJS.qy:$CLJS.ry},Ay=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},By=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.Yd.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.xy,c))].join(""),$CLJS.H(["color:black"]))],null)},Cy=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.Yd.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.xy,$CLJS.vy)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.xy,
c))].join(""),$CLJS.H(["color:black;background-color:inherit"]))],null)},xfa=function(a){function b(c,d){return d>=c}a=zy(a);if(b(zy($CLJS.Fx),a))return"error";if(b(zy($CLJS.Ex),a))return"warn";if(b(zy($CLJS.Cx),a))return"info";b(zy($CLJS.wx),a);return"log"},Dy=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.ux),e=$CLJS.J.g(c,$CLJS.ks);b=$CLJS.J.g(c,$CLJS.Jj);e=xfa(e);e=$CLJS.Aa(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.Ne.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=
["[",$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},Ey=function Ey(a,b){for(;;){if($CLJS.E.g($CLJS.iy,b))return By(a,", ",$CLJS.ry);if($CLJS.E.g($CLJS.ky,b))return Ay(a);if(b instanceof $CLJS.M)return By(a,b,$CLJS.my);if(b instanceof $CLJS.r)return By(a,b,$CLJS.sy);if("string"===typeof b)return By(a,$CLJS.xh.l($CLJS.H([b])),$CLJS.ly);if($CLJS.uf(b)){var d=Ay(function(){var f=a,k=$CLJS.Bb(b);return Ey.g?Ey.g(f,k):Ey.call(null,f,k)}()),e=$CLJS.Cb(b);
return Ey.g?Ey.g(d,e):Ey.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.yg)return d=a,d=By(d,"{",$CLJS.wy),d=$CLJS.bb(Ey,d,$CLJS.af($CLJS.iy,b)),By(d,"}",$CLJS.wy);if($CLJS.sd(b))return d=a,d=By(d,["#",$CLJS.p.h(function(){var f=$CLJS.Ya(b),k=f.name;return $CLJS.od(k)?$CLJS.xh.l($CLJS.H([f])):k}())," "].join(""),$CLJS.ny),d=By(d,"{",$CLJS.wy),d=$CLJS.bb(Ey,d,$CLJS.af($CLJS.iy,b)),By(d,"}",$CLJS.wy);if($CLJS.qd(b))return d=a,d=By(d,"#{",$CLJS.wy),d=$CLJS.bb(Ey,d,$CLJS.af($CLJS.ky,b)),By(d,
"}",$CLJS.wy);if($CLJS.ud(b))return d=a,d=By(d,"[",$CLJS.wy),d=$CLJS.bb(Ey,d,$CLJS.af($CLJS.ky,b)),By(d,"]",$CLJS.wy);if(b instanceof $CLJS.Lf)d=By(a,"#queue ",$CLJS.ny),e=$CLJS.Wf.g($CLJS.xf,b),a=d,b=e;else{if($CLJS.yd(b))return d=a,d=By(d,"(",$CLJS.ny),d=$CLJS.bb(Ey,d,$CLJS.af($CLJS.ky,b)),By(d,")",$CLJS.ny);if(null!=b?b.I&16384||$CLJS.yc===b.bj||(b.I?0:$CLJS.Xa(yy,b)):$CLJS.Xa(yy,b))d=By(a,"#atom ",$CLJS.ny),e=$CLJS.q(b),a=d,b=e;else if($CLJS.Rh(b))d=By(a,"#uuid ",$CLJS.ny),e=$CLJS.p.h(b),a=d,
b=e;else if($CLJS.Ua(b))d=By(a,"#js ",$CLJS.ny),e=$CLJS.bb(function(f,k){return function(l,m){return $CLJS.R.j(l,$CLJS.mh.h(m),$CLJS.Aa(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Qa(b))d=By(a,"#js ",$CLJS.ny),e=$CLJS.Wf.g($CLJS.xf,b),a=d,b=e;else return By(a,$CLJS.xh.l($CLJS.H([b])),$CLJS.oy)}}};$CLJS.yfa=Dy(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.ks);var b=$CLJS.J.g(a,$CLJS.ux),c=$CLJS.J.g(a,$CLJS.wi);$CLJS.J.g(a,$CLJS.Jj);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.zfa=Dy(function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.ks);a=$CLJS.J.g(b,$CLJS.ux);var d=$CLJS.J.g(b,$CLJS.wi);$CLJS.J.g(b,$CLJS.Jj);b=wfa(c);d=Ey(Ay(Cy(Cy(Cy(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.xf],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.ae(a,d)});
$CLJS.Afa=Dy(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.ks);var b=$CLJS.J.g(a,$CLJS.ux),c=$CLJS.J.g(a,$CLJS.wi);$CLJS.J.g(a,$CLJS.Jj);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.xh.l($CLJS.H([c]))],null)});