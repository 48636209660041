var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var JI;$CLJS.HI=function(a){return $CLJS.n($CLJS.oB($CLJS.rd,$CLJS.qd,$CLJS.Sk)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};
$CLJS.II=function(a,b){return $CLJS.Me($CLJS.zd,function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.ie(null,function(K,S,X,T){return function(){for(var da=C;;){var oa=$CLJS.y(da);if(oa){var Oa=oa,pb=$CLJS.z(Oa);if(oa=$CLJS.y(function(Ra,Wa,cb,Ka,kb,Ja,bc,Jd){return function Qh(ed){return new $CLJS.ie(null,function(mz,Fl,Qn,sr,x_,Fv){return function(){for(;;){var Gv=
$CLJS.y(ed);if(Gv){if($CLJS.vd(Gv)){var Hv=$CLJS.ic(Gv),nz=$CLJS.D(Hv),Ck=$CLJS.le(nz);a:for(var tr=0;;)if(tr<nz){var et=$CLJS.hd(Hv,tr);et=$CLJS.E.g(Fv,$CLJS.VD)||$CLJS.E.g(Qn,$CLJS.VD)||$CLJS.wE(Fv,et)&&$CLJS.wE(Qn,et);Ck.add(et);tr+=1}else{Hv=!0;break a}return Hv?$CLJS.oe($CLJS.qe(Ck),Qh($CLJS.jc(Gv))):$CLJS.oe($CLJS.qe(Ck),null)}Ck=$CLJS.z(Gv);return $CLJS.ae($CLJS.E.g(Fv,$CLJS.VD)||$CLJS.E.g(Qn,$CLJS.VD)||$CLJS.wE(Fv,Ck)&&$CLJS.wE(Qn,Ck),Qh($CLJS.Hc(Gv)))}return null}}}(Ra,Wa,cb,Ka,kb,Ja,bc,
Jd),null,null)}}(da,K,pb,Oa,oa,S,X,T)($CLJS.gE)))return $CLJS.bf.g(oa,G($CLJS.Hc(da)));da=$CLJS.Hc(da)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.HI($CLJS.JD(b)))))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}($CLJS.HI($CLJS.JD(a)))}())};
JI=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.Ne.j($CLJS.sE,d,e),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.ps,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.z(f);f=$CLJS.B(f);$CLJS.z(f);f=$CLJS.B(f);var k=$CLJS.yf(f);return $CLJS.Le($CLJS.cl,
$CLJS.cf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.II($CLJS.J.g(k,m),$CLJS.J.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.z(d);d=$CLJS.Hc(d);return c(e,d)};b.l=c;return b}()};$CLJS.KI=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.LI=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.MI=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var NI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,$CLJS.$q],null)),OI=null,PI=0,QI=0;;)if(QI<PI){var Lia=OI.X(null,QI);$CLJS.vE(Lia,$CLJS.H([$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mq,new $CLJS.h(null,1,[$CLJS.bn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null)],null)],null)]));QI+=1}else{var RI=$CLJS.y(NI);if(RI){var SI=RI;if($CLJS.vd(SI)){var TI=$CLJS.ic(SI),Mia=$CLJS.jc(SI),
Nia=TI,Oia=$CLJS.D(TI);NI=Mia;OI=Nia;PI=Oia}else{var Pia=$CLJS.z(SI);$CLJS.vE(Pia,$CLJS.H([$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mq,new $CLJS.h(null,1,[$CLJS.bn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null)],null)],null)]));NI=$CLJS.B(SI);OI=null;PI=0}QI=0}else break}$CLJS.tE($CLJS.br,$CLJS.H([$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)]));
for(var UI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.wF],null)),VI=null,WI=0,XI=0;;)if(XI<WI){var Qia=VI.X(null,XI);$CLJS.vE(Qia,$CLJS.H([$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mq,new $CLJS.h(null,1,[$CLJS.bn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YD],null)],null)],null)],null)]));XI+=1}else{var YI=$CLJS.y(UI);if(YI){var ZI=YI;if($CLJS.vd(ZI)){var $I=$CLJS.ic(ZI),Ria=$CLJS.jc(ZI),
Sia=$I,Tia=$CLJS.D($I);UI=Ria;VI=Sia;WI=Tia}else{var Uia=$CLJS.z(ZI);$CLJS.vE(Uia,$CLJS.H([$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mq,new $CLJS.h(null,1,[$CLJS.bn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YD],null)],null)],null)],null)]));UI=$CLJS.B(ZI);VI=null;WI=0}XI=0}else break}
for(var aJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zq,$CLJS.Bq,$CLJS.vq,$CLJS.xq],null)),bJ=null,cJ=0,dJ=0;;)if(dJ<cJ){var Via=bJ.X(null,dJ);$CLJS.rE.l(JI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Via,$CLJS.H([$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)]));dJ+=1}else{var eJ=$CLJS.y(aJ);if(eJ){var fJ=eJ;if($CLJS.vd(fJ)){var gJ=$CLJS.ic(fJ),Wia=$CLJS.jc(fJ),Xia=gJ,Yia=$CLJS.D(gJ);aJ=Wia;bJ=Xia;cJ=Yia}else{var Zia=
$CLJS.z(fJ);$CLJS.rE.l(JI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Zia,$CLJS.H([$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)]));aJ=$CLJS.B(fJ);bJ=null;cJ=0}dJ=0}else break}
$CLJS.rE.l(JI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),$CLJS.IE,$CLJS.H([$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)]));
$CLJS.rE.l(JI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[1,3],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,5],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,4],null)])),$CLJS.OE,$CLJS.H([$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)]));
for(var hJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HE,$CLJS.TE],null)),iJ=null,jJ=0,kJ=0;;)if(kJ<jJ){var $ia=iJ.X(null,kJ);$CLJS.tE($ia,$CLJS.H([$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)]));kJ+=1}else{var lJ=$CLJS.y(hJ);if(lJ){var mJ=lJ;if($CLJS.vd(mJ)){var nJ=$CLJS.ic(mJ),aja=$CLJS.jc(mJ),bja=nJ,cja=$CLJS.D(nJ);hJ=aja;iJ=bja;jJ=cja}else{var dja=$CLJS.z(mJ);$CLJS.tE(dja,$CLJS.H([$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)]));hJ=$CLJS.B(mJ);
iJ=null;jJ=0}kJ=0}else break}
for(var oJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JE,$CLJS.QE],null)),pJ=null,qJ=0,rJ=0;;)if(rJ<qJ){var eja=pJ.X(null,rJ);$CLJS.tE(eja,$CLJS.H([$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null)]));rJ+=1}else{var sJ=$CLJS.y(oJ);if(sJ){var tJ=sJ;if($CLJS.vd(tJ)){var uJ=$CLJS.ic(tJ),fja=$CLJS.jc(tJ),gja=uJ,hja=$CLJS.D(uJ);oJ=fja;pJ=gja;qJ=hja}else{var ija=$CLJS.z(tJ);$CLJS.tE(ija,$CLJS.H([$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null)]));oJ=$CLJS.B(tJ);
pJ=null;qJ=0}rJ=0}else break}
for(var vJ=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oF,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.pr],null)],null),wJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.YE,$CLJS.rF,$CLJS.fF,$CLJS.ZE],null)),xJ=null,yJ=0,zJ=0;;)if(zJ<yJ){var AJ=xJ.X(null,zJ);$CLJS.FF.v(AJ,$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,AJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,$CLJS.tD,vJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null)],null));zJ+=1}else{var BJ=$CLJS.y(wJ);if(BJ){var CJ=BJ;if($CLJS.vd(CJ)){var DJ=$CLJS.ic(CJ),jja=$CLJS.jc(CJ),kja=DJ,lja=$CLJS.D(DJ);wJ=jja;xJ=kja;yJ=lja}else{var EJ=$CLJS.z(CJ);$CLJS.FF.v(EJ,$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,EJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,$CLJS.tD,vJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.cE],null)],null));wJ=$CLJS.B(CJ);xJ=null;yJ=0}zJ=0}else break}
$CLJS.FF.v($CLJS.bB,$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.bB],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,$CLJS.tD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.pr],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Xq,$CLJS.Mu,$CLJS.Rw,$CLJS.NE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bD],null)],null));$CLJS.FF.v($CLJS.EE,$CLJS.Vr,$CLJS.jj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.EE],null),$CLJS.tD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,$CLJS.gD,$CLJS.oD],null)],null));
$CLJS.Y($CLJS.KI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.LI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$E,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.Xq,$CLJS.Cq,$CLJS.wF,$CLJS.OE,$CLJS.IE,$CLJS.zq,$CLJS.vq,$CLJS.Bq,$CLJS.xq,$CLJS.HE,$CLJS.TE,$CLJS.JE,$CLJS.QE,$CLJS.fF,$CLJS.ZE,$CLJS.YE,$CLJS.rF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MI,$CLJS.Di],null)],null));