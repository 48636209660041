var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.util.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var pY,Coa,Doa,Eoa,Foa,wY,Goa,Hoa,xY,Ioa,AY,Joa,Koa,Loa,FY,GY,Moa,Noa,PY,Ooa,Poa,Qoa,UY,Roa;pY=function(){};$CLJS.qY=function(a){return null!=a?$CLJS.yc===a.ni?!0:a.Wc?!1:$CLJS.Xa(pY,a):$CLJS.Xa(pY,a)};Coa=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.rY=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Doa=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Eoa=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.sY=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.tY=new $CLJS.M(null,"database-id","database-id",1883826326);Foa=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.uY=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);$CLJS.vY=new $CLJS.M(null,"search","search",1564939822);wY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Goa=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Hoa=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
xY=new $CLJS.M("metabase.lib.schema.metadata","column.has-field-values","metabase.lib.schema.metadata/column.has-field-values",-1383672167);Ioa=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.yY=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.zY=new $CLJS.M(null,"dataset","dataset",1159262238);AY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);
Joa=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.BY=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.CY=new $CLJS.M(null,"definition","definition",-1198729982);Koa=new $CLJS.M(null,"state","state",-1988618099);$CLJS.DY=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Loa=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.EY=new $CLJS.M("source","joins","source/joins",1225821486);
FY=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);GY=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.HY=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.IY=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);$CLJS.JY=new $CLJS.M(null,"selected?","selected?",-742502788);
Moa=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Noa=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.KY=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.LY=new $CLJS.M("source","card","source/card",-139977973);$CLJS.MY=new $CLJS.M(null,"active","active",1895962068);$CLJS.NY=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.OY=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);PY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Ooa=new $CLJS.M(null,"details","details",1956795411);$CLJS.QY=new $CLJS.M("source","fields","source/fields",-649667981);$CLJS.RY=new $CLJS.M(null,"has-field-values","has-field-values",626648377);
Poa=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.SY=new $CLJS.M("source","native","source/native",-1444604147);Qoa=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);$CLJS.TY=new $CLJS.M(null,"auto-list","auto-list",314197731);UY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);
$CLJS.VY=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Roa=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);$CLJS.WY=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.XY=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.Y(FY,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Xq,$CLJS.LY,$CLJS.SY,$CLJS.sY,$CLJS.uY,$CLJS.QY,$CLJS.fK,$CLJS.XJ,$CLJS.EY,$CLJS.BY,$CLJS.DY],null));$CLJS.Y(xY,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Xq],null),$CLJS.yz($CLJS.Dd,new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.TY,null,$CLJS.vY,null,$CLJS.bj,null,$CLJS.Zy,null],null),null))));
$CLJS.Y(PY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.eK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fi,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QJ,$CLJS.cK],null)],null));
$CLJS.Y(wY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.ZJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fi,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Or,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.qr],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.qr],null)],null)],null));
$CLJS.Y($CLJS.yY,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ps,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.GJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.sj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pA,$CLJS.xD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fi,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.cK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.sj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mi,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.xD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UJ,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.cK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IY,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.cK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WY,
new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.oD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nD,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.oD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IJ,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VY,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.RJ],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rY,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.oD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HY,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.h(null,2,[$CLJS.bn,1,$CLJS.yj,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JY,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.pr],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.RY,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TJ,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$J,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wY],null)],null)],
null)],null));$CLJS.Y(UY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KY,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[Joa,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[Ioa,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pA,$CLJS.xD],null)],null)],null)],null)],null)],null));
$CLJS.Y(AY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MY,$CLJS.pr],null),new $CLJS.P(null,2,5,$CLJS.Q,[Koa,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KY,$CLJS.oD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CY,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FJ,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,
$CLJS.oD],null)],null)],null));
$CLJS.Y(Poa,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ps,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.dK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fi,$CLJS.RJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tY,$CLJS.SJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NY,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.xj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OY,
new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.xj],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zY,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.pr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VJ,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.LJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Eoa,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,AY],null)],null)],null)],null));
$CLJS.Y(Moa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ps,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.KJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fi,$CLJS.JJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VJ,$CLJS.LJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.xj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.oD],null)],null)],null));
$CLJS.Y(Noa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ps,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.MJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fi,$CLJS.HJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VJ,$CLJS.LJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.xj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.oD],null)],null)],null));
$CLJS.Y(Qoa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ps,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.OJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fi,$CLJS.LJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.oD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.oD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.h(null,
1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.oD],null)],null)],null));
$CLJS.Y(Doa,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ps,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.aK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fi,$CLJS.SJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[Hoa,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.xj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ooa,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.xj],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.XY,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.Di],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$y,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gi,$CLJS.Di],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Loa,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.pr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WJ,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.xj],null)],null)],null));
$CLJS.Y(GY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.ps,"Valid MetadataProvider"],null),new $CLJS.Cc(function(){return $CLJS.qY},Foa,$CLJS.zg([$CLJS.Ci,$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[Coa,Roa,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ap],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.qY)?$CLJS.qY.H:null]))],null));
$CLJS.Y(Goa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ps,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GY],null)],null)],null)],null));