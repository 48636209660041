var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.equality.js");require("./metabase.lib.field.js");require("./metabase.lib.join.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.util.js");require("./metabase.lib.order_by.js");require("./metabase.lib.stage.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.js.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.time.js");require("./metabase.util.log.js");require("./metabase.util.memoize.js");
'use strict';var Oya=function(a,b){const c={};for(const d in a)b.call(void 0,a[d],d,a)&&(c[d]=a[d]);return c},Pya=function(a,b){const c={};for(const d in a)c[d]=b.call(void 0,a[d],d,a);return c},G9=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.pA);b=$CLJS.J.g(b,$CLJS.mi);a=$CLJS.n(b)?b:a;return $CLJS.zz(a,$CLJS.uj)||$CLJS.zz(a,$CLJS.Ti)},Qya=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(f,k){return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),d);return function l(k){return new $CLJS.ie(null,
function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.vd(m)){var t=$CLJS.ic(m),u=$CLJS.D(t),v=$CLJS.le(u);a:for(var x=0;;)if(x<u){var A=$CLJS.hd(t,x);A=$CLJS.N1.v(a,b,A,d);A=$CLJS.J.j(e,A,-1);v.add(A);x+=1}else{t=!0;break a}return t?$CLJS.oe($CLJS.qe(v),l($CLJS.jc(m))):$CLJS.oe($CLJS.qe(v),null)}v=$CLJS.z(m);v=$CLJS.N1.v(a,b,v,d);return $CLJS.ae($CLJS.J.j(e,v,-1),l($CLJS.Hc(m)))}return null}},null,null)}(c)},H9=function(a,b){return new $CLJS.uh(function(){try{return $CLJS.C9.g(a,b)}catch(e){if(e instanceof
Error){var c=e,d=$CLJS.Iz($CLJS.yx);$CLJS.n($CLJS.Hz("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.Gz("metabase.lib.js.metadata",d,$CLJS.sV("Error parsing %s objects: %s",$CLJS.H([a,$CLJS.rU(c)])),c):$CLJS.Gz("metabase.lib.js.metadata",d,$CLJS.sV(c,$CLJS.H(["Error parsing %s objects: %s",a,$CLJS.rU(c)])),null));return null}throw e;}})},Rya=function(a,b){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),
m=$CLJS.le(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.hd(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.VJ.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?
$CLJS.E.g($CLJS.VJ.h(v),b):x}()))return $CLJS.ae(v,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.OD.h(a);return null==d?null:$CLJS.q(d)}())},Sya=function(a,b){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.hd(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var K=C;return null==K?null:
$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.VJ.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.VJ.h(v),b):x}()))return $CLJS.ae(v,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.v9.h(a);
return null==d?null:$CLJS.q(d)}())},Tya=function(a,b){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.hd(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.VJ.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):
$CLJS.oe($CLJS.qe(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.VJ.h(v),b):x}()))return $CLJS.ae(v,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.w9.h(a);return null==d?null:$CLJS.q(d)}())},I9=function(a,b,c,d){this.fg=a;this.Fg=b;this.metadata=c;this.mi=d;this.C=393216;this.I=0},Uya=function(a,b){var c=new $CLJS.h(null,
6,[$CLJS.A9,H9($CLJS.TO,b),$CLJS.y9,H9($CLJS.MV,b),$CLJS.OD,H9($CLJS.gF,b),$CLJS.t9,H9($CLJS.uM,b),$CLJS.v9,H9($CLJS.bF,b),$CLJS.w9,H9($CLJS.EE,b)],null),d=$CLJS.Iz($CLJS.xx);$CLJS.n($CLJS.Hz("metabase.lib.js.metadata",d))&&$CLJS.Gz("metabase.lib.js.metadata",d,$CLJS.tv.l($CLJS.H(["Created metadata provider for metadata"])),null);return new I9(a,b,c,$CLJS.N)},Vya=function(a){return $CLJS.Ua(a)?Oya(a,function(b){return void 0!==b}):a},J9=function(a){return $CLJS.cs($CLJS.Iy(Pya(a,function(b){return Vya(b)})),
function(b){return $CLJS.dk.j($CLJS.Az(b,$CLJS.mh),$CLJS.Qi,$CLJS.mh)})},K9=function(a,b){return $CLJS.qY(b)?b:$CLJS.a_($CLJS.p.h(a),b,$CLJS.Qe(Uya,a),!0)},L9=function(a){a=$CLJS.Wz(a);const b=a.length-1;a=0<=b&&a.indexOf("?",b)==b?["is-",$CLJS.Cz(a,/\?$/,"")].join(""):a;return $CLJS.EA.h?$CLJS.EA.h(a):$CLJS.EA.call(null,a)},O9=function(a){return $CLJS.sd(a)?M9.h?M9.h(a):M9.call(null,a):$CLJS.rd(a)?N9.h?N9.h(a):N9.call(null,a):a instanceof $CLJS.M?$CLJS.Wz(a):a},P9=function(a,b){return $CLJS.SV($CLJS.SV((0,$CLJS.zX)($CLJS.F9($CLJS.Iy(a))),
$CLJS.hP,function(c){return $CLJS.sk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.EP,$CLJS.N],null),c]))}),$CLJS.CE,function(c){var d=function(){var e=$CLJS.OD.h(c);return $CLJS.n(e)?e:function l(k){return new $CLJS.ie(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.vd(m)){var t=$CLJS.ic(m),u=$CLJS.D(t),v=$CLJS.le(u);a:for(var x=0;;)if(x<u){var A=$CLJS.hd(t,x);v.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,A,null],null));x+=1}else{t=!0;break a}return t?$CLJS.oe($CLJS.qe(v),l($CLJS.jc(m))):$CLJS.oe($CLJS.qe(v),
null)}v=$CLJS.z(m);return $CLJS.ae(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,v,null],null),l($CLJS.Hc(m)))}return null}},null,null)}(b)}();return $CLJS.R.j(c,$CLJS.OD,$CLJS.Q7(d))})},Wya=function(a,b){function c(l,m){l=$CLJS.Sa($CLJS.pA.h(m))?$CLJS.Tj.g(l,$CLJS.pA):l;m=$CLJS.Sa($CLJS.mi.h(m))?$CLJS.Tj.g(l,$CLJS.mi):l;return $CLJS.Be(m)}var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var f=$CLJS.I(b,0,null),k=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return $CLJS.E.g(new $CLJS.P(null,3,5,
$CLJS.Q,[d,e,c(a,b)],null),new $CLJS.P(null,3,5,$CLJS.Q,[f,k,c(b,a)],null))},Xya=function(a,b){var c=$CLJS.DV(a,b);return $CLJS.re($CLJS.cf.g(function(d){return $CLJS.R.j(d,$CLJS.JY,!0)},$CLJS.P_.j(a,b,c)))},Yya=function(a){return $CLJS.n(function(){var b=$CLJS.z(a),c=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.EE,null,$CLJS.WE,null,$CLJS.bF,null],null),null);return c.h?c.h(b):c.call(null,b)}())?$CLJS.hk.j(a,0,2):$CLJS.dk.v(a,2,$CLJS.cs,function(b){return $CLJS.he(b)?$CLJS.Wz(b):b})},Q9=function(a){return $CLJS.TX.h($CLJS.dk.j($CLJS.Hy(a,
$CLJS.H([$CLJS.hi,!0])),0,$CLJS.mh))},Zya=function(a){var b=$CLJS.Ua(a)?a:null;return $CLJS.n(b)?(a=$CLJS.E9.h?$CLJS.E9.h(b):$CLJS.E9.call(null,b),$CLJS.E.g($CLJS.IJ.h(a),$CLJS.fK)?(b=Q9(b.field_ref),$CLJS.R.j(a,$CLJS.w0,$CLJS.fd(b))):a):a},R9=function(a,b){var c=null==a?null:$CLJS.z(a);c=null==c?null:$CLJS.E.g(c,$CLJS.WE);return $CLJS.n(c)?$CLJS.R.j(b,$CLJS.w0,$CLJS.fd(a)):b},S9=function(a,b,c){b=$CLJS.d9.l($CLJS.H([a,b]));a=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,
1,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.vD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,l],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.vD);return new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null)}),b);var d=$CLJS.SX,e=$CLJS.RX;$CLJS.SX=a;$CLJS.RX=b;try{var f=function(){var k=c.field_ref;return $CLJS.n(k)?Q9(k):null}();return R9(f,$CLJS.E9.h?$CLJS.E9.h(c):$CLJS.E9.call(null,c))}finally{$CLJS.RX=e,$CLJS.SX=d}},T9=function(a){return function(b){var c=
function(){var e=a.h?a.h(b):a.call(null,b);return $CLJS.E9.h?$CLJS.E9.h(e):$CLJS.E9.call(null,e)}(),d=function(){var e=$CLJS.j1.h(c);return $CLJS.n(e)?Q9(e):null}();return new $CLJS.h(null,3,[$CLJS.yi,R9(d,c),$CLJS.yK,d,$CLJS.cj,b.value],null)}};$CLJS.g=I9.prototype;$CLJS.g.P=function(a,b){return new I9(this.fg,this.Fg,this.metadata,b)};$CLJS.g.O=function(){return this.mi};$CLJS.g.ni=$CLJS.yc;
$CLJS.g.vg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.v9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.wg=function(a,b){return Sya(this.metadata,b)};$CLJS.g.Ag=function(a,b){a=this.metadata;a=null==a?null:$CLJS.y9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.ug=function(a,b){return Rya(this.metadata,b)};
$CLJS.g.tg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.OD.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.yg=function(a,b){return Tya(this.metadata,b)};$CLJS.g.zg=function(a,b){return $CLJS.j9($CLJS.j9(this.Fg,"settings"),$CLJS.Xg(b))};$CLJS.g.rg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.t9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};
$CLJS.g.xg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.w9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.sg=function(){var a=this.metadata,b=this.fg;a=null==a?null:$CLJS.A9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};var U9=function U9(a){switch(arguments.length){case 1:return U9.h(arguments[0]);case 2:return U9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.extract_template_tags",U9);U9.h=function(a){return U9.g(a,$CLJS.N)};U9.g=function(a,b){return $CLJS.uk($CLJS.Ixa.l($CLJS.H([a,J9(b)])))};U9.A=2;
var V9=function V9(a){switch(arguments.length){case 2:return V9.g(arguments[0],arguments[1]);case 3:return V9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.query",V9);V9.g=function(a,b){return $CLJS.g9.l($CLJS.H([a,b]))};
V9.j=function(a,b,c){c=$CLJS.TX.h((0,$CLJS.zX)($CLJS.y1($CLJS.l7(c),$CLJS.Qi,$CLJS.CE)));var d=$CLJS.Iz($CLJS.xx);$CLJS.n($CLJS.Hz("metabase.lib.js",d))&&$CLJS.Gz("metabase.lib.js",d,$CLJS.sV("query map: %s",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))])),null);return $CLJS.g9.l($CLJS.H([K9(a,b),c]))};V9.A=3;
var W9=function W9(a){return $CLJS.he(a)?[$CLJS.de(a),"/",$CLJS.Xg(a)].join(""):$CLJS.sd(a)?$CLJS.cs(a,W9):$CLJS.rd(a)?$CLJS.cf.g(W9,a):a},M9=$CLJS.Vz(function(a){return $CLJS.bb(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);d=L9(d);c=O9.h?O9.h(c):O9.call(null,c);b[d]=c;return b},{},a)}),N9=$CLJS.Vz(function(a){return $CLJS.re($CLJS.cf.g(O9,a))}),X9=function X9(a){switch(arguments.length){case 1:return X9.h(arguments[0]);case 2:return X9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",
$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.order_by_clause",X9);X9.h=function(a){return X9.g(a,$CLJS.dF)};X9.g=function(a,b){return $CLJS.Lxa.l($CLJS.H([$CLJS.f9.l($CLJS.H([$CLJS.Hy(a,$CLJS.H([$CLJS.hi,!0]))])),$CLJS.mh.h(b)]))};X9.A=2;var Y9=function Y9(a){switch(arguments.length){case 2:return Y9.g(arguments[0],arguments[1]);case 3:return Y9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.xa("metabase.lib.js.available_binning_strategies",Y9);Y9.g=function(a,b){return $CLJS.re($CLJS.e9.l($CLJS.H([a,b])))};Y9.j=function(a,b,c){return $CLJS.re($CLJS.e9.l($CLJS.H([a,b,c])))};Y9.A=3;var Z9=function Z9(a){switch(arguments.length){case 2:return Z9.g(arguments[0],arguments[1]);case 3:return Z9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.available_temporal_buckets",Z9);
Z9.g=function(a,b){return $CLJS.re($CLJS.i9.l($CLJS.H([a,b])))};Z9.j=function(a,b,c){return $CLJS.re($CLJS.i9.l($CLJS.H([a,b,c])))};Z9.A=3;
var $9=function $9(a,b){return $CLJS.ud(a)&&$CLJS.ud(b)&&$CLJS.E.l($CLJS.z(a),$CLJS.z(b),$CLJS.H([$CLJS.gF]))?Wya(a,b):$CLJS.sd(a)&&$CLJS.sd(b)?$CLJS.E.g($CLJS.Wg($CLJS.Ng(a)),$CLJS.Wg($CLJS.Ng(b)))&&$CLJS.Le(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);e=$CLJS.J.g(b,e);return $9.g?$9.g(d,e):$9.call(null,d,e)},a):$CLJS.rd(a)&&$CLJS.rd(b)?$CLJS.E.g($CLJS.D(a),$CLJS.D(b))&&$CLJS.Le($CLJS.cl,$CLJS.cf.j($9,a,b)):$CLJS.E.g(a,b)},a$=function a$(a){switch(arguments.length){case 2:return a$.g(arguments[0],
arguments[1]);case 3:return a$.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.query_EQ_",a$);a$.g=function(a,b){return a$.j(a,b,null)};a$.j=function(a,b,c){a=P9(a,c);b=P9(b,c);return $9(a,b)};a$.A=3;
var b$=function b$(a){switch(arguments.length){case 1:return b$.h(arguments[0]);case 2:return b$.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.aggregation_clause",b$);b$.h=function(a){return $CLJS.c9.l($CLJS.H([a]))};b$.g=function(a,b){return $CLJS.c9.l($CLJS.H([a,b]))};b$.A=2;
var c$=function c$(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return c$.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};$CLJS.xa("metabase.lib.js.filter_clause",c$);c$.l=function(a,b,c){return $CLJS.Ne.v($CLJS.Xwa,a,b,c)};c$.A=2;c$.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};
var d$=function d$(a){switch(arguments.length){case 3:return d$.j(arguments[0],arguments[1],arguments[2]);case 4:return d$.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.lib.js.with_different_database",d$);d$.j=function(a,b,c){return d$.v(a,b,c,null)};d$.v=function(a,b,c,d){return $CLJS.Gxa.l($CLJS.H([a,K9(b,c),$CLJS.Hy(d,$CLJS.H([$CLJS.hi,!0]))]))};d$.A=4;
var $ya=T9(function(a){return a.col}),aza=T9(function(a){return a.column}),e$=function e$(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return e$.l(arguments[0],arguments[1],arguments[2],3<c.length?new $CLJS.w(c.slice(3),0,null):null)};$CLJS.xa("metabase.lib.js.drill_thru",e$);e$.l=function(a,b,c,d){return $CLJS.Ne.M($CLJS.Bwa,a,b,c,d)};e$.A=3;
e$.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return this.l(b,a,c,d)};
module.exports={returned_columns:function(a,b){return $CLJS.b_($CLJS.mh.g("returned-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return Xya(a,b)})},legacy_expression_for_expression_clause:function(a,b,c){b=$CLJS.d9.l($CLJS.H([a,b]));a=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.vD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,l],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);
l=$CLJS.J.g(l,$CLJS.vD);return new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null)}),b);var d=$CLJS.SX,e=$CLJS.RX;$CLJS.SX=a;$CLJS.RX=b;try{var f=$CLJS.IX.h(c);return $CLJS.uk(function(){var k;if(k=$CLJS.ud(f)){k=$CLJS.z(f);var l=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.cj,null,$CLJS.tL,null],null),null);k=l.h?l.h(k):l.call(null,k)}return $CLJS.n(k)?$CLJS.J.g(f,1):f}())}finally{$CLJS.RX=e,$CLJS.SX=d}},drop_stage_if_empty:function(a){return $CLJS.Xxa.l($CLJS.H([a]))},extract_template_tags:U9,engine:function(a){return $CLJS.Xg($CLJS.Bxa.l($CLJS.H([a])))},
filter_operator:function(a,b,c){return $CLJS.Ywa.l($CLJS.H([a,b,c]))},binning:function(a){return $CLJS.pwa.l($CLJS.H([a]))},query:V9,columns_group_columns:function(a){return $CLJS.re($CLJS.wwa.l($CLJS.H([a])))},join_fields:function(a){a=$CLJS.lxa.l($CLJS.H([a]));return a instanceof $CLJS.M?$CLJS.Wz(a):$CLJS.re(a)},aggregate:function(a,b,c){return $CLJS.kwa.l($CLJS.H([a,b,$CLJS.Hy(c,$CLJS.H([$CLJS.hi,!0]))]))},join_conditions:function(a){return $CLJS.re($CLJS.kxa.l($CLJS.H([a])))},query_EQ_:a$,temporal_bucket:function(a){return $CLJS.bya.l($CLJS.H([a]))},
available_drill_thrus:function(a,b,c,d,e,f){var k=$CLJS.d9.l($CLJS.H([a,b])),l=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.vD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),k);k=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.vD);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),k);var m=$CLJS.SX,t=$CLJS.RX;$CLJS.SX=l;$CLJS.RX=k;try{var u=function(){var v=$CLJS.n(c)?
c.field_ref:c;return $CLJS.n(v)?Q9(v):null}();return $CLJS.re($CLJS.Awa.l($CLJS.H([a,b,$CLJS.sk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.yi,$CLJS.n(c)?R9(u,$CLJS.E9.h?$CLJS.E9.h(c):$CLJS.E9.call(null,c)):null,$CLJS.yK,u,$CLJS.cj,void 0===d?null:null==d?$CLJS.LK:d],null),$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.wK,$CLJS.ck.g($ya,e)],null):null,$CLJS.n($CLJS.Be(f))?new $CLJS.h(null,1,[$CLJS.GK,$CLJS.ck.g(aza,f)],null):null]))])))}finally{$CLJS.RX=t,$CLJS.SX=m}},has_clauses:function(a,b){return $CLJS.Yxa.l($CLJS.H([a,
b]))},remove_clause:function(a,b,c){return $CLJS.Qxa.l($CLJS.H([a,b,$CLJS.f9.l($CLJS.H([$CLJS.Hy(c,$CLJS.H([$CLJS.hi,!0]))]))]))},source_table_or_card_id:function(a){var b=$CLJS.TZ(a);if($CLJS.n(b))return b;a=$CLJS.D1(a);return null==a?null:["card__",$CLJS.p.h(a)].join("")},legacy_ref:function(a,b,c){b=$CLJS.WE.h($CLJS.DV(a,b));a=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.vD);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);
b=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.vD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.SX,e=$CLJS.RX;$CLJS.SX=a;$CLJS.RX=b;try{return $CLJS.uk(Yya($CLJS.IX.h($CLJS.h9.l($CLJS.H([c])))))}finally{$CLJS.RX=e,$CLJS.SX=d}},replace_clause:function(a,b,c,d){return $CLJS.Txa.l($CLJS.H([a,b,$CLJS.f9.l($CLJS.H([$CLJS.Hy(c,$CLJS.H([$CLJS.hi,!0]))])),$CLJS.f9.l($CLJS.H([$CLJS.Hy(d,$CLJS.H([$CLJS.hi,!0]))]))]))},drop_stage:function(a){return $CLJS.Wxa.l($CLJS.H([a]))},
join_lhs_display_name:function(a,b,c,d){return $CLJS.mxa.l($CLJS.H([a,b,c,d]))},add_field:function(a,b,c){return $CLJS.Owa.l($CLJS.H([a,b,c]))},segment_metadata:function(a,b){return $CLJS.O8(a,b)},pivot_types:function(a){return $CLJS.re($CLJS.cf.g($CLJS.Xg,$CLJS.Dwa.l($CLJS.H([a]))))},native_extras:function(a){return $CLJS.uk($CLJS.Exa.l($CLJS.H([a])))},available_temporal_buckets:Z9,with_binning:function(a,b){return $CLJS.qwa.l($CLJS.H([a,b]))},append_stage:function(a){return $CLJS.Vxa.l($CLJS.H([a]))},
join:function(a,b,c){return $CLJS.exa.l($CLJS.H([a,b,c]))},aggregations:function(a,b){return $CLJS.re($CLJS.d9.l($CLJS.H([a,b])))},drill_thru:e$,raw_native_query:function(a){return $CLJS.yxa.l($CLJS.H([a]))},filterable_column_operators:function(a){return $CLJS.re($CLJS.Wwa.l($CLJS.H([a])))},with_temporal_bucket:function(a,b){return $CLJS.cya.l($CLJS.H([a,b]))},valid_filter_for_QMARK_:function(a,b){return $CLJS.AZ(a)&&$CLJS.AZ(b)||$CLJS.IZ(a)&&$CLJS.IZ(b)||$CLJS.yZ(a)&&$CLJS.yZ(b)||$CLJS.zz($CLJS.pA.h(a),
$CLJS.pA.h(b))},with_join_conditions:function(a,b){return $CLJS.txa.l($CLJS.H([a,$CLJS.Hy(b,$CLJS.H([$CLJS.hi,!0]))]))},update_lat_lon_filter:function(a,b,c,d,e){e=$CLJS.Hy(e,$CLJS.H([$CLJS.hi,!0]));c=S9(a,b,c);d=S9(a,b,d);return $CLJS.axa.l($CLJS.H([a,b,c,d,e]))},available_metrics:function(a){return $CLJS.re($CLJS.wxa.l($CLJS.H([a])))},with_different_database:d$,with_fields:function(a,b,c){return $CLJS.Swa.l($CLJS.H([a,b,c]))},is_column_metadata:function(a){return $CLJS.sd(a)&&$CLJS.E.g($CLJS.GJ,
$CLJS.Rs.h(a))},filter_args_display_name:function(a,b,c){return $CLJS.Nwa.l($CLJS.H([a,b,c]))},join_clause:function(a,b){return $CLJS.fxa.l($CLJS.H([a,b]))},aggregation_clause:b$,external_op:function(a){a=$CLJS.ywa.l($CLJS.H([a]));var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.rD);var c=$CLJS.J.g(b,$CLJS.Li);b=$CLJS.J.g(b,$CLJS.Gr);return{operator:a,options:$CLJS.uk(c),args:$CLJS.re(b)}},with_join_fields:function(a,b){return $CLJS.rxa.l($CLJS.H([a,"string"===typeof b?$CLJS.mh.h(b):b]))},visible_columns:function(a,
b){return $CLJS.b_($CLJS.mh.g("visible-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){var c=$CLJS.DV(a,b),d=$CLJS.R_.j(a,b,c);c=$CLJS.P_.j(a,b,c);return $CLJS.re($CLJS.$2(a,b,d,c))})},update_numeric_filter:function(a,b,c,d,e){c=S9(a,b,c);return $CLJS.bxa.l($CLJS.H([a,b,c,d,e]))},available_binning_strategies:Y9,update_temporal_filter:function(a,b,c,d,e){c=S9(a,b,c);return $CLJS.cxa.l($CLJS.H([a,b,c,d,e]))},find_column_indexes_from_legacy_refs:function(a,b,c,d){var e=$CLJS.WE.h($CLJS.DV(a,
b)),f=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.vD);return new $CLJS.P(null,2,5,$CLJS.Q,[u,v],null)}),e);e=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.vD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null)}),e);var k=$CLJS.SX,l=$CLJS.RX;$CLJS.SX=f;$CLJS.RX=e;try{var m=$CLJS.ck.g(Zya,c),t=$CLJS.cf.g(Q9,d);return $CLJS.re(Qya(a,b,t,m))}finally{$CLJS.RX=l,$CLJS.SX=
k}},expression:function(a,b,c,d){return $CLJS.Gwa.l($CLJS.H([a,b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.mh.h(a):a;b="string"===typeof b?$CLJS.mh.h(b):b;return $CLJS.aya.l($CLJS.H([a,b]))},order_bys:function(a,b){return $CLJS.re($CLJS.Mxa.l($CLJS.H([a,b])))},display_info:function(a,b,c){return $CLJS.b_($CLJS.mh.g("display-info-outer",["stage-",$CLJS.p.h(b)].join("")),c,function(d){return O9($CLJS.uxa.l($CLJS.H([$CLJS.s3(a,b),b,d])))})},can_run:function(a){return $CLJS.Nxa.l($CLJS.H([a]))},
fieldable_columns:function(a,b){return $CLJS.b_($CLJS.mh.g("fieldable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.re($CLJS.Pwa.l($CLJS.H([a,b])))})},template_tags:function(a){return $CLJS.uk($CLJS.Axa.l($CLJS.H([a])))},selected_aggregation_operators:function(a,b){return $CLJS.re($CLJS.owa.l($CLJS.H([$CLJS.y(a),b])))},order_by_clause:X9,filterable_columns:function(a,b){return $CLJS.b_($CLJS.mh.g("filterable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.re($CLJS.Vwa.l($CLJS.H([a,
b])))})},join_condition_rhs_columns:function(a,b,c,d,e){return $CLJS.re($CLJS.ixa.l($CLJS.H([a,b,c,d,e])))},join_condition_update_temporal_bucketing:function(a,b,c,d){return $CLJS.jxa.l($CLJS.H([a,b,c,d]))},filters:function(a,b){return $CLJS.re($CLJS.Uwa.l($CLJS.H([a,b])))},join_condition_lhs_columns:function(a,b,c,d,e){return $CLJS.re($CLJS.gxa.l($CLJS.H([a,b,c,d,e])))},table_or_card_metadata:function(a,b){return $CLJS.a1(a,b)},with_expression_name:function(a,b){return $CLJS.Jwa.l($CLJS.H([a,b]))},
orderable_columns:function(a,b){return $CLJS.b_($CLJS.mh.g("orderable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.re($CLJS.G7.g(a,b))})},find_filter_for_legacy_filter:function(a,b,c){return $CLJS.Zwa.l($CLJS.H([a,b,$CLJS.Hy(c,$CLJS.H([$CLJS.hi,!0]))]))},suggestedName:function(a){return $CLJS.vxa.l($CLJS.H([a]))},find_column_for_legacy_ref:function(a,b,c,d){return $CLJS.Ewa.l($CLJS.H([a,b,c,d]))},has_write_permission:function(a){return $CLJS.Hxa.l($CLJS.H([a]))},metadataProvider:K9,
filter_clause:c$,fields:function(a,b){return $CLJS.re($CLJS.Qwa.l($CLJS.H([a,b])))},dependent_metadata:function(a){return $CLJS.re($CLJS.cf.g($CLJS.uk,$CLJS.Kwa.l($CLJS.H([a]))))},available_aggregation_operators:function(a,b){return $CLJS.re($CLJS.nwa.l($CLJS.H([a,b])))},breakouts:function(a,b){return $CLJS.re($CLJS.vwa.l($CLJS.H([a,b])))},joinable_columns:function(a,b,c){return $CLJS.re($CLJS.oxa.l($CLJS.H([a,b,c])))},group_columns:function(a){return $CLJS.re($CLJS.xwa.l($CLJS.H([a])))},describe_temporal_unit:function(a,
b){b="string"===typeof b?$CLJS.mh.h(b):b;return $CLJS.Zxa.l($CLJS.H([a,b]))},breakout_column:function(a,b,c){return $CLJS.swa.l($CLJS.H([a,b,c]))},joins:function(a,b){return $CLJS.re($CLJS.pxa.l($CLJS.H([a,b])))},native_query:function(a,b,c){return $CLJS.xxa.l($CLJS.H([K9(a,b),c]))},available_join_strategies:function(a,b){return $CLJS.re($CLJS.dxa.l($CLJS.H([a,b])))},legacy_query:function(a){return $CLJS.uk.l(W9($CLJS.IX.h(a)),$CLJS.H([$CLJS.Gj,$CLJS.Wz]))},stage_count:function(a){return $CLJS.Oxa.l($CLJS.H([a]))},
expression_clause:function(a,b,c){return $CLJS.f9.l($CLJS.H([$CLJS.Lwa.l($CLJS.H([$CLJS.mh.h(a),b,$CLJS.Hy(c,$CLJS.H([$CLJS.hi,!0]))]))]))},find_matching_column:function(a,b,c,d){return $CLJS.Fwa.l($CLJS.H([a,b,c,d]))},with_native_extras:function(a,b){return $CLJS.Fxa.l($CLJS.H([a,$CLJS.Hy(b,$CLJS.H([$CLJS.hi,!0]))]))},join_strategy:function(a){return $CLJS.nxa.l($CLJS.H([a]))},picker_info:function(a,b){var c=$CLJS.Rs.h(b);switch(c instanceof $CLJS.M?c.S:null){case "metadata/table":return{databaseId:$CLJS.TO.h(a),
tableId:$CLJS.Fi.h(b)};case "metadata/card":return{databaseId:$CLJS.TO.h(a),tableId:["card__",$CLJS.p.h($CLJS.Fi.h(b))].join(""),cardId:$CLJS.Fi.h(b),isModel:$CLJS.zY.h(b)};default:return a=$CLJS.Iz($CLJS.Ax),$CLJS.n($CLJS.Hz("metabase.lib.js",a))&&$CLJS.Gz("metabase.lib.js",a,$CLJS.tv.l($CLJS.H(["Cannot provide picker-info for",$CLJS.Rs.h(b)])),null),null}},expression_parts:function(a,b,c){a=$CLJS.Mwa.l($CLJS.H([a,b,c]));return $CLJS.sU(function(d){if($CLJS.sd(d)&&$CLJS.E.g($CLJS.q8,$CLJS.Rs.h(d))){var e=
$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.rD);var f=$CLJS.J.g(e,$CLJS.Li);e=$CLJS.J.g(e,$CLJS.Gr);return{operator:$CLJS.Xg(d),options:$CLJS.uk($CLJS.wl(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oF,$CLJS.Dk],null))),args:$CLJS.re($CLJS.cf.g(function(k){return k instanceof $CLJS.M?$CLJS.Wz(k):k},e))}}return d},a)},expression_clause_for_legacy_expression:function(a,b,c){b=$CLJS.d9.l($CLJS.H([a,b]));a=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(l,m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.O(m);m=$CLJS.J.g(m,$CLJS.vD);
return new $CLJS.P(null,2,5,$CLJS.Q,[l,m],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(l,m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.O(m);m=$CLJS.J.g(m,$CLJS.vD);return new $CLJS.P(null,2,5,$CLJS.Q,[m,l],null)}),b);var d=$CLJS.SX,e=$CLJS.RX;$CLJS.SX=a;$CLJS.RX=b;try{var f=$CLJS.Hy(c,$CLJS.H([$CLJS.hi,!0])),k=$CLJS.z($CLJS.AX(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,$CLJS.WE],null),new $CLJS.P(null,1,5,$CLJS.Q,[f],null)));return $CLJS.TX.h(k)}finally{$CLJS.RX=e,$CLJS.SX=d}},format_relative_date_range:function(a,
b,c,d,e){b=$CLJS.mh.h(b);d=null==d?null:$CLJS.mh.h(d);e=$CLJS.Hy(e,$CLJS.H([$CLJS.hi,!0]));return $CLJS.b2((0,$CLJS.JH)(),a,b,c,d,e)},aggregation_operator_columns:function(a){return $CLJS.re($CLJS.mwa.l($CLJS.H([a])))},find_filterable_column_for_legacy_ref:function(a,b,c){return $CLJS.$wa.l($CLJS.H([a,b,c]))},available_segments:function(a,b){return $CLJS.re($CLJS.Uxa.l($CLJS.H([a,b])))},join_condition_operators:function(a,b,c,d){return $CLJS.re($CLJS.hxa.l($CLJS.H([a,b,c,d])))},aggregation_column:function(a,
b,c){return $CLJS.lwa.l($CLJS.H([a,b,c]))},expressionable_columns:function(a,b,c){return $CLJS.b_($CLJS.mh.g("expressionable-columns",["stage-",$CLJS.p.h(b),"-",$CLJS.p.h(c)].join("")),a,function(){return $CLJS.re($CLJS.Iwa.l($CLJS.H([a,b,c])))})},filter:function(a,b,c){return $CLJS.Twa.l($CLJS.H([a,b,$CLJS.Hy(c,$CLJS.H([$CLJS.hi,!0]))]))},metric_metadata:function(a,b){return $CLJS.H3(a,b)},change_direction:function(a,b){return $CLJS.Jxa.l($CLJS.H([a,b]))},pivot_columns_for_type:function(a,b){return $CLJS.re($CLJS.Cwa.l($CLJS.H([a,
$CLJS.mh.h(b)])))},filter_drill_details:function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.CE);var c=$CLJS.J.g(b,$CLJS.yi),d=$CLJS.J.g(b,$CLJS.DK);b=$CLJS.J.g(b,$CLJS.cj);return{column:c,query:a,stageIndex:d,value:$CLJS.E.g(b,$CLJS.LK)?null:b}},remove_field:function(a,b,c){return $CLJS.Rwa.l($CLJS.H([a,b,c]))},with_template_tags:function(a,b){return $CLJS.Cxa.l($CLJS.H([a,J9(b)]))},expressions:function(a,b){return $CLJS.re($CLJS.Hwa.l($CLJS.H([a,b])))},suggested_join_conditions:function(a,b,c){return $CLJS.re($CLJS.qxa.l($CLJS.H([a,
b,c])))},with_join_strategy:function(a,b){return $CLJS.sxa.l($CLJS.H([a,b]))},order_by:function(a,b,c,d){return $CLJS.Kxa.l($CLJS.H([a,b,c,$CLJS.mh.h(d)]))},field_values_search_info:function(a,b){var c=$CLJS.dk.j;if($CLJS.n(b)){var d=$CLJS.Fi.h(b),e=$CLJS.Fi.h;var f=$CLJS.FZ(b)?b:null;$CLJS.n(f)?a=f:(f=$CLJS.xz(b,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TJ,$CLJS.QJ],null)),a=$CLJS.n(f)?$CLJS.T0(a,f):null,a=$CLJS.n(a)?a:b);a=G9(a)?a:null;e=e.call($CLJS.Fi,a);d=$CLJS.fl(d)?d:null;e=$CLJS.fl(e)?e:null;$CLJS.n(b)?
(b=$CLJS.O(b),a=$CLJS.J.g(b,$CLJS.RY),b=$CLJS.E.g(a,$CLJS.TY)?$CLJS.bj:$CLJS.n(a)?a:G9(b)?$CLJS.vY:$CLJS.Zy):b=$CLJS.Zy;b=new $CLJS.h(null,3,[$CLJS.QJ,d,$CLJS.c7,e,$CLJS.RY,b],null)}else b=null;return $CLJS.uk($CLJS.Az(c.call($CLJS.dk,b,$CLJS.RY,$CLJS.Xg),L9))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.mh.h(a):a;b="string"===typeof b?$CLJS.mh.h(b):b;return $CLJS.$xa.l($CLJS.H([a,b]))},breakout:function(a,b,c){return $CLJS.rwa.l($CLJS.H([a,b,$CLJS.h9.l($CLJS.H([c]))]))},
database_id:function(a){return $CLJS.zwa.l($CLJS.H([a]))},required_native_extras:function(a,b){return $CLJS.re($CLJS.cf.g($CLJS.Wz,$CLJS.Dxa.l($CLJS.H([K9(a,b)]))))},remove_join:function(a,b,c){return $CLJS.Rxa.l($CLJS.H([a,b,c]))},joined_thing:function(a,b){return $CLJS.m3(a,b)},legacy_column__GT_metadata:S9,with_native_query:function(a,b){return $CLJS.zxa.l($CLJS.H([a,b]))},breakoutable_columns:function(a,b){return $CLJS.b_($CLJS.mh.g("breakoutable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.re($CLJS.twa.l($CLJS.H([a,
b])))})},rename_join:function(a,b,c,d){return $CLJS.Sxa.l($CLJS.H([a,b,c,d]))},with_different_table:function(a,b){return $CLJS.Pxa.l($CLJS.H([a,b]))}};