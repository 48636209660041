var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.convert.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.filter.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.underlying.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var k7,m7,o7,xta,q7,r7,yta;k7=function(a){if("string"===typeof a)return $CLJS.dh(/[\x00-\x20]*[+-]?NaN[\x00-\x20]*/,a)?NaN:$CLJS.dh(/[\x00-\x20]*[+-]?(Infinity|((\d+\.?\d*|\.\d+)([eE][+-]?\d+)?)[dDfF]?)[\x00-\x20]*/,a)?parseFloat(a):null;throw Error($CLJS.Bl(a));};$CLJS.l7=function(a){return $CLJS.Ua(a)?$CLJS.Hy(a,$CLJS.H([$CLJS.hi,!0])):a};
m7=function(a,b){if(null!=a&&null!=a.zg)a=a.zg(a,b);else{var c=m7[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=m7._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Za("MetadataProvider.setting",a);}return a};$CLJS.n7=function(a,b){return m7($CLJS.XZ(a),b)};o7=function(a,b,c){a=(b-a)/c;if(null!=a)a=Math.ceil(a);else throw $CLJS.Uh("Unexpected Null passed to ceil",new $CLJS.h(null,1,[$CLJS.xi,"ceil"],null));a=$CLJS.Rd(a);return 1<a?a:1};
xta=function(a,b,c){var d=k7(((b-a)/c).toFixed(5)),e=Math.pow(10,0===d?0:Math.floor(Math.log($CLJS.uz(d))/Math.log(10)));return $CLJS.Me(function(f){f*=e;return f>=d?f:null},$CLJS.csa)};
$CLJS.p7=function(a,b,c){var d=$CLJS.Q4(b);if($CLJS.n(d)){var e=$CLJS.zD.h(d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "num-bins":e=$CLJS.xz(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RM,$CLJS.Qi,$CLJS.lj],null));if($CLJS.n(e)){a=$CLJS.O(e);e=$CLJS.J.g(a,$CLJS.bn);var f=$CLJS.J.g(a,$CLJS.yj);a=$CLJS.O(d);a=$CLJS.J.g(a,$CLJS.hD);a=xta(e,f,a);return new $CLJS.h(null,3,[$CLJS.yD,a,$CLJS.FK,c,$CLJS.IK,c+a],null)}return null;case "bin-width":return a=$CLJS.O(d),a=$CLJS.J.g(a,$CLJS.yD),new $CLJS.h(null,
3,[$CLJS.yD,a,$CLJS.FK,c,$CLJS.IK,c+a],null);case "default":e=$CLJS.xz(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RM,$CLJS.Qi,$CLJS.lj],null));if($CLJS.n(e)){d=$CLJS.O(e);e=$CLJS.J.g(d,$CLJS.bn);f=$CLJS.J.g(d,$CLJS.yj);a:switch(d=e,e=f,f=$CLJS.Oh instanceof $CLJS.M?$CLJS.Oh.S:null,f){case "num-bins":a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hD,new $CLJS.h(null,2,[$CLJS.hD,null,$CLJS.yD,k7(((e-d)/0).toFixed(5))],null)],null);break a;case "bin-width":a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yD,new $CLJS.h(null,
2,[$CLJS.yD,null,$CLJS.hD,o7(d,e,null)],null)],null);break a;case "default":$CLJS.NZ(b)?(a=$CLJS.n7(a,$CLJS.bsa),a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yD,new $CLJS.h(null,2,[$CLJS.yD,a,$CLJS.hD,o7(d,e,a)],null)],null)):(a=$CLJS.n7(a,$CLJS.asa),a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hD,new $CLJS.h(null,2,[$CLJS.hD,a,$CLJS.yD,k7(((e-d)/a).toFixed(5))],null)],null));break a;default:throw Error(["No matching clause: ",$CLJS.p.h(f)].join(""));}if($CLJS.n(a))return $CLJS.I(a,0,null),a=$CLJS.I(a,1,null),
a=$CLJS.O(a),a=$CLJS.J.g(a,$CLJS.yD),new $CLJS.h(null,3,[$CLJS.yD,a,$CLJS.FK,c,$CLJS.IK,c+a],null)}return null;default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}}else return null};q7=function(a){var b=$CLJS.$y.h($CLJS.S0(a));b=b.h?b.h($CLJS.i7):b.call(null,$CLJS.i7);if($CLJS.n(b))a:for(b=a;;)if($CLJS.od($CLJS.b4.g(b,-1))&&$CLJS.od($CLJS.z5.g(b,-1))){if(b=$CLJS.dk.j(b,$CLJS.mK,$CLJS.nd),!$CLJS.y($CLJS.mK.h(b))){b=null;break a}}else break a;else b=null;return $CLJS.n(b)?b:a};
r7=function(a,b){var c=q7(a);if($CLJS.E.g(a,c))return b;for(;;){if($CLJS.E.g(a,c))return $CLJS.xU(b,new $CLJS.h(null,2,[$CLJS.HV,$CLJS.j7,$CLJS.NV,$CLJS.wta],null));var d=$CLJS.P_,e=d.j,f=a;var k=a;var l=$CLJS.B1(k,-1);k=$CLJS.n(l)?$CLJS.DV(k,l):null;d=e.call(d,f,-2,k);b=$CLJS.N1.v(a,-2,$CLJS.oV(b),d);if($CLJS.n(b))a=$CLJS.dk.j(a,$CLJS.mK,$CLJS.nd);else return null}};
yta=function(a,b,c){var d=function(){var e=$CLJS.n($CLJS.Q4(b))?function(){var f=$CLJS.x5(b,null);if(null!=c){var k=$CLJS.p7(a,b,c);if($CLJS.n(k)){var l=$CLJS.O(k);k=$CLJS.J.g(l,$CLJS.FK);l=$CLJS.J.g(l,$CLJS.IK);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.w5(f,k),$CLJS.v5(f,l)],null)}return null}return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.z1($CLJS.HE,new $CLJS.P(null,1,5,$CLJS.Q,[f],null))],null)}():null;if($CLJS.n(e))return e;e=function(){var f=$CLJS.j7.h(b);return $CLJS.n(f)?$CLJS.Z2(b,f):b}();return new $CLJS.P(null,
1,5,$CLJS.Q,[(0,$CLJS.R2)(e,c)],null)}();return $CLJS.bb(function(e,f){return $CLJS.S2.j(e,-1,f)},a,d)};
$CLJS.s7=function(a,b){b=$CLJS.O(b);var c=$CLJS.J.g(b,$CLJS.yK),d=$CLJS.J.g(b,$CLJS.GK);b=$CLJS.PV.l(a,-1,$CLJS.Tj,$CLJS.H([$CLJS.WE,$CLJS.VE,$CLJS.MQ,$CLJS.iR,$CLJS.OD]));b=$CLJS.bb(function(f,k){var l=$CLJS.O(k);k=$CLJS.J.g(l,$CLJS.yi);l=$CLJS.J.g(l,$CLJS.cj);return yta(f,k,l)},b,function(){return function l(k){return new $CLJS.ie(null,function(){for(var m=k;;)if(m=$CLJS.y(m)){if($CLJS.vd(m)){var t=$CLJS.ic(m),u=$CLJS.D(t),v=$CLJS.le(u);a:for(var x=0;;)if(x<u){var A=$CLJS.hd(t,x);$CLJS.Zj.g($CLJS.IJ.h($CLJS.yi.h(A)),
$CLJS.fK)&&v.add(A);x+=1}else{t=!0;break a}return t?$CLJS.oe($CLJS.qe(v),l($CLJS.jc(m))):$CLJS.oe($CLJS.qe(v),null)}v=$CLJS.z(m);if($CLJS.Zj.g($CLJS.IJ.h($CLJS.yi.h(v)),$CLJS.fK))return $CLJS.ae(v,l($CLJS.Hc(m)));m=$CLJS.Hc(m)}else return null},null,null)}(d)}());var e=function(){var f=$CLJS.fd(c);return $CLJS.n(f)?$CLJS.nG(function(k){return $CLJS.E.g($CLJS.Z4(k),f)},$CLJS.b4.g(a,-1)):null}();return $CLJS.bb(function(f,k){return $CLJS.S2.j(f,-1,k)},b,$CLJS.n(e)?function(){var f=$CLJS.z(e);switch(f instanceof
$CLJS.M?f.S:null){case "sum-where":case "count-where":case "share":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.fd(e)],null);case "metric":return $CLJS.T2.g($CLJS.R.j($CLJS.TX.h((0,$CLJS.zX)($CLJS.y1($CLJS.l7(new $CLJS.h(null,2,[$CLJS.Qi,$CLJS.CE,$CLJS.CE,$CLJS.l7($CLJS.CY.h($CLJS.H3(a,$CLJS.fd(e))))],null)),$CLJS.Qi,$CLJS.CE))),$CLJS.TO,$CLJS.TO.h(a)),-1);default:return null}}():null)};$CLJS.H5.m(null,$CLJS.C6,function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.w6);b=$CLJS.J.g(b,$CLJS.KY);return new $CLJS.h(null,3,[$CLJS.Qi,$CLJS.C6,$CLJS.w6,a,$CLJS.KY,b],null)});
$CLJS.G5.m(null,$CLJS.C6,function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e){return $CLJS.s7(q7(c),$CLJS.dk.j(e,$CLJS.GK,function(f){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){var u=t;if($CLJS.vd(u)){var v=$CLJS.ic(u),x=$CLJS.D(v),A=$CLJS.le(x);return function(){for(var G=0;;)if(G<x){var K=$CLJS.hd(v,
G);$CLJS.pe(A,$CLJS.dk.j(K,$CLJS.yi,function(){return function(S){return r7(c,S)}}(G,K,v,x,A,u,t)));G+=1}else return!0}()?$CLJS.oe($CLJS.qe(A),m($CLJS.jc(u))):$CLJS.oe($CLJS.qe(A),null)}var C=$CLJS.z(u);return $CLJS.ae($CLJS.dk.j(C,$CLJS.yi,function(){return function(G){return r7(c,G)}}(C,u,t)),m($CLJS.Hc(u)))}return null}},null,null)}(f)}))}a.A=3;a.B=function(c){var d=$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.B(c);var f=$CLJS.z(c);c=$CLJS.Hc(c);return b(d,e,f,c)};a.l=b;return a}());