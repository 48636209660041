var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.util.internal.time.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.js");
'use strict';var dpa,epa,fpa,b0,c0,d0,gpa,hpa,ipa,f0,g0,jpa;$CLJS.a0=function(a){var b=$CLJS.dd($CLJS.dh($CLJS.fh(["(.*?)",$CLJS.iH($CLJS.H([$CLJS.oH])),"$"].join("")),a));return $CLJS.n(b)?b:a};dpa=function(a,b){return a.isSame(b,"day")};epa=function(a,b){return a.isSame(b,"month")};fpa=function(a,b){return a.isSame(b,"year")};
b0=function(a){return $CLJS.Me(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.O(b);b=$CLJS.J.g(d,$CLJS.gj);d=$CLJS.J.g(d,$CLJS.Y_);return $CLJS.n($CLJS.dh(b,a))?(b=$CLJS.JH.parseZone(a,d,!0),$CLJS.n(b.isValid())?new $CLJS.P(null,2,5,$CLJS.Q,[b,c],null):null):null},$CLJS.cpa)};c0=function(a){return a instanceof Date?$CLJS.JH.utc(a):a};d0=function(a,b,c){b="string"===typeof b?$CLJS.z(b0(b)):c0(b);return("string"===typeof c?$CLJS.z(b0(c)):c0(c)).diff(b,$CLJS.Xg(a))};
gpa=function(a,b){return d0.j?d0.j($CLJS.Fj,a,b):d0.call(null,$CLJS.Fj,a,b)};hpa=function(a,b){if("string"===typeof a)return a;a=c0(a);var c=function(){if($CLJS.zz(b,$CLJS.Bk))return $CLJS.V_;if($CLJS.zz(b,$CLJS.Fk))return $CLJS.U_;if($CLJS.zz(b,$CLJS.zk))return $CLJS.Z_;if($CLJS.zz(b,$CLJS.Ak))return $CLJS.W_;if($CLJS.zz(b,$CLJS.yk))return $CLJS.X_;throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}();return $CLJS.$_.h(new $CLJS.P(null,2,5,$CLJS.Q,[a,c],null))};
$CLJS.e0=function(a){if($CLJS.n($CLJS.JH.isMoment(a)))return a;if("string"===typeof a)return(0,$CLJS.JH)($CLJS.a0(a),ipa);throw $CLJS.Uh("Unknown input to coerce-to-time; expecting a string",new $CLJS.h(null,1,[$CLJS.cj,a],null));};ipa=["HH:mm:ss.SSS[Z]","HH:mm:ss.SSS","HH:mm:ss","HH:mm"];f0=function f0(a,b){if("string"===typeof a){var d=b0(a);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);b=f0.g?f0.g(a,b):f0.call(null,a,b);return $CLJS.$_.h(new $CLJS.P(null,2,5,$CLJS.Q,[b,d],null))}a=c0(a);return a.startOf($CLJS.Xg(b))};
g0=function g0(a,b,c){if("string"===typeof a){var e=b0(a);a=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);b=g0.j?g0.j(a,b,c):g0.call(null,a,b,c);return $CLJS.$_.h(new $CLJS.P(null,2,5,$CLJS.Q,[b,e],null))}a=c0(a);return a.add(c,$CLJS.Xg(b))};jpa=new $CLJS.M(null,"local","local",-1497766724);$CLJS.h0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g($CLJS.jH,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.i0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g($CLJS.T_,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.kpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(dpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.lpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(epa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.mpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(fpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.npa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(gpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.opa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(d0,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.j0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(f0,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.ppa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(g0,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.qpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(hpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.k0=function k0(a){switch(arguments.length){case 1:return k0.h(arguments[0]);case 2:return k0.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.shared.util.time.coerce_to_timestamp",$CLJS.k0);$CLJS.k0.h=function(a){return $CLJS.k0.g(a,$CLJS.N)};
$CLJS.k0.g=function(a,b){b=$CLJS.sk.l($CLJS.H([$CLJS.N,$CLJS.bA(b)]));if(!$CLJS.n($CLJS.S_(a))){var c=(c="string"===typeof a)?$CLJS.dh(/.*(Z|[+-]\d\d:?\d\d)$/,a):c;a=$CLJS.n(c)?$CLJS.JH.parseZone(a):"string"===typeof a?$CLJS.kH.g(a,b):$CLJS.lH.g(a,b)}return $CLJS.n(jpa.h(b))?a.local():a};$CLJS.k0.A=2;module.exports={coerce_to_timestamp:$CLJS.k0,coerce_to_time:$CLJS.e0};