var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var t0,zpa,Apa,u0,v0;$CLJS.r0=function(a,b){return $CLJS.dc($CLJS.bb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.cc(c,d):c},$CLJS.ac($CLJS.xf),b))};t0=function(a){return $CLJS.n(s0)?["(",$CLJS.p.h(a),")"].join(""):a};zpa=function(a,b,c){var d=$CLJS.DV(a,b);a=$CLJS.R_.j(a,b,d);var e=$CLJS.Xz(c);return $CLJS.Me(function(f){return $CLJS.E.g($CLJS.Xz($CLJS.U.h(f)),e)},a)};
Apa=function(a,b){var c=$CLJS.dk.v(a,$CLJS.YO,$CLJS.OC($CLJS.Yd,$CLJS.xf),b);return $CLJS.ud($CLJS.OD.h(a))?$CLJS.dk.v(c,$CLJS.OD,$CLJS.Yd,$CLJS.nV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mA,$CLJS.N,$CLJS.wV(b)],null))):c};u0=function(a,b,c){var d=$CLJS.wV(c);return $CLJS.R.l($CLJS.c_.j(a,b,c),$CLJS.IJ,$CLJS.BY,$CLJS.H([$CLJS.U,d,$CLJS.jD,d]))};v0=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);
$CLJS.w0=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var x0=function(){function a(d,e,f){var k=$CLJS.DV(d,e);k=$CLJS.nG($CLJS.ak.g($CLJS.Tg([f]),$CLJS.wV),$CLJS.YO.h(k));if($CLJS.n(k))return k;throw $CLJS.Uh($CLJS.eD("No expression named {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.YQ,f,$CLJS.CE,d,$CLJS.DK,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.K_.m(null,$CLJS.mA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=x0.j(a,b,c);return $CLJS.L_.j(a,b,c)});$CLJS.M_.m(null,$CLJS.mA,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Rs,$CLJS.GJ,$CLJS.w0,$CLJS.vD.h(d),$CLJS.U,e,$CLJS.nD,e,$CLJS.jD,$CLJS.e_.j(a,b,c),$CLJS.pA,$CLJS.L_.j(a,b,c),$CLJS.IJ,$CLJS.BY],null)});$CLJS.G_.m(null,$CLJS.hA,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.G_.m(null,$CLJS.fA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.G_.m(null,$CLJS.gA,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.G_.m(null,$CLJS.wA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.G_.m(null,$CLJS.mA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.H_.m(null,$CLJS.mA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var s0=!1,Bpa=new $CLJS.h(null,4,[$CLJS.Eq,"+",$CLJS.Vr,"-",$CLJS.Fq,"×",$CLJS.AF,"÷"],null),y0=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Eq,$CLJS.Vr,$CLJS.AF,$CLJS.Fq],null)),z0=null,A0=0,B0=0;;)if(B0<A0){var Cpa=z0.X(null,B0);$CLJS.mE(Cpa,v0);B0+=1}else{var C0=$CLJS.y(y0);if(C0){var D0=C0;if($CLJS.vd(D0)){var E0=$CLJS.ic(D0),Dpa=$CLJS.jc(D0),Epa=E0,Fpa=$CLJS.D(E0);y0=Dpa;z0=Epa;A0=Fpa}else{var Gpa=$CLJS.z(D0);$CLJS.mE(Gpa,v0);y0=$CLJS.B(D0);z0=null;A0=0}B0=0}else break}
$CLJS.G_.m(null,v0,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);$CLJS.z(d);d=$CLJS.B(d);c=$CLJS.J.g(Bpa,c);a:{var e=s0;s0=!0;try{var f=$CLJS.ds([" ",$CLJS.Xg(c)," "].join(""),$CLJS.cf.g($CLJS.Ts($CLJS.e_,a,b),d));break a}finally{s0=e}f=void 0}return t0(f)});$CLJS.H_.m(null,v0,function(){return"expression"});
$CLJS.K_.m(null,$CLJS.uG,function(a,b,c){c=$CLJS.y(c);$CLJS.z(c);c=$CLJS.B(c);$CLJS.z(c);var d=$CLJS.B(c);return $CLJS.Md($CLJS.oE,function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);x=$CLJS.L_.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae($CLJS.L_.j(a,
b,u),k($CLJS.Hc(l)))}return null}},null,null)}(d)}())});$CLJS.G_.m(null,$CLJS.$A,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.e_.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.l0.g(f,c).toLowerCase();0<f?f=$CLJS.ha.j?$CLJS.ha.j("+ %d %s",f,c):$CLJS.ha.call(null,"+ %d %s",f,c):(f=$CLJS.uz(f),f=$CLJS.ha.j?$CLJS.ha.j("- %d %s",f,c):$CLJS.ha.call(null,"- %d %s",f,c));f=t0(f);return[a," ",d.call(b,f)].join("")});
$CLJS.H_.m(null,$CLJS.$A,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.I_.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.l0.g(e,c).toLowerCase();0<e?e=$CLJS.ha.j?$CLJS.ha.j("plus_%s_%s",e,c):$CLJS.ha.call(null,"plus_%s_%s",e,c):(e=$CLJS.uz(e),e=$CLJS.ha.j?$CLJS.ha.j("minus_%d_%s",e,c):$CLJS.ha.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.G_.m(null,$CLJS.jF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.e_.v(a,b,e,d)});$CLJS.H_.m(null,$CLJS.jF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.I_.j(a,b,d)});
$CLJS.Hpa=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(zpa(d,e,f)))throw $CLJS.Uh("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.YQ,f],null));return $CLJS.PV.l(d,e,Apa,$CLJS.H([$CLJS.xV($CLJS.pV.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Ipa=function(){function a(d,e){var f=$CLJS.Be($CLJS.YO.h($CLJS.DV(d,e)));return null==f?null:$CLJS.ck.g($CLJS.Ts(u0,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.F0=function(){function a(d,e){return $CLJS.Be($CLJS.YO.h($CLJS.DV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.mV.m(null,$CLJS.mA,function(a){return a});
$CLJS.Jpa=function(){function a(d,e,f){var k=$CLJS.Wf.g($CLJS.N,$CLJS.vl(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wV(t),m],null)},$CLJS.F0.g(d,e))),l=$CLJS.DV(d,e);d=$CLJS.R_.j(d,e,l);return $CLJS.Be($CLJS.r0(function(m){return $CLJS.Sa(f)||$CLJS.Zj.g($CLJS.IJ.h(m),$CLJS.BY)||$CLJS.J.g(k,$CLJS.U.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Kpa=function(){function a(d,e,f){return $CLJS.oV(u0(d,e,x0.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.G0=$CLJS.jE.h($CLJS.UD);