var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.string.js");require("./metabase.lib.metadata.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var mva,nva,pva,qva,rva,sva;mva=function(a,b){return $CLJS.Ez(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};nva=function(a,b){return $CLJS.dc($CLJS.bb(function(c,d){return $CLJS.Zf.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.ac($CLJS.N),b))};pva=function(a){return $CLJS.Wf.j($CLJS.Sg,$CLJS.ak.g($CLJS.dD(function(b){return $CLJS.RV(b,a)}),$CLJS.cf.h($CLJS.dd)),ova)};
qva=function(a){return new $CLJS.h(null,3,[$CLJS.Qi,$CLJS.tA,$CLJS.U,a,$CLJS.Fi,$CLJS.p.h($CLJS.FD())],null)};
rva=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.U);return $CLJS.sk.l($CLJS.H([a,function(){var c=$CLJS.dh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.I(c,0,null),c=$CLJS.I(c,1,null),c=$CLJS.rV(c)):c=null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.Qi,$CLJS.uM,$CLJS.aQ,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.ma)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.Qi,$CLJS.FL,$CLJS.rL,c],null):null}(),$CLJS.n($CLJS.jD.h(a))?null:new $CLJS.h(null,
1,[$CLJS.jD,$CLJS.$0.g($CLJS.uA,b)],null)]))};$CLJS.F8=function(a){a=$CLJS.S0(a);return $CLJS.n($CLJS.xz(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$y,sva],null)))?$CLJS.Yd.g($CLJS.Sg,$CLJS.kN):$CLJS.Sg};$CLJS.G8=function(a,b){var c=$CLJS.F8(a);return(0,$CLJS.PV)(a,0,function(d){var e=$CLJS.Ys.g(tva,c);d=$CLJS.Ne.j($CLJS.Tj,d,e);d=$CLJS.sk.l($CLJS.H([d,$CLJS.wl(b,c)]));$CLJS.Ys.g(c,$CLJS.Wg($CLJS.Ng(b)));$CLJS.DV(a,0);return d})};$CLJS.H8=function(a){return $CLJS.Be($CLJS.wl($CLJS.DV(a,0),$CLJS.F8(a)))};
$CLJS.I8=function(a){return $CLJS.hP.h($CLJS.DV(a,0))};sva=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);var ova,tva;ova=new $CLJS.P(null,3,5,$CLJS.Q,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.J8=function(){function a(d,e){d=$CLJS.Be(pva(d));var f=$CLJS.Be($CLJS.Wg($CLJS.Ng(e)));if($CLJS.n($CLJS.n(d)?d:f)){var k=$CLJS.Ys.g(d,f);d=$CLJS.Ys.g(f,d);if($CLJS.E.l(1,$CLJS.D(k),$CLJS.H([$CLJS.D(d)]))){d=$CLJS.z(d);k=$CLJS.z(k);f=$CLJS.J.g(e,d);var l=$CLJS.E.g($CLJS.jD.h(f),$CLJS.$0.g($CLJS.uA,d))?$CLJS.$0.g($CLJS.uA,k):$CLJS.jD.h(f);f=$CLJS.R.l($CLJS.Tj.l(f,$CLJS.rL,$CLJS.H([$CLJS.aQ,$CLJS.SO])),$CLJS.jD,l,$CLJS.H([$CLJS.U,k]));e=$CLJS.R.j($CLJS.Tj.g(e,d),k,f)}else e=$CLJS.sk.l($CLJS.H([mva($CLJS.Oe(d),
e),nva($CLJS.U,$CLJS.cf.g(qva,k))]));e=$CLJS.cs(e,rva)}else e=$CLJS.N;return e}function b(d){return $CLJS.J8.g?$CLJS.J8.g(d,null):$CLJS.J8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();tva=new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.kN,null],null),null);
$CLJS.Y(new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kN,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.oD],null)],null));
$CLJS.K8=function(){function a(d,e,f,k){var l=$CLJS.J8.h(e);return $CLJS.G8($CLJS.W0.g(d,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.Rs,$CLJS.$U,$CLJS.mU,f,$CLJS.EP,l,$CLJS.hP,e],null)],null)),k)}function b(d,e){return $CLJS.K8.v?$CLJS.K8.v(d,e,null,null):$CLJS.K8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.L8=function(){function a(d,e,f){$CLJS.DV(d,0);return $CLJS.G8($CLJS.W0.g(e,$CLJS.mK.h(d)),f)}function b(d,e){return $CLJS.L8.j?$CLJS.L8.j(d,e,null):$CLJS.L8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.V0.m(null,$CLJS.$U,function(a){return $CLJS.N0($CLJS.F8(a),$CLJS.Wg($CLJS.Ng($CLJS.H8(a))))&&!$CLJS.Dz($CLJS.I8(a))});