var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var BX,Qna,Rna,Sna,Tna,DX,Vna,Wna,Xna,Yna,FX,GX,HX,JX,Zna,KX,LX,$na,aoa,boa,MX,Una,NX,OX,coa,QX;BX=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.Xe(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
Qna=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi.j?$CLJS.Qi.j(k,l,m):$CLJS.Qi.call(null,k,l,m),$CLJS.Gk.j?$CLJS.Gk.j(k,l,m):$CLJS.Gk.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi.g?$CLJS.Qi.g(k,l):$CLJS.Qi.call(null,k,l),$CLJS.Gk.g?$CLJS.Gk.g(k,l):$CLJS.Gk.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi.h?$CLJS.Qi.h(k):$CLJS.Qi.call(null,k),$CLJS.Gk.h?$CLJS.Gk.h(k):$CLJS.Gk.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi.o?$CLJS.Qi.o():$CLJS.Qi.call(null),$CLJS.Gk.o?$CLJS.Gk.o():$CLJS.Gk.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.w(A,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ne.M($CLJS.Qi,m,t,u,v),$CLJS.Ne.M($CLJS.Gk,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.z(m);m=$CLJS.B(m);var u=$CLJS.z(m);m=$CLJS.B(m);var v=$CLJS.z(m);m=$CLJS.Hc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};$CLJS.CX=function(a,b){return $CLJS.PK(a)(b,$CLJS.xf,$CLJS.xf)};Rna=function(a,b,c){return $CLJS.bb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.WE,b),$CLJS.Sr(2,2,c))};Sna=function(a,b){var c=$CLJS.xl(a,$CLJS.EP);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Cb(c);b=$CLJS.cs.g?$CLJS.cs.g(c,b):$CLJS.cs.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.EP,b)}return a};
Tna=function(a){return $CLJS.bf.g($CLJS.KU(a),$CLJS.LU(a))};DX=function(a,b,c){var d=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.zr,null,$CLJS.Lk,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.ot(2,c):$CLJS.ot(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?BX(2,c):BX(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.tW.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.ud(k)?$CLJS.Wf.j($CLJS.xf,$CLJS.bk.h($CLJS.Sk),l):$CLJS.sd(k)?$CLJS.Fz($CLJS.Ta,l):l}):$CLJS.Tj.g(a,f)};
Vna=function(a){return $CLJS.bb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return DX(b,Una,d)},a,Tna(a))};
Wna=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.mK.h(a),b);a:{var d=c;for(var e=$CLJS.xf;;){var f=$CLJS.z($CLJS.cf.g(Qna(),$CLJS.ff($CLJS.ak.j(EX,$CLJS.z,$CLJS.Gk),$CLJS.Dr.h($CLJS.CX($CLJS.jV,d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=DX(d,f,k),m=$CLJS.Iz($CLJS.Ax);if($CLJS.n($CLJS.Hz("metabase.lib.convert",m))){var t=$CLJS.yA.l($CLJS.H([$CLJS.uy,$CLJS.xh.l($CLJS.H([k]))])),u=$CLJS.yA,v=u.l,x=$CLJS.xh,A=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.UK($CLJS.CX($CLJS.jV,d));
$CLJS.Gz("metabase.lib.convert",m,$CLJS.sV("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.uy,A.call(x,$CLJS.H([C]))])),$CLJS.yA.l($CLJS.H([$CLJS.jy,$CLJS.tV($CLJS.z($CLJS.Vs(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.Yd.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=Vna(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.mK.h(a))-1))return a;b+=1}else a=$CLJS.dk.M(a,$CLJS.mK,$CLJS.R,b,d)}};
Xna=function(a){var b=$CLJS.GV();return $CLJS.ck.g(function(c){return $CLJS.E.g($CLJS.SD.h(c),"__join")?$CLJS.dk.j(c,$CLJS.SD,b):c},a)};Yna=function(a){return"string"===typeof $CLJS.dO.h(a)?$CLJS.Tj.g($CLJS.R.j(a,$CLJS.MU,$CLJS.EV($CLJS.dO.h(a))),$CLJS.dO):a};FX=function(){return $CLJS.bk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.he(b))?(b=$CLJS.de(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};
GX=function(a){return $CLJS.Wf.j($CLJS.N,FX(),a)};HX=function(a){return $CLJS.Be($CLJS.Wf.j($CLJS.N,$CLJS.ak.g(FX(),$CLJS.bk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.mi)})),a))};
JX=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d=$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.h($CLJS.IX),c);a=$CLJS.E.g(a,$CLJS.PE)&&$CLJS.B(c)?$CLJS.Yd.g($CLJS.nd(d),new $CLJS.h(null,1,[$CLJS.Oh,$CLJS.md(d)],null)):d;b=$CLJS.Be(HX(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tL,a,b],null):a};Zna=function(a){return $CLJS.Wf.j($CLJS.xf,$CLJS.ak.g($CLJS.cf.h(function(b){return $CLJS.Az(b,$CLJS.DA)}),$CLJS.cf.h($CLJS.IX)),$CLJS.AV.h(a))};
KX=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.mK);a=$CLJS.z($CLJS.bb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.IX.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.GP,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.QN,Zna(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.mU.h(c)],null)},null,a));return $CLJS.n($CLJS.hP.h(a))?$CLJS.xU(a,new $CLJS.h(null,1,[$CLJS.hP,$CLJS.CE],null)):a};
LX=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.SV(a,b,$CLJS.ak.g($CLJS.IX,$CLJS.z)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.SV(d,b,function(e){return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.gr],null),$CLJS.cf.h($CLJS.IX),e)}):d;return $CLJS.xU(a,$CLJS.Ae([b,c]))};$na=function(a){var b=$CLJS.MU.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Tj.g(a,$CLJS.MU),$CLJS.dO,["card__",$CLJS.p.h(b)].join("")):a};
aoa=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);boa=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);MX=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Una=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);NX=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
OX=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.PX=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);coa=new $CLJS.M(null,"m","m",1632677161);QX=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var EX,doa,eoa,foa,goa,hoa,ioa,joa,koa,loa,moa;$CLJS.RX=$CLJS.N;$CLJS.SX=$CLJS.N;EX=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.gV,null,$CLJS.OD,null,$CLJS.CM,null,$CLJS.YO,null,$CLJS.VE,null,$CLJS.MQ,null,$CLJS.WE,null],null),null);doa=$CLJS.Se($CLJS.N);eoa=$CLJS.Se($CLJS.N);foa=$CLJS.Se($CLJS.N);goa=$CLJS.Se($CLJS.N);hoa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.eB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.TX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.VA,hoa,doa,eoa,foa,goa);$CLJS.TX.m(null,$CLJS.Oh,function(a){if($CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M){var b=$CLJS.md(a);var c=$CLJS.sd(b);a=c?$CLJS.nd(a):a;var d=$CLJS.y(a);a=$CLJS.z(d);d=$CLJS.B(d);b=$CLJS.nV($CLJS.Wf.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.cf.h($CLJS.TX),d))}else b=a;return b});$CLJS.TX.m(null,$CLJS.fV,function(a){return a});
$CLJS.TX.m(null,$CLJS.TU,function(a){var b=$CLJS.TX.h($CLJS.WE.h(a)),c=$CLJS.Be($CLJS.ck.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.xV($CLJS.TX.h(t),u)},$CLJS.YO.h(a))),d=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.vD);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.vD);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.SX,k=$CLJS.RX;$CLJS.SX=d;$CLJS.RX=e;try{var l=Rna(Yna(a),b,$CLJS.H([$CLJS.YO,c])),m=$CLJS.bb(function(t,u){return $CLJS.Sa($CLJS.J.g(t,u))?t:$CLJS.dk.j(t,u,$CLJS.TX)},l,$CLJS.Uj.l(EX,$CLJS.WE,$CLJS.H([$CLJS.YO])));return $CLJS.n($CLJS.CM.h(m))?$CLJS.dk.j(m,$CLJS.CM,Xna):m}finally{$CLJS.RX=k,$CLJS.SX=f}});$CLJS.TX.m(null,$CLJS.$U,function(a){return Sna(a,function(b){return $CLJS.SV(b,$CLJS.zE,$CLJS.TX)})});
$CLJS.TX.m(null,$CLJS.hK,function(a){a=$CLJS.dk.j($CLJS.dk.j(a,$CLJS.pK,$CLJS.TX),$CLJS.mK,$CLJS.TX);var b=$CLJS.n($CLJS.OD.h(a))?$CLJS.dk.j(a,$CLJS.OD,function(c){return $CLJS.dl(c)?$CLJS.ck.g($CLJS.TX,c):$CLJS.mh.h(c)}):a;return $CLJS.Sa($CLJS.SD.h(a))?$CLJS.R.j(b,$CLJS.SD,"__join"):b});$CLJS.TX.m(null,$CLJS.jA,function(a){return $CLJS.ck.g($CLJS.TX,a)});
$CLJS.TX.m(null,$CLJS.lA,function(a){return $CLJS.n($CLJS.Qi.h(a))?Wna($CLJS.R.j($CLJS.dk.j($CLJS.BV(a),$CLJS.mK,function(b){return $CLJS.ck.g($CLJS.TX,b)}),$CLJS.PX,!0)):$CLJS.cs(a,$CLJS.TX)});$CLJS.TX.m(null,$CLJS.gF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.sd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.nV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,a,b],null))});
$CLJS.TX.m(null,$CLJS.cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xU(a,new $CLJS.h(null,3,[$CLJS.aC,$CLJS.pA,$CLJS.CN,$CLJS.pj,$CLJS.UQ,$CLJS.qD],null));var c=$CLJS.R.j;var d=$CLJS.mi.h(a);$CLJS.n(d)||(d=$CLJS.pA.h(a),d=$CLJS.n(d)?d:$CLJS.JD(b));a=c.call($CLJS.R,a,$CLJS.mi,d);return $CLJS.nV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cj,a,b],null))});
$CLJS.TX.m(null,$CLJS.PE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.Oh.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PE,$CLJS.Tj.g(c,$CLJS.Oh),$CLJS.ck.g($CLJS.TX,b)],null);b=$CLJS.nV(b);return null!=a?$CLJS.Yd.g(b,$CLJS.TX.h(a)):b});$CLJS.TX.m(null,$CLJS.mA,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.nV(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.TX.m(null,$CLJS.WE,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null),e=$CLJS.Q;var f=$CLJS.J.g($CLJS.SX,c);if(!$CLJS.n(f))throw $CLJS.Uh(["Error converting :aggregation reference: no aggregation at index ",$CLJS.p.h(c)].join(""),new $CLJS.h(null,1,[$CLJS.XQ,a],null));return $CLJS.nV(new $CLJS.P(null,3,5,e,[b,d,f],null))});
$CLJS.TX.m(null,$CLJS.tL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.TX.h(b);var c=$CLJS.y(b);b=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.sk.l($CLJS.H([c,a]))],null),d)});ioa=$CLJS.Se($CLJS.N);joa=$CLJS.Se($CLJS.N);koa=$CLJS.Se($CLJS.N);loa=$CLJS.Se($CLJS.N);moa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.eB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.IX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.VA,moa,ioa,joa,koa,loa);
$CLJS.IX.m(null,$CLJS.Oh,function(a){if($CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);if($CLJS.sd(a)){c=$CLJS.Wf.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.bf.g;d=$CLJS.cf.g($CLJS.IX,d);a=HX(a);a=c.call($CLJS.Wf,b,e.call($CLJS.bf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.cf.g($CLJS.IX,$CLJS.ae(a,d)))}else a=$CLJS.sd(a)?$CLJS.cs(GX(a),$CLJS.IX):
a;return a});for(var UX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[QX,OX],null)),VX=null,WX=0,XX=0;;)if(XX<WX){var noa=VX.X(null,XX);$CLJS.mE(noa,NX);XX+=1}else{var YX=$CLJS.y(UX);if(YX){var ZX=YX;if($CLJS.vd(ZX)){var $X=$CLJS.ic(ZX),ooa=$CLJS.jc(ZX),poa=$X,qoa=$CLJS.D($X);UX=ooa;VX=poa;WX=qoa}else{var roa=$CLJS.z(ZX);$CLJS.mE(roa,NX);UX=$CLJS.B(ZX);VX=null;WX=0}XX=0}else break}
for(var aY=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Nw,$CLJS.aF,$CLJS.pF,$CLJS.LE,$CLJS.yj,$CLJS.tF,$CLJS.bn,$CLJS.SE,$CLJS.nF,$CLJS.uF,$CLJS.sF,$CLJS.zF],null)),bY=null,cY=0,dY=0;;)if(dY<cY){var soa=bY.X(null,dY);$CLJS.mE(soa,QX);dY+=1}else{var eY=$CLJS.y(aY);if(eY){var fY=eY;if($CLJS.vd(fY)){var gY=$CLJS.ic(fY),toa=$CLJS.jc(fY),uoa=gY,voa=$CLJS.D(gY);aY=toa;bY=uoa;cY=voa}else{var woa=$CLJS.z(fY);$CLJS.mE(woa,QX);aY=$CLJS.B(fY);bY=null;cY=0}dY=0}else break}
for(var hY=$CLJS.y($CLJS.tf([$CLJS.Eq,$CLJS.Vr,$CLJS.Fq,$CLJS.AF,$CLJS.PE,$CLJS.jF,$CLJS.GE,$CLJS.CF,$CLJS.xE,$CLJS.UE,$CLJS.BE,$CLJS.eF,$CLJS.lF,$CLJS.DE,$CLJS.XA,$CLJS.YA,$CLJS.Bx,$CLJS.SH,$CLJS.XH,$CLJS.MH,$CLJS.ZA,$CLJS.RH,$CLJS.KH,$CLJS.$H,$CLJS.eI,$CLJS.bI,$CLJS.QH,$CLJS.gI,$CLJS.$A,$CLJS.cB,$CLJS.FE,$CLJS.yE,$CLJS.DF,$CLJS.SG,$CLJS.iF,$CLJS.Lw,$CLJS.vF,$CLJS.xF,$CLJS.EF,$CLJS.fs,$CLJS.ls],!0)),iY=null,jY=0,kY=0;;)if(kY<jY){var xoa=iY.X(null,kY);$CLJS.mE(xoa,OX);kY+=1}else{var lY=$CLJS.y(hY);
if(lY){var mY=lY;if($CLJS.vd(mY)){var nY=$CLJS.ic(mY),yoa=$CLJS.jc(mY),zoa=nY,Aoa=$CLJS.D(nY);hY=yoa;iY=zoa;jY=Aoa}else{var Boa=$CLJS.z(mY);$CLJS.mE(Boa,OX);hY=$CLJS.B(mY);iY=null;jY=0}kY=0}else break}$CLJS.IX.m(null,NX,function(a){return JX(a)});$CLJS.IX.m(null,$CLJS.lA,function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.ak.g(FX(),$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.IX.h(b)],null)})),a)});
$CLJS.IX.m(null,$CLJS.WE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.sd(b))try{var d=HX(b);b=$CLJS.Q;var e=$CLJS.RX,f=$CLJS.J.j(e,c,MX);if($CLJS.E.g(f,MX))throw $CLJS.Uh(["Unable to find key ",$CLJS.xh.l($CLJS.H([c]))," in map."].join(""),new $CLJS.h(null,2,[coa,e,$CLJS.Rv,c],null));var k=new $CLJS.P(null,2,5,b,[$CLJS.WE,f],null);return $CLJS.y(d)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tL,k,d],null):k}catch(l){throw c=l,d=$CLJS.rU(c),d=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",
d):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",d),$CLJS.Th(d,new $CLJS.h(null,1,[$CLJS.W,a],null),c);}else return a});$CLJS.IX.m(null,$CLJS.jA,function(a){return $CLJS.ck.g($CLJS.IX,a)});
$CLJS.IX.m(null,$CLJS.gF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.sd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,$CLJS.IX.h(a),HX(b)],null)});
$CLJS.IX.m(null,$CLJS.cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=HX($CLJS.xU(b,new $CLJS.h(null,3,[$CLJS.pA,$CLJS.aC,$CLJS.pj,$CLJS.CN,$CLJS.qD,$CLJS.UQ],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cj,a,b],null)});
$CLJS.IX.m(null,$CLJS.hK,function(a){var b=GX(a);a=0==$CLJS.SD.h(a).lastIndexOf("__join",0)?$CLJS.Tj.g(b,$CLJS.SD):b;return $CLJS.sk.l($CLJS.H([$CLJS.cs($CLJS.Tj.l(a,$CLJS.mK,$CLJS.H([$CLJS.pK])),$CLJS.IX),LX($CLJS.wl(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.pK],null)),$CLJS.pK,$CLJS.OQ),KX(a)]))});
$CLJS.IX.m(null,$CLJS.TU,function(a){var b=$CLJS.WE.h(a),c=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.vD);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.vD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.SX,e=$CLJS.RX;$CLJS.SX=c;$CLJS.RX=b;try{return $CLJS.bb(function(f,k){return $CLJS.SV(f,
k,$CLJS.IX)},LX($CLJS.SV($CLJS.SV($na(GX(a)),$CLJS.WE,function(f){return $CLJS.ck.g(JX,f)}),$CLJS.YO,function(f){return $CLJS.Wf.g($CLJS.N,function(){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.vd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A),G=$CLJS.IX.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wV(C),$CLJS.E.g($CLJS.cj,$CLJS.z(G))?$CLJS.dd(G):G],null);x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),
m($CLJS.jc(t))):$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);u=$CLJS.IX.h(x);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wV(x),$CLJS.E.g($CLJS.cj,$CLJS.z(u))?$CLJS.dd(u):u],null),m($CLJS.Hc(t)))}return null}},null,null)}(f)}())}),$CLJS.gV,$CLJS.KE),$CLJS.Uj.l(EX,$CLJS.WE,$CLJS.H([$CLJS.gV,$CLJS.YO])))}finally{$CLJS.RX=e,$CLJS.SX=d}});$CLJS.IX.m(null,$CLJS.$U,function(a){return $CLJS.cs(GX(a),$CLJS.IX)});
$CLJS.IX.m(null,$CLJS.fV,function(a){try{var b=GX(a),c=$CLJS.tM.h(b),d=KX(b),e=$CLJS.E.g($CLJS.Rs.h($CLJS.fd($CLJS.mK.h(a))),$CLJS.$U)?$CLJS.hP:$CLJS.CE;return $CLJS.sk.l($CLJS.H([$CLJS.Tj.l(b,$CLJS.mK,$CLJS.H([$CLJS.tM,$CLJS.PX])),function(){var k=$CLJS.Ae([$CLJS.Qi,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.tM,c):k}()]))}catch(k){var f=k;throw $CLJS.Th(function(){var l=$CLJS.rU(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.CE,a],null),f);}});
$CLJS.oY=function(){function a(d,e,f){f=$CLJS.AX(null,$CLJS.Hy(f,$CLJS.H([$CLJS.hi,!0])));var k=$CLJS.DV(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.WE);k=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.vD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.Wf.j($CLJS.N,$CLJS.yl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.vD);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.SX,t=$CLJS.RX;$CLJS.SX=k;$CLJS.RX=l;try{try{return $CLJS.TX.h(f)}catch(v){var u=v;throw $CLJS.Th(function(){var x=$CLJS.rU(u);return $CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.CE,d,$CLJS.DK,e,boa,f,aoa,$CLJS.SX],null),u);}}finally{$CLJS.RX=t,$CLJS.SX=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();