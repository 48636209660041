var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var LH,NH,PH,YH,aI,fI;$CLJS.KH=new $CLJS.M(null,"get-month","get-month",-369374731);LH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.MH=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);NH=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.OH=new $CLJS.M(null,"iso","iso",-1366207543);PH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.QH=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.RH=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.SH=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.TH=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.UH=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.VH=new $CLJS.M(null,"us","us",746429226);$CLJS.WH=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.XH=new $CLJS.M(null,"now","now",-1650525531);
YH=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.ZH=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.$H=new $CLJS.M(null,"get-day","get-day",127568857);aI=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.bI=new $CLJS.M(null,"get-minute","get-minute",1468086343);
$CLJS.cI=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.dI=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);$CLJS.eI=new $CLJS.M(null,"get-hour","get-hour",622714059);fI=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.gI=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);
$CLJS.hI=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);$CLJS.iI=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.jI=new $CLJS.M(null,"target","target",253001721);$CLJS.tE($CLJS.XA,$CLJS.H([$CLJS.Vr,$CLJS.ZB,$CLJS.lr,$CLJS.bD]));$CLJS.ID.m(null,$CLJS.TH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.JD(a);return $CLJS.qd(a)?(a=$CLJS.Zs.g(a,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.yk,null,$CLJS.Ak,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.z(a):a):a});$CLJS.mE($CLJS.TH,$CLJS.ZD);
for(var kI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,$CLJS.cB],null)),lI=null,mI=0,nI=0;;)if(nI<mI){var oI=lI.X(null,nI);$CLJS.tE(oI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null),$CLJS.lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bD],null)]));$CLJS.mE(oI,$CLJS.TH);nI+=1}else{var pI=$CLJS.y(kI);if(pI){var qI=pI;if($CLJS.vd(qI)){var rI=$CLJS.ic(qI),yia=$CLJS.jc(qI),zia=rI,Aia=$CLJS.D(rI);kI=yia;lI=zia;mI=Aia}else{var sI=$CLJS.z(qI);$CLJS.tE(sI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.RD],null),$CLJS.lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bD],null)]));$CLJS.mE(sI,$CLJS.TH);kI=$CLJS.B(qI);lI=null;mI=0}nI=0}else break}
for(var tI=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.RH,$CLJS.KH,$CLJS.$H,$CLJS.eI,$CLJS.bI,$CLJS.QH,$CLJS.gI],null)),uI=null,vI=0,wI=0;;)if(wI<vI){var Bia=uI.X(null,wI);$CLJS.tE(Bia,$CLJS.H([$CLJS.Vr,$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null)]));wI+=1}else{var xI=$CLJS.y(tI);if(xI){var yI=xI;if($CLJS.vd(yI)){var zI=$CLJS.ic(yI),Cia=$CLJS.jc(yI),Dia=zI,Eia=$CLJS.D(zI);tI=Cia;uI=Dia;vI=Eia}else{var Fia=$CLJS.z(yI);$CLJS.tE(Fia,$CLJS.H([$CLJS.Vr,
$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null)]));tI=$CLJS.B(yI);uI=null;vI=0}wI=0}else break}$CLJS.tE($CLJS.WA,$CLJS.H([$CLJS.Vr,$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UF],null)]));
for(var AI=$CLJS.y(new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.gI,null,$CLJS.bI,null,$CLJS.eI,null,$CLJS.cI,null,$CLJS.QH,null,$CLJS.KH,null,$CLJS.RH,null,$CLJS.$H,null],null),null)),BI=null,CI=0,DI=0;;)if(DI<CI){var Gia=BI.X(null,DI);$CLJS.tE(Gia,$CLJS.H([$CLJS.Vr,$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null)]));DI+=1}else{var EI=$CLJS.y(AI);if(EI){var FI=EI;if($CLJS.vd(FI)){var GI=$CLJS.ic(FI),Hia=$CLJS.jc(FI),Iia=GI,Jia=$CLJS.D(GI);
AI=Hia;BI=Iia;CI=Jia}else{var Kia=$CLJS.z(FI);$CLJS.tE(Kia,$CLJS.H([$CLJS.Vr,$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null)]));AI=$CLJS.B(FI);BI=null;CI=0}DI=0}else break}$CLJS.Y(fI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Xq,$CLJS.OH,$CLJS.VH,$CLJS.ZH],null));
$CLJS.vE($CLJS.ZA,$CLJS.H([$CLJS.Vr,$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fI],null)],null)],null)],null)]));
$CLJS.Y($CLJS.dI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.oD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,2,[$CLJS.ps,"valid timezone ID",$CLJS.As,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.cj);return["invalid timezone ID: ",$CLJS.xh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.yz($CLJS.Dd,$CLJS.JH.tz.names())),$CLJS.AH],null)],null));
$CLJS.vE($CLJS.MH,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uy,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dI],null)],null)],null)],
null)]));$CLJS.mE($CLJS.MH,$CLJS.TH);$CLJS.tE($CLJS.XH,$CLJS.H([$CLJS.Vr,$CLJS.zk]));$CLJS.Y(PH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.ps,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Me(function(b){return $CLJS.zz(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.Ak],null))}],null)],null));
$CLJS.Y(LH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pA,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PH],null)],null)],null)],null));
$CLJS.FF.g($CLJS.SH,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.h(null,1,[$CLJS.ps,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.SH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LH],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.h(null,1,[$CLJS.ps,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.$q,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VF],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.h(null,1,[$CLJS.ps,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.Mu],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RF],null)],null)],null)],null)],null)],null));
$CLJS.ID.m(null,$CLJS.SH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Mu)?$CLJS.E.g(a,$CLJS.Oh)?$CLJS.Ak:$CLJS.n($CLJS.lE($CLJS.VF,a))?$CLJS.yk:$CLJS.Ak:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.dh($CLJS.uH,b))?$CLJS.yk:$CLJS.n($CLJS.dh($CLJS.vH,b))?$CLJS.yk:null:null;if($CLJS.n(a))return a;b=$CLJS.JD(b);b=$CLJS.qd(b)?$CLJS.Wf.j($CLJS.Sg,$CLJS.ef(function(c){return $CLJS.zz(c,$CLJS.qj)}),b):b;return $CLJS.qd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.z(b):b});$CLJS.Y(aI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.Mu],null),$CLJS.lr],null));
$CLJS.vE($CLJS.YA,$CLJS.H([$CLJS.Vr,$CLJS.Ak,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bD],null)],null)],null)],null)]));
$CLJS.tE($CLJS.Bx,$CLJS.H([$CLJS.Vr,$CLJS.Fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aD],null)]));$CLJS.Y(NH,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Xq,$CLJS.TF,$CLJS.ii,$CLJS.Aj,$CLJS.UH,$CLJS.hI,$CLJS.iI,$CLJS.ej,$CLJS.ni,$CLJS.ei,$CLJS.Oi,$CLJS.QF],null));$CLJS.Y(YH,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Xq,$CLJS.OH,$CLJS.VH,$CLJS.ZH],null));
$CLJS.vE($CLJS.aB,$CLJS.H([$CLJS.Vr,$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YH],null)],null)],
null)],null)]));