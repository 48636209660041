var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var G3,I3,K3,L3,M3,Ira;G3=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=G3[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=G3._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Za("MetadataProvider.metric",a);}return a};$CLJS.H3=function(a,b){return G3($CLJS.XZ(a),b)};
I3=function(a,b,c){var d=$CLJS.DV(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.WE);d=$CLJS.nG($CLJS.ak.j($CLJS.Tg([c]),$CLJS.vD,$CLJS.dd),d);if(!$CLJS.n(d))throw $CLJS.Uh($CLJS.eD("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.or,c,$CLJS.CE,a,$CLJS.DK,b],null));return d};$CLJS.J3=function(a){return $CLJS.z1($CLJS.sF,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};K3=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);
L3=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);M3=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Ira=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.J_.m(null,$CLJS.WE,function(a,b){var c=$CLJS.Be($CLJS.WE.h($CLJS.DV(a,b)));return $CLJS.n(c)?$CLJS.C1($CLJS.GD("and"),function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u);v=$CLJS.e_.v(a,b,v,$CLJS.f_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}t=$CLJS.z(k);return $CLJS.ae($CLJS.e_.v(a,
b,t,$CLJS.f_),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):null});$CLJS.M_.m(null,$CLJS.WE,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.pA);e=$CLJS.J.g(e,$CLJS.mi);c=$CLJS.I(c,2,null);c=I3(a,b,c);return $CLJS.sk.l($CLJS.H([$CLJS.c_.j(a,b,c),new $CLJS.h(null,2,[$CLJS.IJ,$CLJS.fK,$CLJS.w0,$CLJS.vD.h($CLJS.dd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.pA,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.mi,e],null):null]))});
$CLJS.G_.m(null,$CLJS.WE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.e_.v(a,b,I3(a,b,c),d)});$CLJS.mE(L3,M3);
for(var N3=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nw,$CLJS.hF],null)),O3=null,P3=0,Q3=0;;)if(Q3<P3){var Jra=O3.X(null,Q3);$CLJS.mE(Jra,L3);Q3+=1}else{var R3=$CLJS.y(N3);if(R3){var S3=R3;if($CLJS.vd(S3)){var T3=$CLJS.ic(S3),Kra=$CLJS.jc(S3),Lra=T3,Mra=$CLJS.D(T3);N3=Kra;O3=Lra;P3=Mra}else{var Nra=$CLJS.z(S3);$CLJS.mE(Nra,L3);N3=$CLJS.B(S3);O3=null;P3=0}Q3=0}else break}
$CLJS.G_.m(null,L3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.e_.v(a,b,c,d),e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.eD("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.eD("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.GD("Count");case "cum-count":return $CLJS.GD("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.H_.m(null,L3,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.M_.m(null,L3,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.gH($CLJS.M_,M3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.pj,$CLJS.QB)});$CLJS.G_.m(null,$CLJS.PE,function(){return $CLJS.GD("Case")});
$CLJS.H_.m(null,$CLJS.PE,function(){return"case"});$CLJS.mE(K3,M3);for(var U3=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.aF,$CLJS.BF,$CLJS.LE,$CLJS.yj,$CLJS.tF,$CLJS.bn,$CLJS.uF,$CLJS.sF,$CLJS.ME],null)),V3=null,W3=0,X3=0;;)if(X3<W3){var Ora=V3.X(null,X3);$CLJS.mE(Ora,K3);X3+=1}else{var Y3=$CLJS.y(U3);if(Y3){var Z3=Y3;if($CLJS.vd(Z3)){var $3=$CLJS.ic(Z3),Pra=$CLJS.jc(Z3),Qra=$3,Rra=$CLJS.D($3);U3=Pra;V3=Qra;W3=Rra}else{var Sra=$CLJS.z(Z3);$CLJS.mE(Sra,K3);U3=$CLJS.B(Z3);V3=null;W3=0}X3=0}else break}
$CLJS.H_.m(null,K3,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.G_.m(null,K3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.e_.v(a,b,c,d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "avg":return $CLJS.eD("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.eD("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.eD("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.eD("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.eD("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.eD("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.eD("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.eD("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.eD("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.G_.m(null,$CLJS.SE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.eD("{0}th percentile of {1}",$CLJS.H([c,$CLJS.e_.v(a,b,e,d)]))});$CLJS.H_.m(null,$CLJS.SE,function(){return"percentile"});
$CLJS.mE($CLJS.SE,M3);$CLJS.G_.m(null,$CLJS.zF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.eD("Sum of {0} matching condition",$CLJS.H([$CLJS.e_.v(a,b,e,d)]))});$CLJS.H_.m(null,$CLJS.zF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.I_.j(a,b,d))].join("")});$CLJS.mE($CLJS.zF,M3);$CLJS.G_.m(null,$CLJS.nF,function(){return $CLJS.GD("Share of rows matching condition")});
$CLJS.H_.m(null,$CLJS.nF,function(){return"share"});$CLJS.mE($CLJS.nF,M3);$CLJS.G_.m(null,$CLJS.pF,function(){return $CLJS.GD("Count of rows matching condition")});$CLJS.H_.m(null,$CLJS.pF,function(){return"count-where"});$CLJS.mE($CLJS.pF,M3);
$CLJS.M_.m(null,M3,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.sk.l;d=$CLJS.n(d)?$CLJS.wl($CLJS.c_.j(a,b,d),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.WJ],null)):null;var f=$CLJS.gH($CLJS.M_,$CLJS.Oh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.sk,$CLJS.H([d,a]))});
$CLJS.Tra=function(){function a(d){return $CLJS.z1($CLJS.Nw,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.z1($CLJS.Nw,$CLJS.xf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.mV.m(null,$CLJS.WE,function(a){return a});
$CLJS.a4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.VA(f),$CLJS.MJ))f=$CLJS.oV(f);else return $CLJS.E1(d,e,$CLJS.WE,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.b4=function(){function a(d,e){return $CLJS.Be($CLJS.WE.h($CLJS.DV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.c4=function(){function a(d,e){var f=$CLJS.Be($CLJS.WE.h($CLJS.DV(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.xf,$CLJS.cf.h(function(k){var l=$CLJS.c_.j(d,e,k),m=$CLJS.R.l,t=$CLJS.pA.h(l);return m.call($CLJS.R,$CLJS.y1(l,$CLJS.mi,$CLJS.n(t)?t:$CLJS.aj),$CLJS.IJ,$CLJS.fK,$CLJS.H([$CLJS.w0,$CLJS.vD.h($CLJS.dd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.G_.m(null,$CLJS.NF,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.JF);return $CLJS.jD.h(a.o?a.o():a.call(null))});$CLJS.N_.m(null,$CLJS.NF,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.$E);b=$CLJS.J.g(d,$CLJS.JF);c=$CLJS.J.g(d,$CLJS.IF);d=$CLJS.J.g(d,$CLJS.JY);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.E_,$CLJS.Wz(a),$CLJS.H([$CLJS.C_,c]));return null!=d?$CLJS.R.j(a,$CLJS.m_,d):a});
$CLJS.Ura=function(){function a(d,e){var f=function(){var m=$CLJS.$y.h($CLJS.S0(d));return $CLJS.n(m)?m:$CLJS.Sg}(),k=$CLJS.DV(d,e),l=$CLJS.R_.j(d,e,k);return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.ak.j($CLJS.ef(function(m){m=$CLJS.LF.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.ol(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.IF),u=$CLJS.J.g(m,$CLJS.GF);if($CLJS.Sa(t))return m;if($CLJS.E.g(u,$CLJS.qr))return $CLJS.R.j(m,$CLJS.AV,l);t=$CLJS.Be($CLJS.r0(function(v){return $CLJS.xZ(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.AV,t):null}),$CLJS.cf.h(function(m){return $CLJS.R.j(m,$CLJS.Rs,$CLJS.NF)})),$CLJS.OF))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Vra=function(){function a(d,e){return $CLJS.nV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$E.h(d),$CLJS.N,$CLJS.pV.h(e)],null))}function b(d){if($CLJS.Sa($CLJS.IF.h(d)))return $CLJS.nV(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$E.h(d),$CLJS.N],null));var e=$CLJS.$E.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.Uh(e,new $CLJS.h(null,1,[Ira,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();