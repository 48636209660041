var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var mha,nha,oha,pha,qha,rha,sha;$CLJS.PF=new $CLJS.M(null,"second","second",-444702010);$CLJS.QF=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);$CLJS.RF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.SF=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.TF=new $CLJS.M(null,"year-of-era","year-of-era",682445876);
mha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);nha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.UF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);
$CLJS.VF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);oha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);pha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
qha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);rha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.WF=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.XF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);
$CLJS.YF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);sha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var ZF=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ni,$CLJS.ej,$CLJS.dj,$CLJS.bi,$CLJS.Aj,$CLJS.ii,$CLJS.Wh,$CLJS.TF],null),$F=$CLJS.Wg(ZF),tha,uha,vha;$CLJS.Y(qha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ps,"Valid date extraction unit"],null)],null),$F));var aG=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fj,$CLJS.Si,$CLJS.Yi,$CLJS.ai,$CLJS.Wh],null),bG=$CLJS.Wg(aG);
$CLJS.Y(pha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ps,"Valid date truncation unit"],null)],null),bG));$CLJS.cG=$CLJS.Wf.j($CLJS.xf,$CLJS.tk.o(),$CLJS.bf.g(aG,ZF));tha=$CLJS.Wg($CLJS.cG);$CLJS.Y($CLJS.VF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ps,"Valid date bucketing unit"],null)],null),tha));var dG=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QF,$CLJS.Oi,$CLJS.ei],null),eG=$CLJS.Wg(dG);
$CLJS.Y(oha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ps,"Valid time extraction unit"],null)],null),eG));var fG=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.SF,$CLJS.PF,$CLJS.zi,$CLJS.Ej],null),gG=$CLJS.Wg(fG);$CLJS.Y(sha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ps,"Valid time truncation unit"],null)],null),gG));$CLJS.hG=$CLJS.Wf.j($CLJS.xf,$CLJS.tk.o(),$CLJS.bf.g(fG,dG));uha=$CLJS.Wg($CLJS.hG);
$CLJS.Y(nha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ps,"Valid time bucketing unit"],null)],null),uha));$CLJS.iG=$CLJS.Wf.j($CLJS.xf,$CLJS.tk.o(),$CLJS.bf.l(fG,aG,$CLJS.H([dG,ZF])));vha=$CLJS.Wg($CLJS.iG);$CLJS.Y($CLJS.RF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ps,"Valid datetime bucketing unit"],null)],null),$CLJS.iG));var wha=$CLJS.Yd.g(vha,$CLJS.Oh);
$CLJS.Y($CLJS.XF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ps,"Valid temporal bucketing unit"],null)],null),wha));$CLJS.jG=$CLJS.Ss.g(bG,gG);$CLJS.Y($CLJS.UF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ps,"Valid datetime truncation unit"],null)],null),$CLJS.jG));$CLJS.kG=$CLJS.Ss.g($F,eG);$CLJS.Y(mha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ps,"Valid datetime extraction unit"],null)],null),$CLJS.kG));
var lG=$CLJS.Yd.g(bG,$CLJS.Wh);$CLJS.Y($CLJS.YF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ps,"Valid date interval unit"],null)],null),lG));$CLJS.Y($CLJS.aD,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ps,"Valid time interval unit"],null)],null),gG));var xha=$CLJS.Ss.g(lG,gG);$CLJS.Y($CLJS.bD,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ps,"Valid datetime interval unit"],null)],null),xha));
$CLJS.Y(rha,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.WF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xk,$CLJS.XF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.pr],null)],null));