var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Toa,SZ,Uoa,UZ,VZ,WZ,r_,Xoa,Woa,Yoa,Voa,y_,i_,A_,d_,Zoa,k_;Toa=function(a){return $CLJS.y(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};SZ=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Cd($CLJS.Zfa,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
Uoa=function(a){var b=$CLJS.O(new $CLJS.h(null,1,[$CLJS.rA,!0],null)),c=$CLJS.J.g(b,$CLJS.Yfa),d=$CLJS.J.g(b,$CLJS.rA);return $CLJS.y(a)?(b=$CLJS.uU(function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);x=SZ(x,$CLJS.Sa(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae(SZ(u,
$CLJS.Sa(d)),k($CLJS.Hc(l)))}return null}},null,null)}(Toa($CLJS.Xz(a)))}()),$CLJS.n(c)?$CLJS.uU($CLJS.We(c,b)):b):null};$CLJS.TZ=function(a){return $CLJS.dO.h($CLJS.z($CLJS.mK.h(a)))};UZ=function(a,b){if(null!=a&&null!=a.Ag)a=a.Ag(a,b);else{var c=UZ[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=UZ._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Za("MetadataProvider.table",a);}return a};
VZ=function(a,b){if(null!=a&&null!=a.rg)a=a.rg(a,b);else{var c=VZ[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=VZ._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Za("MetadataProvider.card",a);}return a};WZ=function(a,b){if(null!=a&&null!=a.ug)a=a.ug(a,b);else{var c=WZ[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=WZ._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Za("MetadataProvider.fields",a);}return a};
$CLJS.XZ=function(a){return $CLJS.qY(a)?a:$CLJS.fD.h(a)};$CLJS.YZ=function(a,b){return UZ($CLJS.XZ(a),b)};$CLJS.ZZ=function(a,b){return WZ($CLJS.XZ(a),b)};$CLJS.$Z=function(a,b){return VZ($CLJS.XZ(a),b)};
$CLJS.a_=function(a,b,c,d){if($CLJS.n($CLJS.n(d)?d:$CLJS.Ua(b)||$CLJS.sd(b))&&($CLJS.n(b.__mbcache)||(b.__mbcache=$CLJS.Se($CLJS.N)),d=b.__mbcache,$CLJS.n(d))){var e=$CLJS.J.g($CLJS.q(d),a);if($CLJS.n(e))return e;b=c.h?c.h(b):c.call(null,b);$CLJS.zh.v(d,$CLJS.R,a,b);return b}return c.h?c.h(b):c.call(null,b)};$CLJS.b_=function(a,b,c){return $CLJS.a_(a,b,c,!1)};
r_=function(a,b,c){var d=$CLJS.c_.j(a,b,c);return $CLJS.sk.l($CLJS.H([$CLJS.wl(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,$CLJS.jD,$CLJS.pj],null)),function(){var e=$CLJS.OV.h(c);return $CLJS.n(e)?new $CLJS.h(null,2,[$CLJS.jD,e,d_,!0],null):null}(),function(){var e=$CLJS.e_.v(a,b,c,$CLJS.f_);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.g_,e],null):null}(),function(){var e=$CLJS.vz($CLJS.mi,$CLJS.pA)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.mi,e],null):null}(),function(){var e=$CLJS.VJ.h(d);return $CLJS.n(e)?
(e=$CLJS.Ad(e)?$CLJS.YZ(a,e):"string"===typeof e?$CLJS.$Z(a,$CLJS.EV(e)):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.MV,$CLJS.h_.j(a,b,e)],null):null):null}(),function(){var e=$CLJS.IJ.h(d);return $CLJS.n(e)?new $CLJS.h(null,6,[i_,$CLJS.E.g(e,$CLJS.sY),$CLJS.j_,$CLJS.E.g(e,$CLJS.EY),k_,$CLJS.E.g(e,$CLJS.BY),$CLJS.l_,$CLJS.E.g(e,$CLJS.DY),Voa,$CLJS.E.g(e,$CLJS.fK),Woa,$CLJS.E.g(e,$CLJS.XJ)],null):null}(),function(){var e=$CLJS.JY.h(d);return null==e?null:new $CLJS.h(null,1,[$CLJS.m_,e],null)}(),function(){var e=
$CLJS.vz($CLJS.HV,$CLJS.mF)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.n_,$CLJS.Cd($CLJS.kG,e)&&!$CLJS.Cd($CLJS.jG,e)],null):null}(),$CLJS.wl(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.o_,$CLJS.p_,$CLJS.q_],null))]))};$CLJS.s_=new $CLJS.M(null,"fk-join-alias","fk-join-alias",997510084);$CLJS.q_=new $CLJS.M(null,"filter-positions","filter-positions",378749375);$CLJS.t_=new $CLJS.M(null,"direction","direction",-633359395);$CLJS.l_=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);
$CLJS.o_=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);$CLJS.u_=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.m_=new $CLJS.M(null,"selected","selected",574897764);Xoa=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);Woa=new $CLJS.M(null,"is-breakout","is-breakout",-1878069983);
Yoa=new $CLJS.r("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.v_=new $CLJS.M("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);$CLJS.w_=new $CLJS.M("metabase.lib.metadata.calculation","source-join-alias","metabase.lib.metadata.calculation/source-join-alias",-1432523829);Voa=new $CLJS.M(null,"is-aggregation","is-aggregation",872502629);
y_=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.z_=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);i_=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);A_=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);d_=new $CLJS.M(null,"named?","named?",-213401078);$CLJS.B_=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);
$CLJS.j_=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.n_=new $CLJS.M(null,"is-temporal-extraction","is-temporal-extraction",-1029608872);$CLJS.C_=new $CLJS.M(null,"requires-column","requires-column",934105295);$CLJS.p_=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);Zoa=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.g_=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);
$CLJS.D_=new $CLJS.M(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.f_=new $CLJS.M(null,"long","long",-171452093);$CLJS.E_=new $CLJS.M(null,"short-name","short-name",-1767085022);$CLJS.F_=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);k_=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.G_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.eB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","display-name-method"),function(f,k,l){return $CLJS.VA(l)},e,a,b,c,d)}();
$CLJS.H_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.eB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","column-name-method"),function(f,k,l){return $CLJS.VA(l)},e,a,b,c,d)}();
$CLJS.e_=function(){function a(f,k,l,m){var t=$CLJS.vz($CLJS.jD,$CLJS.nD)($CLJS.CD(l));if($CLJS.n(t))return t;try{return $CLJS.G_.v(f,k,l,m)}catch(u){if(u instanceof Error)throw k=u,$CLJS.Th($CLJS.eD("Error calculating display name for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([l])),$CLJS.rU(k)])),new $CLJS.h(null,2,[$CLJS.CE,f,$CLJS.wD,l],null),k);throw u;}}function b(f,k,l){return e.v?e.v(f,k,l,$CLJS.Oh):e.call(null,f,k,l,$CLJS.Oh)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.I_=function(){function a(d,e,f){var k=$CLJS.U.h($CLJS.CD(f));if($CLJS.n(k))return k;try{return $CLJS.H_.j(d,e,f)}catch(l){if(l instanceof Error)throw k=l,$CLJS.Th($CLJS.eD("Error calculating column name for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([f])),$CLJS.rU(k)])),new $CLJS.h(null,3,[$CLJS.wD,f,$CLJS.CE,d,$CLJS.DK,e],null),k);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.G_.m(null,$CLJS.Oh,function(a,b,c){a=$CLJS.Iz($CLJS.Ax);$CLJS.n($CLJS.Hz("metabase.lib.metadata.calculation",a))&&$CLJS.Gz("metabase.lib.metadata.calculation",a,$CLJS.sV("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.H([$CLJS.xh.l($CLJS.H([c])),Yoa,$CLJS.VA(c)])),null);return $CLJS.ud(c)&&$CLJS.z(c)instanceof $CLJS.M?$CLJS.Xg($CLJS.z(c)):$CLJS.xh.l($CLJS.H([c]))});
$CLJS.H_.m(null,$CLJS.Oh,function(a,b,c){return Uoa($CLJS.Cz($CLJS.e_.j(a,b,c),/[\(\)]/,""))});$CLJS.J_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.eB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,k,l){return l},e,a,b,c,d)}();
$CLJS.$oa=function(){function a(d,e,f){return $CLJS.J_.j(d,e,$CLJS.mh.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.K_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.eB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","type-of-method"),function(f,k,l){return $CLJS.VA(l)},e,a,b,c,d)}();
$CLJS.L_=function(){function a(d,e,f){var k=$CLJS.CD(f);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.mF),m=$CLJS.mi.h(k);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Cd($CLJS.kG,l):l)?$CLJS.Xi:null;if($CLJS.n(l))return l;k=$CLJS.pA.h(k);if($CLJS.n(k))return k;d=$CLJS.K_.j(d,e,f);return $CLJS.zz(d,$CLJS.aj)?d:$CLJS.aj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.K_.m(null,$CLJS.Oh,function(a,b,c){return $CLJS.JD(c)});$CLJS.K_.m(null,$CLJS.ZD,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.L_.j(a,b,c)});$CLJS.K_.m(null,$CLJS.TH,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);return"string"===typeof d?$CLJS.JD(c):$CLJS.L_.j(a,b,d)});
$CLJS.M_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.eB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","metadata-method"),function(f,k,l){return $CLJS.VA(l)},e,a,b,c,d)}();
$CLJS.M_.m(null,$CLJS.Oh,function(a,b,c){try{return new $CLJS.h(null,4,[$CLJS.Rs,$CLJS.GJ,$CLJS.pA,$CLJS.L_.j(a,b,c),$CLJS.U,$CLJS.I_.j(a,b,c),$CLJS.jD,$CLJS.e_.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.Th($CLJS.eD("Error calculating metadata for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([$CLJS.VA(c)])),$CLJS.rU(d)])),new $CLJS.h(null,3,[$CLJS.CE,a,$CLJS.DK,b,$CLJS.wD,c],null),d);}throw e;}});
$CLJS.c_=function(){function a(e,f,k){return $CLJS.M_.j(e,f,k)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.N_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.eB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","display-info-method"),function(f,k,l){return $CLJS.VA(l)},e,a,b,c,d)}();
$CLJS.Y(y_,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.sj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.g_,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.sj],null),new $CLJS.P(null,3,5,$CLJS.Q,[d_,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.pr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MV,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,y_],null)],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[i_,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.pr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.j_,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.pr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[k_,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.pr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.l_,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.pr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[A_,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.pr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Zoa,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.pr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Xoa,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.pr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,
1,[$CLJS.wr,!0],null),$CLJS.sj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RE,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.sj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.sj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.E_,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.sj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.C_,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.pr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.m_,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.pr],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.pr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.t_,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xq,$CLJS.dF,$CLJS.XE],null)],null)],null));
$CLJS.h_=function(){function a(d,e,f){return $CLJS.b_($CLJS.mh.g("display-info",["stage-",$CLJS.p.h(e)].join("")),f,function(k){try{return $CLJS.N_.j(d,e,k)}catch(m){if(m instanceof Error){var l=m;throw $CLJS.Th($CLJS.eD("Error calculating display info for {0}: {1}",$CLJS.H([$CLJS.VA(k),$CLJS.rU(l)])),new $CLJS.h(null,3,[$CLJS.CE,d,$CLJS.DK,e,$CLJS.wD,k],null),l);}throw m;}})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.N_.m(null,$CLJS.Oh,function(a,b,c){return r_(a,b,c)});$CLJS.N_.m(null,$CLJS.OJ,function(a,b,c){return $CLJS.sk.l($CLJS.H([r_(a,b,c),new $CLJS.h(null,1,[A_,$CLJS.E.g($CLJS.TZ(a),$CLJS.Fi.h(c))],null)]))});
$CLJS.O_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.eB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,k,l){return $CLJS.VA(l)},e,a,b,c,d)}();$CLJS.O_.m(null,$CLJS.sA,function(){return $CLJS.xf});
$CLJS.P_=function(){function a(f,k,l,m){m=$CLJS.sk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.B_,$CLJS.GV()],null),m]));return $CLJS.O_.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.DV(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,
f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.Q_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.eB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,k,l){return $CLJS.VA(l)},e,a,b,c,d)}();$CLJS.Q_.m(null,$CLJS.sA,function(){return $CLJS.xf});
$CLJS.Q_.m(null,$CLJS.Oh,function(a,b,c,d){return $CLJS.O_.v(a,b,c,d)});
$CLJS.R_=function(){function a(f,k,l,m){m=$CLJS.sk.l($CLJS.H([$CLJS.sk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.B_,$CLJS.GV()],null),new $CLJS.h(null,4,[$CLJS.z_,!0,$CLJS.F_,!0,$CLJS.u_,!0,$CLJS.D_,!0],null)])),m]));return $CLJS.Q_.v(f,k,l,m)}function b(f,k,l){return $CLJS.n(function(){var m=$CLJS.sd(l);if(m){m=$CLJS.Rs.h(l);var t=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.$U,null,$CLJS.TU,null],null),null);return t.h?t.h(m):t.call(null,m)}return m}())?$CLJS.b_($CLJS.mh.h([$CLJS.p.h(k),"__visible-columns-no-opts"].join("")),
f,function(){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}):e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.DV(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
module.exports={display_name:$CLJS.e_};