var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var oG,pG,rG,sG,yha,zha,Aha,tG,qG;$CLJS.mG=function(a,b){return $CLJS.dc($CLJS.bb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.Yd.g($CLJS.J.j(c,e,$CLJS.xf),d))},$CLJS.ac($CLJS.N),b))};$CLJS.nG=function(a,b){return $CLJS.bb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Oc(d):null},null,b)};
oG=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.zz(b,$CLJS.yk)?$CLJS.YF:$CLJS.zz(b,$CLJS.Fk)?$CLJS.aD:$CLJS.zz(b,$CLJS.Ak)?$CLJS.bD:null;return $CLJS.n(b)?$CLJS.lE(b,a):!0};
pG=function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);a=$CLJS.mG(function(d){return $CLJS.zz($CLJS.JD(d),$CLJS.ZB)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Zj.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.JD($CLJS.z(a));return $CLJS.Me(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(oG(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
rG=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.h(null,1,[$CLJS.ps,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qG],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.As,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.cj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(pG(b))].join("")}],null),$CLJS.Oe(pG)],null)],null)};
sG=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.h(null,1,[$CLJS.ps,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mq,new $CLJS.h(null,1,[$CLJS.bn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)],null)],null)};
yha=function(a){return $CLJS.Md($CLJS.oE,$CLJS.cf.g(function(b){var c=$CLJS.JD(b),d=$CLJS.zz(c,$CLJS.VD);b=d?$CLJS.lE($CLJS.kF,b):d;return $CLJS.n(b)?$CLJS.lj:c},a))};zha=function(a){a=$CLJS.nG(function(b){return!$CLJS.zz(b,$CLJS.ZB)},$CLJS.cf.g($CLJS.JD,a));return $CLJS.zz(a,$CLJS.VD)?$CLJS.qj:a};
Aha=function(a,b){return $CLJS.n($CLJS.Me(function(c){return $CLJS.zz($CLJS.JD(c),$CLJS.ZB)},b))?zha(b):$CLJS.E.g(a,$CLJS.Vr)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Le(function(c){return $CLJS.zz($CLJS.JD(c),$CLJS.yk)},b)||$CLJS.Le(function(c){return $CLJS.zz($CLJS.JD(c),$CLJS.Ak)},b))?$CLJS.ZB:yha(b)};tG=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.uG=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);qG=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Eq,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,qG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.ps,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=$CLJS.JD(a);return $CLJS.Le(function(d){return oG(d,c)},b)}],null)],null));
$CLJS.Y(tG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mq,new $CLJS.h(null,1,[$CLJS.bn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)],null));
var Bha=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.h(null,1,[$CLJS.ps,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.Vr],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null)],
null);$CLJS.FF.g($CLJS.Eq,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,rG($CLJS.Eq),sG($CLJS.Eq)],null));$CLJS.FF.g($CLJS.Vr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.$q,rG($CLJS.Vr),Bha,sG($CLJS.Vr)],null));$CLJS.vE($CLJS.Fq,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gr,tG],null)]));$CLJS.vE($CLJS.AF,$CLJS.H([$CLJS.Vr,$CLJS.JC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gr,tG],null)]));
for(var vG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Eq,$CLJS.Vr,$CLJS.Fq],null)),wG=null,xG=0,yG=0;;)if(yG<xG){var Cha=wG.X(null,yG);$CLJS.mE(Cha,$CLJS.uG);yG+=1}else{var zG=$CLJS.y(vG);if(zG){var AG=zG;if($CLJS.vd(AG)){var BG=$CLJS.ic(AG),Dha=$CLJS.jc(AG),Eha=BG,Fha=$CLJS.D(BG);vG=Dha;wG=Eha;xG=Fha}else{var Gha=$CLJS.z(AG);$CLJS.mE(Gha,$CLJS.uG);vG=$CLJS.B(AG);wG=null;xG=0}yG=0}else break}
$CLJS.ID.m(null,$CLJS.uG,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);$CLJS.z(b);b=$CLJS.B(b);return Aha(a,b)});$CLJS.tE($CLJS.GE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));$CLJS.mE($CLJS.GE,$CLJS.ZD);
for(var CG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CF,$CLJS.xE,$CLJS.UE],null)),DG=null,EG=0,FG=0;;)if(FG<EG){var Hha=DG.X(null,FG);$CLJS.tE(Hha,$CLJS.H([$CLJS.Vr,$CLJS.JC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));FG+=1}else{var GG=$CLJS.y(CG);if(GG){var HG=GG;if($CLJS.vd(HG)){var IG=$CLJS.ic(HG),Iha=$CLJS.jc(HG),Jha=IG,Kha=$CLJS.D(IG);CG=Iha;DG=Jha;EG=Kha}else{var Lha=$CLJS.z(HG);$CLJS.tE(Lha,$CLJS.H([$CLJS.Vr,$CLJS.JC,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));CG=$CLJS.B(HG);DG=null;EG=0}FG=0}else break}
for(var JG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BE,$CLJS.eF,$CLJS.lF],null)),KG=null,LG=0,MG=0;;)if(MG<LG){var Mha=KG.X(null,MG);$CLJS.tE(Mha,$CLJS.H([$CLJS.Vr,$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));MG+=1}else{var NG=$CLJS.y(JG);if(NG){var OG=NG;if($CLJS.vd(OG)){var PG=$CLJS.ic(OG),Nha=$CLJS.jc(OG),Oha=PG,Pha=$CLJS.D(PG);JG=Nha;KG=Oha;LG=Pha}else{var Qha=$CLJS.z(OG);$CLJS.tE(Qha,$CLJS.H([$CLJS.Vr,$CLJS.Xi,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));JG=$CLJS.B(OG);KG=null;LG=0}MG=0}else break}$CLJS.tE($CLJS.DE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));
$CLJS.ID.m(null,$CLJS.DE,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.zz($CLJS.JD(b),$CLJS.Xi)&&$CLJS.zz($CLJS.JD(a),$CLJS.Xi)?$CLJS.Xi:$CLJS.JC});