var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var Xga,Yga,Zga,$ga,aha,bha,cha,KF,dha,eha,fha,gha,hha,iha,jha,kha,MF,lha;$CLJS.GF=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Xga=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.HF=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Yga=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Zga=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.IF=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$ga=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.JF=new $CLJS.M(null,"display-info","display-info",-816930907);aha=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);bha=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
cha=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);KF=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);dha=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);eha=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);fha=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);gha=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
hha=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);iha=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);jha=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.LF=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);kha=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);MF=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.NF=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);lha=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.vE($CLJS.Nw,$CLJS.H([$CLJS.Vr,$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null)],null)]));$CLJS.vE($CLJS.hF,$CLJS.H([$CLJS.Vr,$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null)],null)]));
$CLJS.tE($CLJS.aF,$CLJS.H([$CLJS.Vr,$CLJS.JC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));$CLJS.tE($CLJS.LE,$CLJS.H([$CLJS.Vr,$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));$CLJS.tE($CLJS.pF,$CLJS.H([$CLJS.Vr,$CLJS.Xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null)]));
$CLJS.tE($CLJS.yj,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)],null)]));$CLJS.mE($CLJS.yj,$CLJS.ZD);$CLJS.tE($CLJS.tF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));$CLJS.mE($CLJS.tF,$CLJS.ZD);$CLJS.tE($CLJS.bn,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)],null)]));$CLJS.mE($CLJS.bn,$CLJS.ZD);
$CLJS.Y(KF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.h(null,1,[$CLJS.ps,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.ps,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.tE($CLJS.SE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KF],null)]));$CLJS.mE($CLJS.SE,$CLJS.ZD);
$CLJS.tE($CLJS.nF,$CLJS.H([$CLJS.Vr,$CLJS.JC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null)]));$CLJS.tE($CLJS.uF,$CLJS.H([$CLJS.Vr,$CLJS.JC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));$CLJS.tE($CLJS.sF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));
$CLJS.tE($CLJS.BF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));$CLJS.mE($CLJS.sF,$CLJS.ZD);$CLJS.mE($CLJS.BF,$CLJS.ZD);$CLJS.tE($CLJS.zF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null)]));$CLJS.mE($CLJS.zF,$CLJS.ZD);
$CLJS.tE($CLJS.ME,$CLJS.H([$CLJS.Vr,$CLJS.JC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));$CLJS.Y(MF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.$q,kha,iha,bha,Yga,Xga,aha,cha,dha,eha,hha,fha,lha,jha,Zga,gha,$CLJS.Vk],null));$CLJS.Y($CLJS.HF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.bn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MF],null)],null));
$CLJS.OF=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.$E,$CLJS.Nw,$CLJS.IF,!1,$CLJS.LF,$CLJS.AE,$CLJS.JF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Count of rows"),$CLJS.RE,$CLJS.GD("Count"),$CLJS.Ds,$CLJS.GD("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.$E,$CLJS.sF,$CLJS.GF,$CLJS.vj,$CLJS.IF,!0,$CLJS.LF,$CLJS.AE,$CLJS.JF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Sum of ..."),$CLJS.RE,$CLJS.GD("Sum"),$CLJS.Ds,$CLJS.GD("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.$E,$CLJS.aF,$CLJS.GF,$CLJS.vj,$CLJS.IF,!0,$CLJS.LF,$CLJS.AE,$CLJS.JF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Average of ..."),$CLJS.RE,$CLJS.GD("Average"),$CLJS.Ds,$CLJS.GD("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.$E,$CLJS.tF,$CLJS.GF,$CLJS.vj,$CLJS.IF,!0,$CLJS.LF,$CLJS.cF,$CLJS.JF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Median of ..."),$CLJS.RE,$CLJS.GD("Median"),$CLJS.Ds,$CLJS.GD("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.$E,$CLJS.LE,$CLJS.GF,$CLJS.qr,$CLJS.IF,!0,$CLJS.LF,$CLJS.AE,$CLJS.JF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Number of distinct values of ..."),$CLJS.RE,$CLJS.GD("Distinct values"),$CLJS.Ds,$CLJS.GD("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.$E,$CLJS.BF,$CLJS.GF,$CLJS.vj,$CLJS.IF,!0,$CLJS.LF,$CLJS.AE,$CLJS.JF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Cumulative sum of ..."),
$CLJS.RE,$CLJS.GD("Sum"),$CLJS.Ds,$CLJS.GD("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.$E,$CLJS.hF,$CLJS.IF,!1,$CLJS.LF,$CLJS.AE,$CLJS.JF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Cumulative count of rows"),$CLJS.RE,$CLJS.GD("Count"),$CLJS.Ds,$CLJS.GD("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.$E,$CLJS.uF,$CLJS.GF,$CLJS.vj,$CLJS.IF,
!0,$CLJS.LF,$CLJS.qF,$CLJS.JF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Standard deviation of ..."),$CLJS.RE,$CLJS.GD("SD"),$CLJS.Ds,$CLJS.GD("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.$E,$CLJS.bn,$CLJS.GF,$CLJS.Ei,$CLJS.IF,!0,$CLJS.LF,$CLJS.AE,$CLJS.JF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Minimum of ..."),$CLJS.RE,$CLJS.GD("Min"),$CLJS.Ds,$CLJS.GD("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.$E,$CLJS.yj,$CLJS.GF,$CLJS.Ei,$CLJS.IF,!0,$CLJS.LF,$CLJS.AE,$CLJS.JF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Maximum of ..."),$CLJS.RE,$CLJS.GD("Max"),$CLJS.Ds,$CLJS.GD("Maximum value of a column")],null)}],null)],null);
$CLJS.Y($ga,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.NF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$E,$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Xq],null),$CLJS.cf.h($CLJS.$E),$CLJS.OF)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GF,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.Di],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IF,$CLJS.pr],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.LF,$CLJS.Di],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JF,$CLJS.id],null)],null));