var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var $qa,ara,bra,cra,c3,d3,e3,f3,dra,era,fra,gra,hra,ira,jra,kra,lra,p3,q3,mra,nra,ora,o3,n3,pra,r3;$CLJS.X2=function(a,b){$CLJS.bb(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
$qa=function(a){return function(b){var c=$CLJS.Ve(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.Yk(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};ara=function(a,b){return function f(d,e){return new $CLJS.ie(null,function(){var k;a:{var l=d;for(k=e;;){var m=l;l=$CLJS.I(m,0,null);if(m=$CLJS.y(m)){var t=a.h?a.h(l):a.call(null,l);if($CLJS.Cd(k,t))l=$CLJS.Hc(m);else{k=$CLJS.ae(l,f($CLJS.Hc(m),$CLJS.Yd.g(k,t)));break a}}else{k=null;break a}}}return k},null,null)}(b,$CLJS.Sg)};
bra=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.w(b.slice(3),0,null):null;return $CLJS.Sa($CLJS.y($CLJS.xz(c,d)))?c:$CLJS.Ne.M($CLJS.tW,c,d,e,b)};$CLJS.Y2=function(a){return(0,$CLJS.ma)($CLJS.Cz(a,$CLJS.Yma,""))};$CLJS.Z2=function(a,b){return $CLJS.m0.g(a,b instanceof $CLJS.M?b:$CLJS.xk.h(b))};
$CLJS.$2=function(a,b,c,d){if($CLJS.y(c)){d=$CLJS.ck.g($CLJS.oV,d);var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h(function(f){return $CLJS.N1.v(a,b,f,c)}),d);return $CLJS.ck.g(function(f){return $CLJS.R.j(f,$CLJS.JY,$CLJS.Cd(e,f))},c)}return null};$CLJS.a3=function(a){return $CLJS.E.g($CLJS.VA(a),$CLJS.hK)};cra=function(a,b){return $CLJS.n($CLJS.OD.h(a))?$CLJS.dk.j(a,$CLJS.OD,function(c){return $CLJS.rd(c)?$CLJS.ck.g(function(d){return $CLJS.b3.g?$CLJS.b3.g(d,b):$CLJS.b3.call(null,d,b)},c):c}):a};
c3=function(a){if($CLJS.n(a)){var b=$CLJS.ff;a:try{if($CLJS.ud(a)&&4===$CLJS.D(a))try{if($CLJS.F(a,0)instanceof $CLJS.M)try{var c=$CLJS.F(a,2);if($CLJS.ud(c)&&3===$CLJS.D(c))try{var d=$CLJS.F(c,0);if($CLJS.ce(d,$CLJS.gF))try{var e=$CLJS.F(a,3);if($CLJS.ud(e)&&3===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.ce(f,$CLJS.gF)){$CLJS.F(e,1);$CLJS.F(e,2);$CLJS.F(c,1);$CLJS.F(c,2);$CLJS.F(a,0);$CLJS.F(a,1);var k=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=
m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Z){k=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.z($CLJS.y(b($CLJS.Ta,k)))}else a=null;return a};d3=function(a){if($CLJS.n(c3(a))){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);$CLJS.I(a,3,null);return b}return null};e3=function(a){return $CLJS.n(c3(a))?($CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),$CLJS.I(a,3,null)):null};
f3=function(a,b){var c=$CLJS.b3;if($CLJS.Sa(c3(a)))return a;var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null),f=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.Ne.j(c,a,b)],null)};
dra=function(a,b,c){return $CLJS.od($CLJS.pK.h(a))?a:$CLJS.n(b)?bra(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.pK],null),function(d){return function l(f,k){try{if($CLJS.ud(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ce(m,$CLJS.gF))try{var t=$CLJS.F(k,1);if(null!=t?t.C&256||$CLJS.yc===t.mf||(t.C?0:$CLJS.Xa($CLJS.ub,t)):$CLJS.Xa($CLJS.ub,t))try{var u=$CLJS.J.j(t,$CLJS.sO,$CLJS.BU);if($CLJS.E.g(u,b))return $CLJS.F(k,2),$CLJS.b3.g?$CLJS.b3.g(k,c):$CLJS.b3.call(null,k,c);throw $CLJS.Z;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)return $CLJS.WV(l,f,k);throw v;}throw x;}}($CLJS.xf,d)}):$CLJS.dk.j(a,$CLJS.pK,function(d){return $CLJS.ck.g(function(e){return f3(e,$CLJS.H([c]))},d)})};
$CLJS.b3=function(a,b){var c=$CLJS.VA(a);switch(c instanceof $CLJS.M?c.S:null){case "field":return $CLJS.DD(a,$CLJS.BD,$CLJS.H([$CLJS.sO,b]));case "metadata/column":return $CLJS.BD(a,$CLJS.$Y,b);case "mbql/join":return c=$CLJS.b1(a),dra(cra($CLJS.BD(a,$CLJS.SD,b),b),c,b);default:return b=$CLJS.Iz($CLJS.yx),$CLJS.n($CLJS.Hz("metabase.lib.join",b))&&$CLJS.Gz("metabase.lib.join",b,$CLJS.tv.l($CLJS.H(["with-join-value should not be called with",$CLJS.xh.l($CLJS.H([a]))])),null),a}};
$CLJS.g3=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.vz($CLJS.rY,$CLJS.U)(c);a=$CLJS.c1($CLJS.SD.h(a),$CLJS.vz($CLJS.rY,$CLJS.U)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.rY,e,$CLJS.H([$CLJS.HY,b]))};era=function(a,b){return $CLJS.Sa(b)?a:$CLJS.ck.g(function(c){var d=e3(c);d=$CLJS.n(d)?$CLJS.n($CLJS.b1(d))?null:f3(c,$CLJS.H([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.h3=function(a,b){b=era($CLJS.ck.g($CLJS.pV,b),$CLJS.b1(a));return $CLJS.BD(a,$CLJS.pK,$CLJS.Be(b))};
$CLJS.i3=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.E.g(b,$CLJS.xf)?$CLJS.Zy:$CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.ak.g($CLJS.cf.h($CLJS.oV),function(){var c=$CLJS.b1(a);return $CLJS.n(c)?$CLJS.cf.h(function(d){return $CLJS.b3(d,c)}):$CLJS.Pd}()),b));return $CLJS.BD(a,$CLJS.OD,b)};
fra=function(a,b){b=$CLJS.ul(function(d){return $CLJS.N1.g(d,a)},b);var c=$CLJS.nG($CLJS.vz($CLJS.EZ,$CLJS.FZ),b);if($CLJS.n(c))return c;b=$CLJS.z(b);if($CLJS.n(b))return b;b=$CLJS.nG($CLJS.EZ,a);if($CLJS.n(b))return b;b=$CLJS.nG($CLJS.FZ,a);return $CLJS.n(b)?b:$CLJS.z(a)};gra=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.Cz(a,/ id$/i,"")):null};
hra=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.uh(function(){return $CLJS.h2(b)});var d=new $CLJS.uh(function(){return $CLJS.h2(a)});return $CLJS.E.g(a,b)||$CLJS.E.g(a,$CLJS.q(c))||$CLJS.E.g($CLJS.q(d),b)||$CLJS.E.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
ira=function(a,b,c){var d=$CLJS.e_.g($CLJS.E.g($CLJS.Rs.h(b),$CLJS.fV)?b:a,b),e=$CLJS.n(c)?gra($CLJS.e_.g(a,c)):null,f=hra(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Sa($CLJS.dh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
jra=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.A1(l,$CLJS.gF)&&$CLJS.zd($CLJS.N1.v(a,b,l,e)))return $CLJS.b3(l,d);throw $CLJS.Z;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.Z)return $CLJS.WV(m,k,l);throw t;}throw u;}}($CLJS.xf,c)};
kra=function(a,b,c,d,e,f){c=jra(a,b,c,d,f);return function t(l,m){try{if($CLJS.ud(m)&&4===$CLJS.D(m))try{var u=$CLJS.F(m,2);if($CLJS.A1(u,$CLJS.gF))try{var v=$CLJS.F(m,3);if($CLJS.A1(v,$CLJS.gF)){var x=$CLJS.F(m,3),A=$CLJS.F(m,2),C=$CLJS.F(m,0),G=$CLJS.F(m,1),K=$CLJS.b1(A),S=$CLJS.b1(x);if($CLJS.Sa($CLJS.n(K)?K:S))return $CLJS.n($CLJS.N1.v(a,b,x,e))?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,$CLJS.b3(A,d),x],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,$CLJS.b3(x,d)],null);if($CLJS.E.g(K,d)&&$CLJS.E.g(S,d)){var X=
$CLJS.DD(A,$CLJS.Tj,$CLJS.H([$CLJS.sO])),T=$CLJS.DD(x,$CLJS.Tj,$CLJS.H([$CLJS.sO])),da=null==$CLJS.N1.v(a,b,X,e);var oa=da?$CLJS.N1.v(a,b,T,e):da;return $CLJS.n(oa)?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,T],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,X,x],null)}return m}throw $CLJS.Z;}catch(pb){if(pb instanceof Error){var Oa=pb;if(Oa===$CLJS.Z)throw $CLJS.Z;throw Oa;}throw pb;}else throw $CLJS.Z;}catch(pb){if(pb instanceof Error){Oa=pb;if(Oa===$CLJS.Z)throw $CLJS.Z;throw Oa;}throw pb;}else throw $CLJS.Z;
}catch(pb){if(pb instanceof Error){Oa=pb;if(Oa===$CLJS.Z)return $CLJS.WV(t,l,m);throw Oa;}throw pb;}}($CLJS.xf,c)};lra=function(a,b){var c=$CLJS.GV();$CLJS.X2(c,b);return c(a)};
$CLJS.j3=function(a,b,c){if($CLJS.Cd(c,$CLJS.SD))return c;var d=$CLJS.DV(a,b),e=$CLJS.R_.j(a,b,d),f=$CLJS.y($CLJS.ff($CLJS.Ta,function v(t,u){try{if($CLJS.ud(u)&&1<=$CLJS.D(u))try{var x=$CLJS.hk.j(u,0,1);if($CLJS.ud(x)&&1===$CLJS.D(x))try{var A=$CLJS.F(x,0);if($CLJS.ce(A,$CLJS.gF))return new $CLJS.P(null,1,5,$CLJS.Q,[u],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var C=G;if(C===$CLJS.Z)throw $CLJS.Z;throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)throw $CLJS.Z;
throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)return $CLJS.yU(v,t,u);throw C;}throw G;}}($CLJS.xf,$CLJS.pK.h(c))));f=fra(e,f);var k=lra(ira(a,c,f),$CLJS.ul($CLJS.SD,$CLJS.CM.h(d))),l=$CLJS.P_.h($CLJS.W0.g(a,$CLJS.mK.h(c)));return $CLJS.b3($CLJS.dk.j(c,$CLJS.pK,function(t){return $CLJS.ck.g(function(u){return kra(a,b,u,k,e,l)},t)}),k)};$CLJS.k3=function(a){return $CLJS.pK.h(a)};
$CLJS.l3=function(a){return $CLJS.sk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Rs,$CLJS.qK,$CLJS.zD,a],null),$CLJS.E.g(a,$CLJS.XD)?new $CLJS.h(null,1,[$CLJS.Oh,!0],null):null]))};$CLJS.m3=function(a,b){b=$CLJS.z($CLJS.mK.h(b));return $CLJS.n($CLJS.MU.h(b))?$CLJS.$Z(a,$CLJS.MU.h(b)):$CLJS.n($CLJS.dO.h(b))?$CLJS.YZ(a,$CLJS.dO.h(b)):null};
p3=function(a){a=$CLJS.mG(function(d){return $CLJS.FZ(d)?n3:$CLJS.EZ(d)?o3:$CLJS.Ns},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,n3);var c=$CLJS.J.g(b,o3);b=$CLJS.J.g(b,$CLJS.Ns);return $CLJS.bf.l(a,c,$CLJS.H([b]))};q3=function(a,b,c,d){return $CLJS.Sa(c)?d:$CLJS.ck.g(function(e){return $CLJS.n($CLJS.JY.h(e))?$CLJS.Z2(e,$CLJS.f2(c)):e},$CLJS.$2(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null)))};
mra=function(a,b,c,d){var e=new $CLJS.uh(function(){return $CLJS.R_.v(a,b,d,new $CLJS.h(null,2,[$CLJS.u_,!1,$CLJS.D_,!1],null))});return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.ol(function(f){var k=$CLJS.O(f),l=$CLJS.J.g(k,$CLJS.IY);return $CLJS.n(function(){var m=$CLJS.EZ(k);return m?l:m}())?(f=$CLJS.nG(function(m){return $CLJS.E.g(l,$CLJS.Fi.h(m))},$CLJS.q(e)),$CLJS.n(f)?$CLJS.R.j(k,r3,f):null):null}),$CLJS.R_.j(a,b,c)))};nra={};
ora=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);o3=new $CLJS.M(null,"fk","fk",398442651);n3=new $CLJS.M(null,"pk","pk",-771936732);pra=new $CLJS.r("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);r3=new $CLJS.M("metabase.lib.join","target","metabase.lib.join/target",203858838);$CLJS.G_.m(null,$CLJS.hK,function(a,b,c){b=$CLJS.O(c);b=$CLJS.J.g(b,$CLJS.mK);b=$CLJS.I(b,0,null);b=$CLJS.O(b);c=$CLJS.J.g(b,$CLJS.dO);b=$CLJS.J.g(b,$CLJS.MU);c=$CLJS.n(c)?$CLJS.jD.h($CLJS.YZ(a,c)):null;if($CLJS.n(c))return c;$CLJS.n(b)?(c=$CLJS.$Z(a,b),a=$CLJS.n(c)?$CLJS.e_.j(a,0,c):$CLJS.g1(b)):a=null;return $CLJS.n(a)?a:$CLJS.GD("Native Query")});$CLJS.N_.m(null,$CLJS.hK,function(a,b,c){a=$CLJS.e_.j(a,b,c);c=$CLJS.SD.h(c);return new $CLJS.h(null,2,[$CLJS.U,$CLJS.n(c)?c:a,$CLJS.jD,a],null)});
$CLJS.M_.m(null,$CLJS.hK,function(){throw $CLJS.Uh("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.G_.m(null,$CLJS.qK,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.zD);a=a instanceof $CLJS.M?a.S:null;switch(a){case "left-join":return $CLJS.GD("Left outer join");case "right-join":return $CLJS.GD("Right outer join");case "inner-join":return $CLJS.GD("Inner join");case "full-join":return $CLJS.GD("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.N_.m(null,$CLJS.qK,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.zD);c=$CLJS.J.g(d,$CLJS.Oh);a=new $CLJS.h(null,2,[$CLJS.E_,$CLJS.Wz(e),$CLJS.jD,$CLJS.e_.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.Oh,!0):a});
$CLJS.O_.m(null,$CLJS.hK,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.J.g(e,$CLJS.SD),k=$CLJS.J.j(e,$CLJS.OD,$CLJS.Zy),l=$CLJS.J.g(e,$CLJS.mK);c=$CLJS.O(d);var m=$CLJS.J.g(c,$CLJS.B_);if($CLJS.E.g(k,$CLJS.Zy))return null;var t="undefined"!==typeof $CLJS.eL&&"undefined"!==typeof nra&&"undefined"!==typeof $CLJS.qra&&"undefined"!==typeof $CLJS.s3?new $CLJS.Cc(function(){return $CLJS.s3},$CLJS.kd(pra,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.mK,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.E.g(k,$CLJS.sx)?$CLJS.P_.v(u,-1,$CLJS.md(l),c):function(){return function A(x){return new $CLJS.ie(null,function(){for(;;){var C=$CLJS.y(x);if(C){if($CLJS.vd(C)){var G=$CLJS.ic(C),K=$CLJS.D(G),S=$CLJS.le(K);a:for(var X=0;;)if(X<K){var T=$CLJS.hd(G,X);T=$CLJS.DD(T,$CLJS.Tj,$CLJS.H([$CLJS.sO]));T=$CLJS.c_.j(u,-1,T);S.add(T);X+=1}else{G=!0;break a}return G?$CLJS.oe($CLJS.qe(S),A($CLJS.jc(C))):$CLJS.oe($CLJS.qe(S),null)}S=$CLJS.z(C);S=$CLJS.DD(S,$CLJS.Tj,$CLJS.H([$CLJS.sO]));
return $CLJS.ae($CLJS.c_.j(u,-1,S),A($CLJS.Hc(C)))}return null}},null,null)}(k)}();return $CLJS.ck.g(function(v){v=$CLJS.R.j(v,$CLJS.WY,f);v=$CLJS.b3($CLJS.R.l(v,$CLJS.jD,$CLJS.e_.j(a,b,v),$CLJS.H([$CLJS.IJ,$CLJS.EY])),f);return $CLJS.g3(e,m,v)},c)});$CLJS.Q_.m(null,$CLJS.hK,function(a,b,c,d){return $CLJS.P_.v(a,b,$CLJS.R.j(c,$CLJS.OD,$CLJS.sx),d)});
var t3,rra=$CLJS.Se($CLJS.N),sra=$CLJS.Se($CLJS.N),tra=$CLJS.Se($CLJS.N),ura=$CLJS.Se($CLJS.N),vra=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.eB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));t3=new $CLJS.Ph($CLJS.qh.g("metabase.lib.join","join-clause-method"),$CLJS.VA,vra,rra,sra,tra,ura);t3.m(null,$CLJS.hK,function(a){return a});t3.m(null,$CLJS.fV,function(a){return $CLJS.nV(new $CLJS.h(null,2,[$CLJS.Rs,$CLJS.hK,$CLJS.mK,$CLJS.mK.h($CLJS.BV(a))],null))});
t3.m(null,$CLJS.TU,function(a){return $CLJS.nV(new $CLJS.h(null,2,[$CLJS.Rs,$CLJS.hK,$CLJS.mK,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});t3.m(null,$CLJS.dK,function(a){return $CLJS.nV(new $CLJS.h(null,2,[$CLJS.Rs,$CLJS.hK,$CLJS.mK,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.MU,$CLJS.Fi.h(a),$CLJS.Rs,$CLJS.TU],null)],null)],null))});
t3.m(null,$CLJS.OJ,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.$Y);a=$CLJS.J.g(b,ora);b=t3.h(new $CLJS.h(null,3,[$CLJS.Rs,$CLJS.TU,$CLJS.qA,new $CLJS.h(null,1,[$CLJS.vD,$CLJS.p.h($CLJS.FD())],null),$CLJS.dO,$CLJS.Fi.h(b)],null));c=$CLJS.n(c)?$CLJS.b3(b,c):b;return $CLJS.n(a)?$CLJS.i3.g?$CLJS.i3.g(c,a):$CLJS.i3.call(null,c,a):c});
$CLJS.u3=function(){function a(d,e){return $CLJS.h3(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.y1(t3.h(d),$CLJS.OD,$CLJS.sx)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.wra=function(){function a(d,e,f){var k=$CLJS.u3.h(f);f=$CLJS.od($CLJS.k3.h?$CLJS.k3.h(k):$CLJS.k3.call(null,k))?function(){var m=$CLJS.m3.g?$CLJS.m3.g(d,k):$CLJS.m3.call(null,d,k);return $CLJS.v3.j?$CLJS.v3.j(d,e,m):$CLJS.v3.call(null,d,e,m)}():null;f=$CLJS.y(f)?$CLJS.h3(k,f):k;var l=$CLJS.j3(d,e,f);return $CLJS.PV.l(d,e,$CLJS.dk,$CLJS.H([$CLJS.CM,function(m){return $CLJS.Yd.g($CLJS.yf(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.w3=function(){function a(d,e){return $CLJS.Be($CLJS.J.g($CLJS.DV(d,e),$CLJS.CM))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.xra=function(){function a(d){d=$CLJS.S0(d);d=$CLJS.$y.h(d);return $CLJS.Wf.j($CLJS.xf,$CLJS.ak.g($CLJS.ef($CLJS.Qe($CLJS.Cd,d)),$CLJS.cf.h($CLJS.l3)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.XD,$CLJS.ND,$CLJS.aE,$CLJS.dE],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.yra=function(){function a(d,e,f,k){var l=$CLJS.a3(f)?$CLJS.b1(f):null,m=$CLJS.Wf.j($CLJS.Sg,$CLJS.ak.g($CLJS.cf.h($CLJS.b1),$qa(function(t){return $CLJS.Zj.g(t,l)})),$CLJS.w3.g(d,e));f=$CLJS.n(k)?k:$CLJS.a3(f)?d3($CLJS.z($CLJS.k3(f))):null;return p3(q3(d,e,f,$CLJS.bk.g(function(t){t=$CLJS.b1(t);return $CLJS.n(t)?$CLJS.Cd(m,t):null},$CLJS.R_.v(d,e,$CLJS.DV(d,e),new $CLJS.h(null,1,[$CLJS.u_,!1],null)))))}function b(d,e,f,k){return c.M?c.M(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.M=a;return c}();
$CLJS.zra=function(){function a(d,e,f,k,l){k=$CLJS.a3(f)?$CLJS.m3(d,f):f;var m=$CLJS.a3(f)?$CLJS.b1(f):null;f=$CLJS.n(l)?l:$CLJS.a3(f)?e3($CLJS.z($CLJS.k3(f))):null;f=$CLJS.n(f)?$CLJS.Sa(m)?$CLJS.DD(f,$CLJS.Tj,$CLJS.H([$CLJS.sO])):f:null;return p3(q3(d,e,f,$CLJS.cf.g(function(t){t=$CLJS.R.j(t,$CLJS.IJ,$CLJS.EY);return $CLJS.n(m)?$CLJS.b3(t,m):t},$CLJS.R_.v(d,e,k,new $CLJS.h(null,1,[$CLJS.u_,!1],null)))))}function b(d,e,f,k){return c.M?c.M(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.M=a;return c}();$CLJS.Ara=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.S1}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.S1};return b}();
$CLJS.v3=function(){function a(d,e,f){function k(u,v){u=$CLJS.Tj.g(u,$CLJS.i1);v=$CLJS.Tj.g(v,$CLJS.i1);return $CLJS.V2.l($CLJS.Q1.h($CLJS.Cq),u,$CLJS.H([v]))}function l(u,v){return $CLJS.Be(ara(function(x){return $CLJS.Fi.h(r3.h(x))},mra(d,e,u,v)))}var m=$CLJS.DV(d,e),t=function(){var u=l(m,f);return $CLJS.n(u)?$CLJS.ck.g(function(v){return k(v,r3.h(v))},u):null}();if($CLJS.n(t))return t;t=l(f,m);return $CLJS.n(t)?$CLJS.ck.g(function(u){return k(r3.h(u),u)},t):null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Bra=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.a3(k)?d3($CLJS.z($CLJS.k3(k))):null;$CLJS.n(l)?(l=$CLJS.h_.j(e,f,l),l=$CLJS.xz(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MV,$CLJS.jD],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.CV(e,f)){l=$CLJS.w3.g(e,f);var m=$CLJS.od(l);k=m?m:$CLJS.a3(k)?$CLJS.E.g($CLJS.SD.h(k),$CLJS.SD.h($CLJS.z(l))):null;k=$CLJS.n(k)?$CLJS.TZ(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.TZ(e),k=$CLJS.YZ(e,k),e=$CLJS.e_.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.GD("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.Cra=function(){function a(d,e,f,k){f=$CLJS.pV.h(f);$CLJS.I(f,0,null);$CLJS.I(f,1,null);var l=$CLJS.I(f,2,null),m=$CLJS.I(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.xk.h(k);e=$CLJS.CV(d,e);l=$CLJS.q0.j(d,e,l);d=$CLJS.q0.j(d,e,m);m=null==k||$CLJS.Cd($CLJS.Wg($CLJS.cf.g($CLJS.xk,l)),k);d=null==k||$CLJS.Cd($CLJS.Wg($CLJS.cf.g($CLJS.xk,d)),k);f=m?$CLJS.dk.v(f,2,$CLJS.Z2,k):f;return d?$CLJS.dk.v(f,3,$CLJS.Z2,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.J_.m(null,$CLJS.CM,function(a,b){var c=$CLJS.Be($CLJS.w3.g(a,b));c=null==c?null:$CLJS.cf.g(function(d){return $CLJS.e_.j(a,b,d)},c);return null==c?null:$CLJS.ds(" + ",c)});