var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Ata,Bta,t7,Cta,Dta,Eta,Fta,Gta,Hta,Ita,u7,v7,w7;
Ata=function(a){return function(b){var c=$CLJS.Ve($CLJS.Sg);return function(){function d(l,m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.Cd($CLJS.q(c),t))return l;c.nd(null,$CLJS.Yd.g(c.Lb(null),t));return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);
};k.o=f;k.h=e;k.g=d;return k}()}};Bta=function(a,b,c){return $CLJS.Wf.j($CLJS.xf,$CLJS.dD(function(d){return $CLJS.R_.v(a,b,d,new $CLJS.h(null,2,[$CLJS.B_,c,$CLJS.u_,!1],null))}),$CLJS.CM.h($CLJS.DV(a,b)))};t7=function(a,b,c){return $CLJS.Wf.j($CLJS.xf,$CLJS.dD(function(d){return $CLJS.P_.v(a,b,d,c)}),$CLJS.CM.h($CLJS.DV(a,b)))};$CLJS.s3=function(a,b){return $CLJS.bb(function(c,d){return $CLJS.PV.l(c,d,$CLJS.R,$CLJS.H([$CLJS.X6,$CLJS.P_.j(c,d,$CLJS.DV(c,d))]))},a,$CLJS.mt(0,$CLJS.CV(a,b)))};
Cta=function(a,b){a=$CLJS.DV(a,b);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.Rs),d=$CLJS.J.g(a,$CLJS.MU);b=$CLJS.X6.h(a);if($CLJS.n(b))return b;var e=$CLJS.mU.h(a);if($CLJS.n(e)&&$CLJS.n(function(){var k=$CLJS.E.g(c,$CLJS.$U);return k?k:d}())){var f=function(){var k=c instanceof $CLJS.M?c.S:null;switch(k){case "mbql.stage/native":return $CLJS.SY;case "mbql.stage/mbql":return $CLJS.LY;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}();return $CLJS.Be(function(){return function m(l){return new $CLJS.ie(null,
function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.vd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A);C=$CLJS.sk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.rY,$CLJS.U.h(C),$CLJS.HY,$CLJS.U.h(C)],null),C,new $CLJS.h(null,1,[$CLJS.IJ,f],null)]));x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),m($CLJS.jc(t))):$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);return $CLJS.ae($CLJS.sk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.rY,$CLJS.U.h(x),$CLJS.HY,$CLJS.U.h(x)],
null),x,new $CLJS.h(null,1,[$CLJS.IJ,f],null)])),m($CLJS.Hc(t)))}return null}},null,null)}($CLJS.AV.h(e))}())}return null};
Dta=function(a,b,c){return $CLJS.Be(function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v),A=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.vz($CLJS.rY,$CLJS.U)(x);x=$CLJS.d1(a,x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.IJ,$CLJS.XJ,$CLJS.H([$CLJS.rY,S,$CLJS.HY,x]));A.add(C);v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k);return $CLJS.ae($CLJS.R.l(u,$CLJS.IJ,$CLJS.XJ,$CLJS.H([$CLJS.rY,$CLJS.vz($CLJS.rY,$CLJS.U)(u),$CLJS.HY,function(){var v=$CLJS.d1(a,u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Hc(k)))}return null}},null,null)}($CLJS.A5.g(a,b))}())};
Eta=function(a,b,c){return $CLJS.Be(function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v),A=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.U.h(x);x=$CLJS.U.h(x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.IJ,$CLJS.fK,$CLJS.H([$CLJS.rY,S,$CLJS.HY,x]));A.add(C);v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}var u=
$CLJS.z(k);return $CLJS.ae($CLJS.R.l(u,$CLJS.IJ,$CLJS.fK,$CLJS.H([$CLJS.rY,$CLJS.U.h(u),$CLJS.HY,function(){var v=$CLJS.U.h(u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Hc(k)))}return null}},null,null)}($CLJS.c4.g(a,b))}())};
Fta=function(a,b,c){var d=$CLJS.DV(a,b);if($CLJS.n(d)){d=$CLJS.O(d);var e=$CLJS.J.g(d,$CLJS.OD);return $CLJS.Be(function(){return function l(k){return new $CLJS.ie(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.vd(m)){var t=$CLJS.ic(m),u=$CLJS.D(t),v=$CLJS.le(u);return function(){for(var K=0;;)if(K<u){var S=$CLJS.hd(t,K),X=$CLJS.I(S,0,null),T=function(){var oa=X;oa=oa instanceof $CLJS.M?oa.S:null;switch(oa){case "field":return $CLJS.QY;case "expression":return $CLJS.BY;default:throw Error(["No matching clause: ",
$CLJS.p.h(oa)].join(""));}}(),da=$CLJS.c_.j(a,b,S);$CLJS.pe(v,$CLJS.R.l(da,$CLJS.IJ,T,$CLJS.H([$CLJS.rY,$CLJS.I_.j(a,b,da),$CLJS.HY,function(){var oa=$CLJS.d1(a,da);return c.h?c.h(oa):c.call(null,oa)}()])));K+=1}else return!0}()?$CLJS.oe($CLJS.qe(v),l($CLJS.jc(m))):$CLJS.oe($CLJS.qe(v),null)}var x=$CLJS.z(m),A=$CLJS.I(x,0,null),C=function(){var K=A;K=K instanceof $CLJS.M?K.S:null;switch(K){case "field":return $CLJS.QY;case "expression":return $CLJS.BY;default:throw Error(["No matching clause: ",$CLJS.p.h(K)].join(""));
}}(),G=$CLJS.c_.j(a,b,x);return $CLJS.ae($CLJS.R.l(G,$CLJS.IJ,C,$CLJS.H([$CLJS.rY,$CLJS.I_.j(a,b,G),$CLJS.HY,function(){var K=$CLJS.d1(a,G);return c.h?c.h(K):c.call(null,K)}()])),l($CLJS.Hc(m)))}return null}},null,null)}(e)}())}return null};Gta=function(a,b,c){return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.dD(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.P(null,2,5,$CLJS.Q,[Dta,Eta],null)))};
Hta=function(a,b,c){var d=$CLJS.B1(a,b);return $CLJS.n(d)?$CLJS.Be(function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);return function(){for(var A=0;;)if(A<t){var C=$CLJS.hd(m,A);var G=$CLJS.vz($CLJS.HY,$CLJS.rY)(C);G=$CLJS.n(G)?G:$CLJS.I_.j(a,b,C);C=$CLJS.Tj.l($CLJS.sk.l($CLJS.H([C,new $CLJS.h(null,3,[$CLJS.IJ,$CLJS.sY,$CLJS.rY,G,$CLJS.HY,c.h?c.h(G):c.call(null,G)],null),$CLJS.n($CLJS.i1.h(C))?
$CLJS.wl(C,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.i1],null)):null])),$CLJS.HV,$CLJS.H([$CLJS.nD]));u.add(C);A+=1}else return!0}()?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}var v=$CLJS.z(l),x=function(){var A=$CLJS.vz($CLJS.HY,$CLJS.rY)(v);return $CLJS.n(A)?A:$CLJS.I_.j(a,b,v)}();return $CLJS.ae($CLJS.Tj.l($CLJS.sk.l($CLJS.H([v,new $CLJS.h(null,3,[$CLJS.IJ,$CLJS.sY,$CLJS.rY,x,$CLJS.HY,c.h?c.h(x):c.call(null,x)],null),$CLJS.n($CLJS.i1.h(v))?$CLJS.wl(v,new $CLJS.P(null,1,5,$CLJS.Q,
[$CLJS.i1],null)):null])),$CLJS.HV,$CLJS.H([$CLJS.nD])),k($CLJS.Hc(l)))}return null}},null,null)}($CLJS.P_.j(a,d,$CLJS.DV(a,d)))}()):null};Ita=function(a,b,c,d){return $CLJS.n(c)?(c=$CLJS.$Z(a,c),$CLJS.n(c)?$CLJS.Be($CLJS.R_.v(a,b,c,d)):null):null};
u7=function(a,b,c){return $CLJS.Be(function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v),A=t;var C=$CLJS.pA.h(x);var G=$CLJS.R.l,K=x,S=$CLJS.U.h(x);x=$CLJS.U.h(x);x=c.h?c.h(x):c.call(null,x);C=$CLJS.y1(G.call($CLJS.R,K,$CLJS.IJ,$CLJS.BY,$CLJS.H([$CLJS.rY,S,$CLJS.HY,x])),$CLJS.mi,$CLJS.n(C)?C:$CLJS.aj);A.add(C);v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),
f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}var u=$CLJS.z(k);return $CLJS.ae(function(){var v=$CLJS.pA.h(u),x=$CLJS.R.l,A=u,C=$CLJS.U.h(u);var G=$CLJS.U.h(u);G=c.h?c.h(G):c.call(null,G);return $CLJS.y1(x.call($CLJS.R,A,$CLJS.IJ,$CLJS.BY,$CLJS.H([$CLJS.rY,C,$CLJS.HY,G])),$CLJS.mi,$CLJS.n(v)?v:$CLJS.aj)}(),f($CLJS.Hc(k)))}return null}},null,null)}($CLJS.Ipa.g(a,b))}())};
v7=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.B_);return $CLJS.ck.g(function(f){return $CLJS.Tj.l(f,$CLJS.$Y,$CLJS.H([$CLJS.HV,$CLJS.NV,$CLJS.UJ]))},function(){var f=Hta(a,b,e);if($CLJS.n(f))return f;f=$CLJS.DV(a,b);f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.dO),l=$CLJS.J.g(f,$CLJS.MU),m=$CLJS.n(k)?function(){var t=$CLJS.YZ(a,k);return $CLJS.R_.v(a,b,t,d)}():null;if($CLJS.n(m))return m;l=$CLJS.n(l)?Ita(a,b,l,$CLJS.R.j(d,$CLJS.u_,!1)):null;return $CLJS.n(l)?l:function v(u){return new $CLJS.ie(null,
function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.vd(x)){var A=$CLJS.ic(x),C=$CLJS.D(A),G=$CLJS.le(C);return function(){for(var S=0;;)if(S<C){var X=$CLJS.hd(A,S),T=G,da=$CLJS.R,oa=da.l,Oa=X,pb=$CLJS.U.h(X);X=$CLJS.U.h(X);X=e.h?e.h(X):e.call(null,X);da=oa.call(da,Oa,$CLJS.IJ,$CLJS.SY,$CLJS.H([$CLJS.rY,pb,$CLJS.HY,X]));T.add(da);S+=1}else return!0}()?$CLJS.oe($CLJS.qe(G),v($CLJS.jc(x))):$CLJS.oe($CLJS.qe(G),null)}var K=$CLJS.z(x);return $CLJS.ae($CLJS.R.l(K,$CLJS.IJ,$CLJS.SY,$CLJS.H([$CLJS.rY,$CLJS.U.h(K),
$CLJS.HY,function(){var S=$CLJS.U.h(K);return e.h?e.h(S):e.call(null,S)}()])),v($CLJS.Hc(x)))}return null}},null,null)}($CLJS.AV.h($CLJS.mU.h(f)))}())};w7=new $CLJS.M("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.qra={};$CLJS.mE($CLJS.TU,w7);$CLJS.mE($CLJS.$U,w7);$CLJS.K0.m(null,$CLJS.TU,function(a){return $CLJS.J0(a,new $CLJS.h(null,2,[$CLJS.WE,$CLJS.Qe($CLJS.ck,$CLJS.K0),$CLJS.gV,$CLJS.Qe($CLJS.ck,$CLJS.K0)],null))});$CLJS.M_.m(null,w7,function(){throw $CLJS.Uh("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.Q_.m(null,w7,function(a,b,c,d){d=$CLJS.O(d);c=$CLJS.J.g(d,$CLJS.B_);var e=$CLJS.J.g(d,$CLJS.u_);a=$CLJS.s3(a,b);var f=$CLJS.O(d);var k=$CLJS.J.g(f,$CLJS.B_),l=$CLJS.J.g(f,$CLJS.z_),m=$CLJS.J.g(f,$CLJS.F_);f=$CLJS.bf.l(v7(a,b,f),$CLJS.n(m)?u7(a,b,k):null,$CLJS.H([$CLJS.n(l)?Bta(a,b,k):null]));k=$CLJS.bf.g;d=$CLJS.n(e)?(e=$CLJS.Sa($CLJS.MU.h($CLJS.DV(a,b))))?e:$CLJS.D_.h(d):e;return $CLJS.yf(k.call($CLJS.bf,f,$CLJS.n(d)?$CLJS.e1(a,b,f,c):null))});
$CLJS.O_.m(null,w7,function(a,b,c,d){c=$CLJS.O(d);d=$CLJS.J.g(c,$CLJS.B_);var e=Cta(a,b);if($CLJS.n(e))return e;a=$CLJS.s3(a,b);e=Gta(a,b,d);var f=Fta(a,b,d);return $CLJS.n(e)?$CLJS.Wf.g(e,f):$CLJS.n(f)?($CLJS.ch(f),$CLJS.Wf.j($CLJS.xf,Ata(function(k){return $CLJS.Tj.l(k,$CLJS.WY,$CLJS.H([$CLJS.IJ,$CLJS.w0,$CLJS.HY]))}),$CLJS.bf.g(f,t7(a,b,c)))):$CLJS.bf.l(v7(a,b,new $CLJS.h(null,2,[$CLJS.u_,!1,$CLJS.B_,d],null)),u7(a,b,d),$CLJS.H([t7(a,b,c)]))});$CLJS.G_.m(null,$CLJS.$U,function(){return $CLJS.GD("Native query")});
var x7=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dO,$CLJS.MU,$CLJS.CM],null),y7=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.WE,$CLJS.VE,$CLJS.gV,$CLJS.MQ,$CLJS.iR],null);
$CLJS.G_.m(null,$CLJS.TU,function(a,b,c,d){var e=$CLJS.s3(a,b);a=$CLJS.Be(function(){var f=$CLJS.Wf.j($CLJS.N,$CLJS.ak.g($CLJS.pB,$CLJS.cf.h(function(l){return new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.$oa.j(e,b,l)],null)})),new $CLJS.P(null,2,5,$CLJS.Q,[x7,y7],null)),k=$CLJS.ds(" + ",$CLJS.bk.g($CLJS.Dz,$CLJS.cf.g(f,x7)));f=$CLJS.cf.g(f,y7);return $CLJS.ds(", ",$CLJS.bk.g($CLJS.Dz,$CLJS.ae(k,f)))}());if($CLJS.n(a))return a;a=$CLJS.B1(e,b);return $CLJS.n(a)?$CLJS.e_.v(e,a,$CLJS.DV(e,a),d):null});