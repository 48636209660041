var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");
'use strict';$CLJS.a1=function(a,b){var c=$CLJS.EV(b);return $CLJS.n(c)?$CLJS.$Z(a,c):$CLJS.YZ(a,b)};$CLJS.b1=function(a){var b=$CLJS.VA(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.sO.h($CLJS.CD(a));case "metadata/column":return $CLJS.$Y.h(a);case "mbql/join":return $CLJS.SD.h(a);default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};
$CLJS.c1=function(a,b){return $CLJS.ha.j?$CLJS.ha.j("%s__%s",a,b):$CLJS.ha.call(null,"%s__%s",a,b)};$CLJS.d1=function(a,b){var c=$CLJS.b1(b);if($CLJS.n(c))a=c;else a:{var d=$CLJS.O(b);c=$CLJS.J.g(d,$CLJS.UJ);d=$CLJS.J.g(d,$CLJS.VJ);if($CLJS.n($CLJS.n(c)?d:c)&&(d=$CLJS.a1(a,d),$CLJS.n(d))){d=$CLJS.U.h(d);a=$CLJS.U.h($CLJS.T0(a,c));a=$CLJS.ha.j?$CLJS.ha.j("%s__via__%s",d,a):$CLJS.ha.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.n(a)?$CLJS.c1(a,$CLJS.U.h(b)):$CLJS.U.h(b)};
$CLJS.e1=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.VJ),c);return $CLJS.Wf.j($CLJS.xf,$CLJS.ak.l($CLJS.ef($CLJS.IY),$CLJS.ef($CLJS.Fi),$CLJS.ef($CLJS.ak.g($CLJS.Tk,$CLJS.Fi)),$CLJS.H([$CLJS.cf.h(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.Fi),l=$CLJS.J.g(f,$CLJS.IY);return $CLJS.R.l($CLJS.T0(a,l),$CLJS.v_,k,$CLJS.H([$CLJS.w_,$CLJS.$Y.h(f)]))}),$CLJS.bk.h(function(f){return $CLJS.Cd(e,$CLJS.VJ.h(f))}),$CLJS.dD(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.VJ),l=$CLJS.J.g(f,
$CLJS.v_),m=$CLJS.J.g(f,$CLJS.w_);f=$CLJS.YZ(a,k);return function v(u){return new $CLJS.ie(null,function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.vd(x)){var A=$CLJS.ic(x),C=$CLJS.D(A),G=$CLJS.le(C);return function(){for(var X=0;;)if(X<C){var T=$CLJS.hd(A,X),da=$CLJS.R.l(T,$CLJS.UJ,l,$CLJS.H([$CLJS.s_,m,$CLJS.IJ,$CLJS.DY,$CLJS.rY,$CLJS.U.h(T)]));T=G;var oa=$CLJS.R,Oa=oa.j,pb=da;da=$CLJS.d1(a,da);da=d.h?d.h(da):d.call(null,da);oa=Oa.call(oa,pb,$CLJS.HY,da);T.add(oa);X+=1}else return!0}()?$CLJS.oe($CLJS.qe(G),
v($CLJS.jc(x))):$CLJS.oe($CLJS.qe(G),null)}var K=$CLJS.z(x),S=$CLJS.R.l(K,$CLJS.UJ,l,$CLJS.H([$CLJS.s_,m,$CLJS.IJ,$CLJS.DY,$CLJS.rY,$CLJS.U.h(K)]));return $CLJS.ae($CLJS.R.j(S,$CLJS.HY,function(){var X=$CLJS.d1(a,S);return d.h?d.h(X):d.call(null,X)}()),v($CLJS.Hc(x)))}return null}},null,null)}($CLJS.Q_.v(a,b,f,new $CLJS.h(null,2,[$CLJS.B_,d,$CLJS.u_,!1],null)))})])),c)};$CLJS.f1=function(a,b){return $CLJS.X0.g(a,b)};$CLJS.g1=function(a){return $CLJS.eD("Question {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))]))};
$CLJS.h1=function(a,b){var c=$CLJS.OD.h(b);$CLJS.n(c)||(c=$CLJS.OY.h(b),$CLJS.n(c)||(c=$CLJS.NY.h(b),c=null!=c?$CLJS.P_.h($CLJS.f1(a,$CLJS.TX.h(c))):null));return $CLJS.n(c)?(c=$CLJS.Be($CLJS.sd(c)?$CLJS.AV.h(c):$CLJS.rd(c)?c:null),$CLJS.n(c)?$CLJS.ck.g($CLJS.Ts(Vpa,a,b),c):null):null};$CLJS.i1=new $CLJS.M("metabase.lib.card","force-broken-id-refs","metabase.lib.card/force-broken-id-refs",-868945458);$CLJS.j1=new $CLJS.M(null,"field-ref","field-ref",-1699303428);$CLJS.G_.m(null,$CLJS.dK,function(a,b,c){return $CLJS.vz($CLJS.jD,$CLJS.U)(c)});$CLJS.M_.m(null,$CLJS.dK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);c=$CLJS.J.g(a,$CLJS.jD);return $CLJS.Sa(c)?$CLJS.R.j(a,$CLJS.jD,$CLJS.$0.g($CLJS.uA,b)):a});
$CLJS.Q_.m(null,$CLJS.dK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.OD),f=$CLJS.J.g(c,$CLJS.OY);d=$CLJS.O(d);var k=$CLJS.J.g(d,$CLJS.u_),l=$CLJS.J.g(d,$CLJS.B_);return $CLJS.bf.g($CLJS.P_.v(a,b,c,d),$CLJS.n(k)?$CLJS.e1(a,b,$CLJS.bf.g(e,f),l):null)});$CLJS.J_.m(null,$CLJS.MU,function(a,b){var c=$CLJS.DV(a,b);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.MU);if($CLJS.n(c)){var d=$CLJS.$Z(a,c);a=$CLJS.n(d)?$CLJS.e_.v(a,b,d,$CLJS.f_):null;return $CLJS.n(a)?a:$CLJS.g1(c)}return null});
var Vpa=function(){function a(d,e,f){var k=$CLJS.Tj.g($CLJS.Az(f,$CLJS.aA),$CLJS.j1);f=$CLJS.sk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.pA,$CLJS.aj,$CLJS.Rs,$CLJS.GJ],null),function(){var l=$CLJS.Fi.h(k);if($CLJS.n(l))try{return $CLJS.T0(d,l)}catch(m){return null}else return null}(),k,new $CLJS.h(null,3,[$CLJS.Rs,$CLJS.GJ,$CLJS.IJ,$CLJS.LY,$CLJS.rY,$CLJS.vz($CLJS.rY,$CLJS.U)(k)],null)]));f=$CLJS.n(e)?$CLJS.R.j(f,$CLJS.VY,$CLJS.P0(e)):f;e=$CLJS.n($CLJS.n(!0)?$CLJS.Sa(e)||$CLJS.Sa($CLJS.zY.h($CLJS.$Z(d,
$CLJS.P0(e)))):!0)?$CLJS.R.j(f,$CLJS.i1,!0):f;return $CLJS.Zj.g($CLJS.pj.h(k),$CLJS.Xh)?$CLJS.R.j(e,$CLJS.IY,null):e}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.O_.m(null,$CLJS.dK,function(a,b,c,d){b=$CLJS.O(d);var e=$CLJS.J.g(b,$CLJS.B_);return $CLJS.ck.g(function(f){var k=$CLJS.oB($CLJS.HY,$CLJS.rY,$CLJS.U)(f);return $CLJS.R.j(f,$CLJS.HY,e.h?e.h(k):e.call(null,k))},$CLJS.h1(a,c))});