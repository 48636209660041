var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.drill_thru.automatic_insights.js");require("./metabase.lib.drill_thru.column_filter.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.drill_thru.distribution.js");require("./metabase.lib.drill_thru.fk_details.js");require("./metabase.lib.drill_thru.fk_filter.js");require("./metabase.lib.drill_thru.pk.js");require("./metabase.lib.drill_thru.zoom.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.util.malli.js");require("./metabase.lib.drill_thru.pivot.js");require("./metabase.lib.drill_thru.quick_filter.js");require("./metabase.lib.drill_thru.sort.js");require("./metabase.lib.drill_thru.summarize_column.js");require("./metabase.lib.drill_thru.summarize_column_by_time.js");require("./metabase.lib.drill_thru.underlying_records.js");require("./metabase.lib.drill_thru.zoom_in_bins.js");require("./metabase.lib.drill_thru.zoom_in_geographic.js");require("./metabase.lib.drill_thru.zoom_in_timeseries.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var R7,S7,T7,U7,V7,W7,Vta,X7,Wta,Xta,Y7,Z7,Yta,$7,a8,Zta,b8,$ta,aua,c8,d8,e8,f8,bua,g8,cua,dua,eua,fua,h8,gua,hua,i8,iua,jua,kua,lua,mua,j8,nua,oua,pua,qua,rua,sua,tua,k8,uua,vua,wua,xua,yua,zua,Aua,Bua,Cua,Dua,Eua,Fua,Gua,Hua,Iua,Jua,Kua,l8,m8,Lua,Mua,Nua,n8,Oua,Pua,Qua,Rua,o8,Sua,Tua,Uua,p8,Vua,Wua,Xua,Yua,Zua;$CLJS.Q7=function(a){return $CLJS.dc($CLJS.bb(function(b,c){return $CLJS.Zf.j(b,c,$CLJS.J.j(b,c,0)+1)},$CLJS.ac($CLJS.N),a))};
R7=function(a){var b=$CLJS.TZ(a);return $CLJS.n(b)?$CLJS.ff($CLJS.FZ,$CLJS.ZZ(a,b)):$CLJS.xf};
S7=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.yi),e=$CLJS.J.g(c,$CLJS.yK),f=$CLJS.J.g(c,$CLJS.GK),k=$CLJS.J.g(c,$CLJS.cj);return $CLJS.n(function(){var l=$CLJS.E5(a,b);return l?(l=$CLJS.Be(f),$CLJS.n(l)?(l=$CLJS.n(d)?null!=k:d,l=$CLJS.n(l)?l:null==d&&null==k,$CLJS.n(l)?$CLJS.Sa($CLJS.n(d)?$CLJS.GZ(d):d):l):l):l}())?new $CLJS.h(null,6,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.C6,$CLJS.w6,"number"!==typeof k||0>k?2:k,$CLJS.KY,function(){var l=null==a?null:$CLJS.TZ(a);l=null==l?null:$CLJS.YZ(a,l);
$CLJS.n(l)||(l=null==a?null:$CLJS.D1(a),l=null==l?null:$CLJS.$Z(a,l));return $CLJS.n(l)?$CLJS.e_.j(a,b,l):null}(),$CLJS.GK,f,$CLJS.yK,e],null):null};
T7=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.yi),e=$CLJS.J.g(c,$CLJS.yK),f=$CLJS.J.g(c,$CLJS.GK),k=$CLJS.J.g(c,$CLJS.cj);return $CLJS.n(function(){var l=$CLJS.E5(a,b);return l?(l=$CLJS.Sa(d)||null!=k)?(l=$CLJS.n7(a,$CLJS.zta),$CLJS.n(l)?$CLJS.Be(f):l):l:l}())?new $CLJS.h(null,4,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.g6,$CLJS.yK,e,$CLJS.GK,f],null):null};
U7=function(a,b,c,d){var e=$CLJS.a5(a,$CLJS.CV(a,b)),f=$CLJS.Zj.g($CLJS.IJ.h(c),$CLJS.fK)?new $CLJS.h(null,2,[$CLJS.CE,a,$CLJS.DK,b],null):$CLJS.n(e)?new $CLJS.h(null,2,[$CLJS.CE,a,$CLJS.DK,e],null):new $CLJS.h(null,2,[$CLJS.CE,$CLJS.z7(a),$CLJS.DK,-1],null),k=$CLJS.U2.g($CLJS.CE.h(f),$CLJS.DK.h(f));a=function(){var l=$CLJS.N1.v($CLJS.CE.h(f),$CLJS.DK.h(f),d,k);if($CLJS.n(l))return l;l=$CLJS.w0.h(c);return $CLJS.n(l)?$CLJS.nG(function(m){return $CLJS.E.g($CLJS.w0.h(m),$CLJS.w0.h(c))},k):l}();return $CLJS.R.j(f,
$CLJS.yi,a)};V7=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.yi);var e=$CLJS.J.g(d,$CLJS.yK);d=$CLJS.J.g(d,$CLJS.cj);var f=$CLJS.E5(a,b);d=f?$CLJS.n(c)?null==d&&!$CLJS.GZ(c):c:f;return $CLJS.n(d)?(d=$CLJS.yZ(c)?null:$CLJS.R.j($CLJS.z($CLJS.j2(c)),$CLJS.Rs,$CLJS.LI),$CLJS.sk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.a6,$CLJS.Z5,d],null),U7(a,b,c,e)]))):null};
W7=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.yi);d=$CLJS.J.g(d,$CLJS.cj);var e=$CLJS.E5(a,b);a=e?$CLJS.n(c)?null==d&&$CLJS.Zj.g($CLJS.IJ.h(c),$CLJS.fK)&&!$CLJS.FZ(c)&&!$CLJS.GZ(c)&&!$CLJS.QZ(c)&&!$CLJS.DZ(c)&&$CLJS.Sa($CLJS.y($CLJS.C5.j(a,b,c))):c:e;return $CLJS.n(a)?new $CLJS.h(null,3,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.A6,$CLJS.yi,c],null):null};
Vta=function(a,b,c){c=$CLJS.O(c);b=$CLJS.J.g(c,$CLJS.yi);c=$CLJS.J.g(c,$CLJS.cj);return $CLJS.EZ(b)&&null!=c&&$CLJS.Zj.g(c,$CLJS.LK)?new $CLJS.h(null,5,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.z6,$CLJS.yi,b,$CLJS.zK,c,$CLJS.vK,1<$CLJS.D(R7(a))],null):null};
X7=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.yi);var e=$CLJS.J.g(d,$CLJS.yK);d=$CLJS.J.g(d,$CLJS.cj);if($CLJS.n($CLJS.n(c)?null!=d&&$CLJS.Zj.g(d,$CLJS.LK)&&$CLJS.E5(a,b)&&!$CLJS.FZ(c)&&$CLJS.EZ(c):c)){var f=null==a?null:$CLJS.TZ(a);f=null==f?null:$CLJS.YZ(a,f);$CLJS.n(f)||(f=null==a?null:$CLJS.D1(a),f=null==f?null:$CLJS.$Z(a,f));return new $CLJS.h(null,5,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.e6,$CLJS.KE,$CLJS.nV(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cq,$CLJS.N,e,d],null)),$CLJS.RE,$CLJS.e_.v(a,
b,c,$CLJS.f_),$CLJS.KY,$CLJS.e_.j(a,0,f)],null)}return null};
Wta=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.yi),f=$CLJS.J.g(d,$CLJS.cj),k=$CLJS.J.g(d,$CLJS.wK);if(null!=f&&$CLJS.E5(a,b)&&1<$CLJS.D(R7(a))&&!$CLJS.EZ(e)){if($CLJS.FZ(e))return null!=f&&$CLJS.Zj.g(f,$CLJS.LK)?new $CLJS.h(null,3,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.o6,$CLJS.GK,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.yi,e,$CLJS.cj,f],null)],null)],null):null;var l=R7(a);a=function(){return function u(t){return new $CLJS.ie(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var A=
x;if($CLJS.vd(A)){var C=$CLJS.ic(A),G=$CLJS.D(C),K=$CLJS.le(G);return function(){for(var T=0;;)if(T<G){var da=$CLJS.hd(C,T),oa=$CLJS.cj.h($CLJS.nG(function(Oa,pb,Ra){return function(Wa){return $CLJS.E.g($CLJS.U.h($CLJS.yi.h(Wa)),$CLJS.U.h(Ra))}}(T,v,da,C,G,K,A,x,l,c,d,d,e,f,k),k));$CLJS.n(oa)&&$CLJS.pe(K,new $CLJS.h(null,2,[$CLJS.yi,da,$CLJS.cj,oa],null));T+=1}else return!0}()?$CLJS.oe($CLJS.qe(K),u($CLJS.jc(A))):$CLJS.oe($CLJS.qe(K),null)}var S=$CLJS.z(A),X=$CLJS.cj.h($CLJS.nG(function(T,da){return function(oa){return $CLJS.E.g($CLJS.U.h($CLJS.yi.h(oa)),
$CLJS.U.h(da))}}(v,S,A,x,l,c,d,d,e,f,k),k));if($CLJS.n(X))return $CLJS.ae(new $CLJS.h(null,2,[$CLJS.yi,S,$CLJS.cj,X],null),u($CLJS.Hc(A)));v=$CLJS.Hc(A)}else return null}},null,null)}(l)}();return $CLJS.y(a)?new $CLJS.h(null,3,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.o6,$CLJS.GK,$CLJS.yf($CLJS.Y0(function(m){return $CLJS.xz(m,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,$CLJS.Fi],null))},a))],null):null}return null};
Xta=function(a,b,c){var d=$CLJS.O(c);b=$CLJS.J.g(d,$CLJS.yi);c=$CLJS.J.g(d,$CLJS.cj);d=$CLJS.J.g(d,$CLJS.wK);if(null==c||1<$CLJS.D(R7(a)))return null;if($CLJS.FZ(b))return $CLJS.E.g(c,$CLJS.LK)?null:new $CLJS.h(null,5,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.R6,$CLJS.yi,b,$CLJS.zK,c,$CLJS.vK,!1],null);a=R7(a);var e=$CLJS.I(a,0,null);a=$CLJS.cj.h($CLJS.nG(function(f){return $CLJS.E.g($CLJS.U.h($CLJS.yi.h(f)),$CLJS.U.h(e))},d));return $CLJS.n(a)?null==a?null:new $CLJS.h(null,5,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,
$CLJS.R6,$CLJS.yi,e,$CLJS.zK,a,$CLJS.vK,!1],null):null};Y7=function(a,b,c){return $CLJS.Me(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)},new $CLJS.P(null,3,5,$CLJS.Q,[Vta,Wta,Xta],null))};Z7=function(a,b,c,d){var e=$CLJS.O(c);c=$CLJS.J.g(e,$CLJS.yi);e=$CLJS.J.g(e,$CLJS.cj);var f=$CLJS.E5(a,b);c=f?$CLJS.n(c)?null!=e&&$CLJS.E.g($CLJS.IJ.h(c),$CLJS.fK):c:f;return $CLJS.n(c)?$CLJS.ff(d,$CLJS.B5.g(a,b)):null};
Yta=function(a,b){var c=$CLJS.Q7($CLJS.cf.g(function(d){d=$CLJS.c_.j(a,b,d);return $CLJS.yZ(d)?$CLJS.AO:$CLJS.JZ(d)?$CLJS.A7:$CLJS.CZ(d)?$CLJS.yF:null},$CLJS.z5.g(a,b)));return $CLJS.E.g(new $CLJS.h(null,1,[$CLJS.AO,1],null),c)?new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.yF,null,$CLJS.V5,null],null),null):$CLJS.E.g(new $CLJS.h(null,2,[$CLJS.AO,1,$CLJS.yF,1],null),c)?new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.yF,null,$CLJS.V5,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.A7,1],null),c)?new $CLJS.Rg(null,
new $CLJS.h(null,2,[$CLJS.yF,null,$CLJS.Bx,null],null),null):$CLJS.E.g($CLJS.N,c)?new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.yF,null,$CLJS.Bx,null,$CLJS.V5,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.yF,1],null),c)?new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.yF,null,$CLJS.Bx,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.yF,2],null),c)?new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.yF,null,$CLJS.Bx,null],null),null):$CLJS.Sg};
$7=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.yi);c=$CLJS.J.g(d,$CLJS.GK);var f=$CLJS.J.g(d,$CLJS.cj);if($CLJS.n(function(){var m=$CLJS.E5(a,b);return m?$CLJS.n(e)?null!=f&&$CLJS.E.g($CLJS.IJ.h(e),$CLJS.fK)&&0<$CLJS.D($CLJS.b4.g(a,b)):e:m}())){var k=Yta(a,b),l=$CLJS.Wf.g($CLJS.N,function(){return function u(t){return new $CLJS.ie(null,function(){for(var v=t;;)if(v=$CLJS.y(v)){if($CLJS.vd(v)){var x=$CLJS.ic(v),A=$CLJS.D(x),C=$CLJS.le(A);a:for(var G=0;;)if(G<A){var K=$CLJS.hd(x,G),S=$CLJS.J.g($CLJS.B7,
K);S=Z7(a,b,d,S);$CLJS.n($CLJS.Be(S))&&C.add(new $CLJS.P(null,2,5,$CLJS.Q,[K,S],null));G+=1}else{x=!0;break a}return x?$CLJS.oe($CLJS.qe(C),u($CLJS.jc(v))):$CLJS.oe($CLJS.qe(C),null)}C=$CLJS.z(v);x=$CLJS.J.g($CLJS.B7,C);x=Z7(a,b,d,x);if($CLJS.n($CLJS.Be(x)))return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[C,x],null),u($CLJS.Hc(v)));v=$CLJS.Hc(v)}else return null},null,null)}(k)}());return $CLJS.od(l)?null:new $CLJS.h(null,4,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.J6,$CLJS.GK,c,$CLJS.R5,l],null)}return null};
a8=function(a,b){return $CLJS.nV($CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.N],null),b))};
Zta=function(a,b){var c=$CLJS.oV(a);if($CLJS.GZ(a))return $CLJS.xf;if($CLJS.E.g(b,$CLJS.LK))return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.U,"\x3d",$CLJS.KE,a8($CLJS.HE,$CLJS.H([c]))],null),new $CLJS.h(null,2,[$CLJS.U,"≠",$CLJS.KE,a8($CLJS.TE,$CLJS.H([c]))],null)],null);if($CLJS.zZ(a)||$CLJS.yZ(a))return a=function f(e){return new $CLJS.ie(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var x=0;;)if(x<
m){var A=$CLJS.hd(l,x),C=$CLJS.I(A,0,null);A=$CLJS.I(A,1,null);var G=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.vq,null,$CLJS.zq,null],null),null);G=G.h?G.h(C):G.call(null,C);G=(G=$CLJS.Sa(G))?G:$CLJS.II(c,b);$CLJS.n(G)&&(C=new $CLJS.h(null,2,[$CLJS.U,A,$CLJS.KE,a8(C,$CLJS.H([c,b]))],null),t.add(C));x+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);if($CLJS.n(function(){var x=new $CLJS.Rg(null,new $CLJS.h(null,
2,[$CLJS.vq,null,$CLJS.zq,null],null),null);x=x.h?x.h(v):x.call(null,v);return(x=$CLJS.Sa(x))?x:$CLJS.II(c,b)}()))return $CLJS.ae(new $CLJS.h(null,2,[$CLJS.U,u,$CLJS.KE,a8(v,$CLJS.H([c,b]))],null),f($CLJS.Hc(k)));k=$CLJS.Hc(k)}else return null},null,null)},a(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zq,"\x3c"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vq,"\x3e"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wF,"≠"],null)],null));
if($CLJS.AZ(a)&&($CLJS.QZ(a)||$CLJS.DZ(a)))return a=function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.U,v,$CLJS.KE,a8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}l=$CLJS.z(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);
return $CLJS.ae(new $CLJS.h(null,2,[$CLJS.U,l,$CLJS.KE,a8(t,$CLJS.H([c,b]))],null),f($CLJS.Hc(k)))}return null}},null,null)},a(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fF,"contains"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZE,"does-not-contain"],null)],null));a=function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,
1,null);x=new $CLJS.h(null,2,[$CLJS.U,v,$CLJS.KE,a8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}l=$CLJS.z(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.ae(new $CLJS.h(null,2,[$CLJS.U,l,$CLJS.KE,a8(t,$CLJS.H([c,b]))],null),f($CLJS.Hc(k)))}return null}},null,null)};return a(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wF,"≠"],null)],
null))};b8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.yi);var e=$CLJS.J.g(d,$CLJS.yK);d=$CLJS.J.g(d,$CLJS.cj);var f=(f=$CLJS.E5(a,b))?$CLJS.n(c)?null!=d&&!$CLJS.GZ(c)&&!$CLJS.FZ(c)&&!$CLJS.EZ(c):c:f;return $CLJS.n(f)?(a=U7(a,b,c,e),$CLJS.sk.l($CLJS.H([new $CLJS.h(null,4,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.L5,$CLJS.BK,Zta($CLJS.yi.h(a),d),$CLJS.cj,d],null),a]))):null};
$ta=function(a,b,c){return $CLJS.nG(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);d=$CLJS.I(d,2,null);return $CLJS.N1.v(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null))},$CLJS.F7.g(a,b))};aua=function(a,b,c){a=$ta(a,b,c);return $CLJS.n(a)?(b=$CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),b):null};
c8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.yi),e=$CLJS.J.g(c,$CLJS.yK),f=$CLJS.J.g(c,$CLJS.cj);if($CLJS.n(function(){var l=$CLJS.E5(a,b);return l?$CLJS.n(d)?null==f&&!$CLJS.GZ(d):d:l}())&&$CLJS.n($CLJS.N1.v(a,b,e,$CLJS.G7.g(a,b)))){var k=aua(a,b,d);return new $CLJS.h(null,4,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.k6,$CLJS.yi,d,$CLJS.n6,function(){switch(k instanceof $CLJS.M?k.S:null){case "asc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.XE],null);case "desc":return new $CLJS.P(null,1,5,$CLJS.Q,
[$CLJS.dF],null);default:return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,$CLJS.XE],null)}}()],null)}return null};
d8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.yi);d=$CLJS.J.g(d,$CLJS.cj);var e=$CLJS.E5(a,b);a=e?$CLJS.n(c)?null==d&&!$CLJS.GZ(c)&&$CLJS.Zj.g($CLJS.IJ.h(c),$CLJS.fK)&&$CLJS.Sa($CLJS.y($CLJS.C5.j(a,b,c))):c:e;return $CLJS.n(a)?(a=$CLJS.bf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.LE],null),$CLJS.BZ(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sF,$CLJS.aF],null):null),new $CLJS.h(null,4,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.l6,$CLJS.yi,c,$CLJS.q6,a],null)):null};
e8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.yi);d=$CLJS.J.g(d,$CLJS.cj);var e=$CLJS.E5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.GZ(c)&&$CLJS.BZ(c)&&$CLJS.Zj.g($CLJS.IJ.h(c),$CLJS.fK):c:e;if($CLJS.n(d)&&(d=$CLJS.nG($CLJS.yZ,$CLJS.B5.g(a,b)),$CLJS.n(d)&&(e=$CLJS.nG($CLJS.Oh,$CLJS.q0.j(a,b,d)),$CLJS.n(e)))){var f=$CLJS.Z2(d,e);return $CLJS.n($CLJS.HU($CLJS.cf.g($CLJS.oV,$CLJS.ae(f,$CLJS.z5.g(a,b)))))?new $CLJS.h(null,5,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.G6,$CLJS.yi,c,$CLJS.VE,d,$CLJS.xk,$CLJS.n0.h(e)],
null):null}return null};
f8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.yi);d=$CLJS.J.g(d,$CLJS.cj);if($CLJS.n($CLJS.n(c)?d:c))if(b=$CLJS.z($CLJS.C5.j(a,b,c)),$CLJS.n(b))if(b=$CLJS.Q4(b),$CLJS.n(b))if(a=$CLJS.p7(a,c,d),$CLJS.n(a)){var e=$CLJS.O(a);a=$CLJS.J.g(e,$CLJS.FK);var f=$CLJS.J.g(e,$CLJS.IK);e=$CLJS.J.g(e,$CLJS.yD);var k=$CLJS.zD.h(b);k=k instanceof $CLJS.M?k.S:null;switch(k){case "num-bins":case "default":return new $CLJS.h(null,6,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.p6,$CLJS.yi,c,$CLJS.FK,d,$CLJS.IK,d+e,$CLJS.h6,
new $CLJS.h(null,1,[$CLJS.zD,$CLJS.Oh],null)],null);case "bin-width":return new $CLJS.h(null,6,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.p6,$CLJS.yi,c,$CLJS.FK,a,$CLJS.IK,f,$CLJS.h6,$CLJS.dk.j(b,$CLJS.yD,function(l){return l/10})],null);default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}else return null;else return null;else return null;else return null};
bua=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.wK),e=$CLJS.DV(a,b);b=$CLJS.Me(function(f){var k=$CLJS.nG($CLJS.OZ,f);return $CLJS.n(k)?(f=$CLJS.nG($CLJS.PZ,f),$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null):null):null},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.P_.j(a,b,e),$CLJS.R_.j(a,b,e)],null));a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.n($CLJS.n(a)?b:a)?(e=function(f){return $CLJS.Me(function(k){var l=$CLJS.yi.h(k);l=$CLJS.n($CLJS.Fi.h(f))?$CLJS.E.g($CLJS.Fi.h(f),$CLJS.Fi.h(l)):
$CLJS.E.g($CLJS.HY.h(f),$CLJS.HY.h(l));return $CLJS.n(l)?$CLJS.cj.h(k):null},d)},$CLJS.R.l(c,$CLJS.L7,a,$CLJS.H([$CLJS.M7,b,$CLJS.N7,e(a),$CLJS.K7,e(b)]))):null};
g8=function(a,b){var c=$CLJS.O(a);a=$CLJS.J.g(c,$CLJS.yi);var d=$CLJS.J.g(c,$CLJS.cj),e=$CLJS.J.g(c,$CLJS.L7);c=$CLJS.J.g(c,$CLJS.M7);return $CLJS.n(d)?new $CLJS.h(null,7,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,$CLJS.N6,$CLJS.P5,$CLJS.c6,$CLJS.yi,a,$CLJS.cj,d,$CLJS.f6,new $CLJS.h(null,2,[$CLJS.yi,e,$CLJS.yD,b],null),$CLJS.d6,new $CLJS.h(null,2,[$CLJS.yi,c,$CLJS.yD,b],null)],null):null};cua=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.yi);return $CLJS.n(null==b?null:$CLJS.MZ(b))?g8(a,10):null};
dua=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.yi);return $CLJS.n(null==b?null:$CLJS.LZ(b))?g8(a,1):null};eua=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.yi);return $CLJS.n(null==b?null:$CLJS.KZ(b))?g8(a,.1):null};
fua=function(a,b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.L7);var d=$CLJS.J.g(c,$CLJS.M7),e=$CLJS.J.g(c,$CLJS.N7);c=$CLJS.J.g(c,$CLJS.K7);if($CLJS.n($CLJS.n(e)?c:e)){var f=$CLJS.p7(a,b,e);if($CLJS.n(f)&&(f=$CLJS.O(f),f=$CLJS.J.g(f,$CLJS.yD),a=$CLJS.p7(a,d,c),$CLJS.n(a))){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.yD);var k=20<=f&&20<=a?new $CLJS.P(null,2,5,$CLJS.Q,[10,10],null):new $CLJS.P(null,2,5,$CLJS.Q,[f/10,a/10],null),l=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return new $CLJS.h(null,5,[$CLJS.Rs,$CLJS.MK,$CLJS.Qi,
$CLJS.N6,$CLJS.P5,$CLJS.J5,$CLJS.f6,new $CLJS.h(null,4,[$CLJS.yi,b,$CLJS.yD,l,$CLJS.bn,e,$CLJS.yj,e+f],null),$CLJS.d6,new $CLJS.h(null,4,[$CLJS.yi,d,$CLJS.yD,k,$CLJS.bn,c,$CLJS.yj,c+a],null)],null)}}return null};h8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.cj);if($CLJS.n(d)){var e=bua(a,b,c);return $CLJS.n(e)?$CLJS.Me(function(f){return f.h?f.h(e):f.call(null,e)},new $CLJS.P(null,4,5,$CLJS.Q,[cua,dua,eua,$CLJS.Qe(fua,a)],null)):null}return null};
gua=function(a,b,c){return $CLJS.z(function(){return function f(e){return new $CLJS.ie(null,function(){for(var k=e;;){var l=$CLJS.y(k);if(l){var m=l,t=$CLJS.z(m);if($CLJS.n(function(){var u=$CLJS.A1(t,$CLJS.gF);return u?$CLJS.f2(t):u}())&&(l=$CLJS.y(function(u,v,x,A){return function K(G){return new $CLJS.ie(null,function(S,X){return function(){for(var T=G;;)if(T=$CLJS.y(T)){if($CLJS.vd(T)){var da=$CLJS.ic(T),oa=$CLJS.D(da),Oa=$CLJS.le(oa);return function(){for(var cb=0;;)if(cb<oa){var Ka=$CLJS.hd(da,
cb),kb=$CLJS.O(Ka);Ka=kb;kb=$CLJS.J.g(kb,$CLJS.yi);var Ja=$CLJS.N1.g(X,new $CLJS.P(null,1,5,$CLJS.Q,[kb],null));kb=$CLJS.n(Ja)?$CLJS.E.g($CLJS.f2(X),$CLJS.f2(kb)):Ja;$CLJS.n(kb)&&(Ka=$CLJS.R.j(Ka,$CLJS.yK,X),Oa.add(Ka));cb+=1}else return!0}()?$CLJS.oe($CLJS.qe(Oa),K($CLJS.jc(T))):$CLJS.oe($CLJS.qe(Oa),null)}var pb=$CLJS.z(T),Ra=pb=$CLJS.O(pb),Wa=$CLJS.J.g(pb,$CLJS.yi);if($CLJS.n(function(){var cb=$CLJS.N1.g(X,new $CLJS.P(null,1,5,$CLJS.Q,[Wa],null));return $CLJS.n(cb)?$CLJS.E.g($CLJS.f2(X),$CLJS.f2(Wa)):
cb}()))return $CLJS.ae($CLJS.R.j(Ra,$CLJS.yK,X),K($CLJS.Hc(T)));T=$CLJS.Hc(T)}else return null}}(u,v,x,A),null,null)}}(k,t,m,l)(c))))return $CLJS.bf.g(l,f($CLJS.Hc(k)));k=$CLJS.Hc(k)}else return null}},null,null)}($CLJS.z5.g(a,b))}())};
hua=function(a){a=a instanceof $CLJS.M?a.S:null;switch(a){case "quarter":return $CLJS.GD("See this year by quarter");case "month":return $CLJS.GD("See this quarter by month");case "week":return $CLJS.GD("See this month by week");case "day":return $CLJS.GD("See this week by day");case "hour":return $CLJS.GD("See this day by hour");case "minute":return $CLJS.GD("See this hour by minute");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}};
i8=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.GK);var d=(d=$CLJS.E5(a,b))?$CLJS.Be(c):d;return $CLJS.n(d)&&(a=gua(a,b,c),$CLJS.n(a)&&(a=$CLJS.O(a),b=$CLJS.J.g(a,$CLJS.cj),$CLJS.n(b)))?(b=$CLJS.yi.h(a),b=$CLJS.n0.h(b),b=$CLJS.n(b)?$CLJS.Cd($CLJS.Wg($CLJS.O7),b)?$CLJS.P7.h?$CLJS.P7.h(b):$CLJS.P7.call(null,b):null:null,$CLJS.n(b)?new $CLJS.h(null,5,[$CLJS.Rs,$CLJS.MK,$CLJS.jD,hua(b),$CLJS.Qi,$CLJS.L6,$CLJS.zE,a,$CLJS.F6,b],null):null):null};
iua=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.GK);return $CLJS.Be(function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u);v=$CLJS.sk.l($CLJS.H([b,v]));t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}t=$CLJS.z(k);return $CLJS.ae($CLJS.sk.l($CLJS.H([b,t])),f($CLJS.Hc(k)))}return null}},null,
null)}(c)}())};jua=new $CLJS.r(null,"metabase.lib.drill-thru.pivot","metabase.lib.drill-thru.pivot",109208756,null);kua=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-bins","metabase.lib.drill-thru.zoom-in-bins",477390405,null);lua=new $CLJS.r("metabase.lib.drill-thru.zoom-in-bins","zoom-in-binning-drill","metabase.lib.drill-thru.zoom-in-bins/zoom-in-binning-drill",-862934837,null);
mua=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column-by-time","metabase.lib.drill-thru.summarize-column-by-time",-369504986,null);j8=new $CLJS.r(null,"stage-number","stage-number",-112198111,null);nua=new $CLJS.r(null,"underlying-records-drill","underlying-records-drill",713740052,null);oua=new $CLJS.r("metabase.lib.drill-thru.zoom-in-timeseries","zoom-in-timeseries-drill","metabase.lib.drill-thru.zoom-in-timeseries/zoom-in-timeseries-drill",313862423,null);
pua=new $CLJS.r(null,"zoom-in-timeseries-drill","zoom-in-timeseries-drill",-1304664195,null);qua=new $CLJS.r(null,"distribution-drill","distribution-drill",-898205041,null);rua=new $CLJS.r(null,"sort-drill","sort-drill",600387798,null);sua=new $CLJS.M(null,"drill","drill",-1704624793);tua=new $CLJS.r("metabase.lib.drill-thru.quick-filter","quick-filter-drill","metabase.lib.drill-thru.quick-filter/quick-filter-drill",457872114,null);k8=new $CLJS.r(null,"_context","_context",-1238791322,null);
uua=new $CLJS.r(null,"metabase.lib.drill-thru.distribution","metabase.lib.drill-thru.distribution",-514507852,null);vua=new $CLJS.r(null,"zoom-in-binning-drill","zoom-in-binning-drill",1550598377,null);wua=new $CLJS.r(null,"metabase.lib.drill-thru.object-details","metabase.lib.drill-thru.object-details",1788168194,null);xua=new $CLJS.r(null,"automatic-insights-drill","automatic-insights-drill",-538289251,null);
yua=new $CLJS.r("metabase.lib.drill-thru.zoom-in-geographic","zoom-in-geographic-drill","metabase.lib.drill-thru.zoom-in-geographic/zoom-in-geographic-drill",754461586,null);zua=new $CLJS.r("metabase.lib.drill-thru.distribution","distribution-drill","metabase.lib.drill-thru.distribution/distribution-drill",-2103693273,null);Aua=new $CLJS.r(null,"fk-filter-drill","fk-filter-drill",-1258190784,null);
Bua=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column","metabase.lib.drill-thru.summarize-column",490384430,null);Cua=new $CLJS.r(null,"pivot-drill","pivot-drill",-1794856768,null);Dua=new $CLJS.r(null,"metabase.lib.drill-thru.quick-filter","metabase.lib.drill-thru.quick-filter",671398986,null);Eua=new $CLJS.r(null,"metabase.lib.drill-thru.underlying-records","metabase.lib.drill-thru.underlying-records",-827008688,null);
Fua=new $CLJS.r("metabase.lib.drill-thru.summarize-column-by-time","summarize-column-by-time-drill","metabase.lib.drill-thru.summarize-column-by-time/summarize-column-by-time-drill",391970943,null);Gua=new $CLJS.r(null,"summarize-column-drill","summarize-column-drill",-1961542613,null);Hua=new $CLJS.r(null,"column-filter-drill","column-filter-drill",-1088669153,null);
Iua=new $CLJS.r("metabase.lib.drill-thru.underlying-records","underlying-records-drill","metabase.lib.drill-thru.underlying-records/underlying-records-drill",1176519262,null);Jua=new $CLJS.r(null,"metabase.lib.drill-thru.sort","metabase.lib.drill-thru.sort",961185366,null);Kua=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-geographic","metabase.lib.drill-thru.zoom-in-geographic",-1562146002,null);l8=new $CLJS.r(null,"dimensions","dimensions",1385713430,null);
m8=new $CLJS.M(null,"return-drills-for-dimensions?","return-drills-for-dimensions?",-1759804348);Lua=new $CLJS.r("metabase.lib.drill-thru.column-filter","column-filter-drill","metabase.lib.drill-thru.column-filter/column-filter-drill",-1130871894,null);Mua=new $CLJS.r(null,"object-detail-drill","object-detail-drill",-613919053,null);Nua=new $CLJS.r(null,"metabase.lib.drill-thru.column-filter","metabase.lib.drill-thru.column-filter",23504270,null);
n8=new $CLJS.r(null,"context","context",810340414,null);Oua=new $CLJS.r(null,"zoom-in-geographic-drill","zoom-in-geographic-drill",312914971,null);Pua=new $CLJS.r("metabase.lib.drill-thru.automatic-insights","automatic-insights-drill","metabase.lib.drill-thru.automatic-insights/automatic-insights-drill",1471807986,null);Qua=new $CLJS.r(null,"summarize-column-by-time-drill","summarize-column-by-time-drill",1117784387,null);
Rua=new $CLJS.r("metabase.lib.drill-thru.object-details","object-detail-drill","metabase.lib.drill-thru.object-details/object-detail-drill",-1376576725,null);o8=new $CLJS.r(null,"column-ref","column-ref",-636247393,null);Sua=new $CLJS.r("metabase.lib.drill-thru.pivot","pivot-drill","metabase.lib.drill-thru.pivot/pivot-drill",1004065694,null);Tua=new $CLJS.r(null,"metabase.lib.drill-thru.fk-filter","metabase.lib.drill-thru.fk-filter",-1939155669,null);
Uua=new $CLJS.r("metabase.lib.drill-thru.summarize-column","summarize-column-drill","metabase.lib.drill-thru.summarize-column/summarize-column-drill",-894275347,null);p8=new $CLJS.r(null,"query","query",352022017,null);Vua=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-timeseries","metabase.lib.drill-thru.zoom-in-timeseries",105846904,null);Wua=new $CLJS.r(null,"metabase.lib.drill-thru.automatic-insights","metabase.lib.drill-thru.automatic-insights",912989182,null);
Xua=new $CLJS.r("metabase.lib.drill-thru.fk-filter","fk-filter-drill","metabase.lib.drill-thru.fk-filter/fk-filter-drill",-716335012,null);Yua=new $CLJS.r("metabase.lib.drill-thru.sort","sort-drill","metabase.lib.drill-thru.sort/sort-drill",2019212116,null);Zua=new $CLJS.r(null,"quick-filter-drill","quick-filter-drill",534264366,null);var $ua;$CLJS.N_.m(null,$CLJS.MK,function(a,b,c){return $CLJS.H5.j(a,b,c)});
$ua=new $CLJS.P(null,14,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Nr,new $CLJS.Cc(function(){return T7},Pua,$CLJS.zg([$CLJS.Jk,$CLJS.Ci,$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Oq,$CLJS.JK,$CLJS.lr,$CLJS.U5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.j6],null)],null),Wua,xua,"metabase/lib/drill_thru/automatic_insights.cljc",34,1,11,11,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[p8,j8,new $CLJS.h(null,
1,[$CLJS.eo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ty,o8,l8,$CLJS.Ai],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru]\n          \n\n  Automatic insights appears:\n  - When clicking on a value with a breakout - eg. a point in a time series, a cell of a table, a bar or pie slice\n  - Or when clicking a pivot cell, with a value but no column.\n  - Or when clicking a chart legend, in which case there\'s no column or value set.\n  - There must be at least 1 breakout\n  - X-rays must be enabled (check the settings)\n\n  There are two forms: X-ray, and "Compare to the rest". This is a simple user choice and does not need extra data.',
$CLJS.n(T7)?T7.H:null])),m8,!1],null),new $CLJS.h(null,2,[$CLJS.Nr,new $CLJS.Cc(function(){return V7},Lua,$CLJS.zg([$CLJS.Jk,$CLJS.Ci,$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Oq,$CLJS.JK,$CLJS.lr,$CLJS.U5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.X5],null)],null),Nua,Hua,"metabase/lib/drill_thru/column_filter.cljc",29,1,82,82,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[p8,j8,new $CLJS.h(null,
1,[$CLJS.eo,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ty,o8,$CLJS.Ai],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.column-filter]\n          \n\n  Filtering at the column level, based on its type. Displays a submenu of eg. "Today", "This Week", etc. for date\n  columns.\n\n  Note that if the clicked column is an aggregation, filtering by it will require a new stage. Therefore this drill\n  returns a possibly-updated `:query` and `:stage-number` along with a `:column` referencing that later stage.',
$CLJS.n(V7)?V7.H:null])),m8,!0],null),new $CLJS.h(null,2,[$CLJS.Nr,new $CLJS.Cc(function(){return W7},zua,$CLJS.zg([$CLJS.Jk,$CLJS.Ci,$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Oq,$CLJS.JK,$CLJS.lr,$CLJS.U5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.O5],null)],null),uua,qua,"metabase/lib/drill_thru/distribution.cljc",28,1,42,42,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[p8,j8,new $CLJS.h(null,
1,[$CLJS.eo,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ty,$CLJS.Ai],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.distribution]\n          \n\n  Select a column and see a histogram of how many rows fall into an automatic set of bins/buckets.\n  - For dates, breaks out by month by default.\n  - For numeric values, by an auto-selected set of bins\n  - For strings, by each distinct value (which might be \x3d the number of rows)",
$CLJS.n(W7)?W7.H:null])),m8,!0],null),new $CLJS.h(null,2,[$CLJS.Nr,new $CLJS.Cc(function(){return X7},Xua,$CLJS.zg([$CLJS.Jk,$CLJS.Ci,$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Oq,$CLJS.JK,$CLJS.lr,$CLJS.U5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.D6],null)],null),Tua,Aua,"metabase/lib/drill_thru/fk_filter.cljc",25,1,37,37,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[p8,j8,new $CLJS.h(null,
2,[$CLJS.eo,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ty,o8,$CLJS.Ai],null),$CLJS.Tr,k8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.fk-filter]\n          \n\n  When clicking on a foreign key value, filter this query by that column.\n\n  This has the same effect as the `\x3d` filter on a generic field (ie. not a key), but renders differently.\n\n  Contrast [[metabase.lib.drill-thru.object-details/object-detail-drill]], which shows the details of the foreign\n  object.",
$CLJS.n(X7)?X7.H:null])),m8,!1],null),new $CLJS.h(null,2,[$CLJS.Nr,new $CLJS.Cc(function(){return Y7},Rua,$CLJS.zg([$CLJS.Jk,$CLJS.Ci,$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Oq,$CLJS.JK,$CLJS.lr,$CLJS.U5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.$q,$CLJS.K5,$CLJS.E6,$CLJS.t6],null)],null)],null),wua,Mua,"metabase/lib/drill_thru/object_details.cljc",
29,1,10,10,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[p8,j8,n8],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int context :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe\n           [:or\n            :metabase.lib.schema.drill-thru/drill-thru.pk\n            :metabase.lib.schema.drill-thru/drill-thru.zoom\n            :metabase.lib.schema.drill-thru/drill-thru.fk-details]]\n          \n\n  When clicking a foreign key or primary key value, drill through to the details for that specific object.\n\n  Contrast [[metabase.lib.drill-thru.fk-filter/fk-filter-drill]], which filters this query to only those rows with a\n  specific value for a FK column.",
$CLJS.n(Y7)?Y7.H:null])),m8,!1],null),new $CLJS.h(null,2,[$CLJS.Nr,new $CLJS.Cc(function(){return $7},Sua,$CLJS.zg([$CLJS.Jk,$CLJS.Ci,$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Oq,$CLJS.JK,$CLJS.lr,$CLJS.U5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.S5],null)],null),jua,Cua,"metabase/lib/drill_thru/pivot.cljc",21,1,119,119,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[p8,j8,new $CLJS.h(null,
2,[$CLJS.eo,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ty,l8,$CLJS.Ai],null),$CLJS.Tr,n8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column dimensions value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.pivot]\n          \n\n  Return all possible pivoting options on the given column and value.\n\n  See `:pivots` key, which holds a map `{t [breakouts...]}` where `t` is `:category`, `:location`, or `:time`.\n  If a key is missing, there are no breakouts of that kind.",
$CLJS.n($7)?$7.H:null])),m8,!1],null),new $CLJS.h(null,2,[$CLJS.Nr,new $CLJS.Cc(function(){return b8},tua,$CLJS.zg([$CLJS.Jk,$CLJS.Ci,$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Oq,$CLJS.JK,$CLJS.lr,$CLJS.U5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.u6],null)],null),Dua,Zua,"metabase/lib/drill_thru/quick_filter.cljc",28,1,93,93,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[p8,j8,new $CLJS.h(null,
2,[$CLJS.eo,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ty,o8,$CLJS.Ai],null),$CLJS.Tr,k8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.quick-filter]\n          \n\n  Filter the current query based on the value clicked.\n\n  The options vary depending on the type of the field:\n  - `:is-null` and `:not-null` for a `NULL` value;\n  - `:\x3d` and `:!\x3d` for everything else;\n  - plus `:\x3c` and `:\x3e` for numeric and date columns.\n\n  Note that this returns a single `::drill-thru` value with 1 or more `:operators`; these are rendered as a set of small\n  buttons in a single row of the drop-down.",
$CLJS.n(b8)?b8.H:null])),m8,!1],null),new $CLJS.h(null,2,[$CLJS.Nr,new $CLJS.Cc(function(){return c8},Yua,$CLJS.zg([$CLJS.Jk,$CLJS.Ci,$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Oq,$CLJS.JK,$CLJS.lr,$CLJS.U5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.O6],null)],null),Jua,rua,"metabase/lib/drill_thru/sort.cljc",20,1,51,51,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[p8,j8,new $CLJS.h(null,
2,[$CLJS.eo,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ty,o8,$CLJS.Ai],null),$CLJS.Tr,k8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.sort]\n          \n\n  Sorting on a clicked column.",$CLJS.n(c8)?c8.H:null])),m8,!0],null),new $CLJS.h(null,2,[$CLJS.Nr,new $CLJS.Cc(function(){return d8},Uua,$CLJS.zg([$CLJS.Jk,$CLJS.Ci,
$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Oq,$CLJS.JK,$CLJS.lr,$CLJS.U5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.$5],null)],null),Bua,Gua,"metabase/lib/drill_thru/summarize_column.cljc",32,1,31,31,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[p8,j8,new $CLJS.h(null,1,[$CLJS.eo,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ty,$CLJS.Ai],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column]\n          \n\n  A set of possible aggregations that can summarize this column: distinct values, sum, average.\n  Separate from [[summarize-column-by-time-drill]] which breaks out a column over time.",
$CLJS.n(d8)?d8.H:null])),m8,!0],null),new $CLJS.h(null,2,[$CLJS.Nr,new $CLJS.Cc(function(){return e8},Fua,$CLJS.zg([$CLJS.Jk,$CLJS.Ci,$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Oq,$CLJS.JK,$CLJS.lr,$CLJS.U5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.Y5],null)],null),mua,Qua,"metabase/lib/drill_thru/summarize_column_by_time.cljc",40,1,40,40,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,
[p8,j8,new $CLJS.h(null,1,[$CLJS.eo,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ty,$CLJS.Ai],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time]\n          \n\n  A breakout summarizing a column over time.\n  Separate from single-value [[summarize-column-drill]] for sum, average, and distinct value count.",$CLJS.n(e8)?
e8.H:null])),m8,!0],null),new $CLJS.h(null,2,[$CLJS.Nr,new $CLJS.Cc(function(){return S7},Iua,$CLJS.zg([$CLJS.Jk,$CLJS.Ci,$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Oq,$CLJS.JK,$CLJS.lr,$CLJS.U5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.M6],null)],null),Eua,nua,"metabase/lib/drill_thru/underlying_records.cljc",34,1,51,51,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[p8,j8,new $CLJS.h(null,
2,[$CLJS.eo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ty,o8,l8,$CLJS.Ai],null),$CLJS.Tr,k8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.underlying-records]\n          \n\n  When clicking on a particular broken-out group, offer a look at the details of all the rows that went into this\n  bucket. Eg. distribution of People by State, then click New York and see the table of all People filtered by\n  `STATE \x3d 'New York'`.\n\n  There is another quite different case: clicking the legend of a chart with multiple bars or lines broken out by\n  category. Then `column` is nil!",
$CLJS.n(S7)?S7.H:null])),m8,!1],null),new $CLJS.h(null,2,[$CLJS.Nr,new $CLJS.Cc(function(){return i8},oua,$CLJS.zg([$CLJS.Jk,$CLJS.Ci,$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Oq,$CLJS.JK,$CLJS.lr,$CLJS.U5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.H6],null)],null),Vua,pua,"metabase/lib/drill_thru/zoom_in_timeseries.cljc",34,1,89,89,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[p8,j8,
new $CLJS.h(null,2,[$CLJS.eo,new $CLJS.P(null,1,5,$CLJS.Q,[l8],null),$CLJS.Tr,k8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [dimensions], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries]\n          \n\n  Zooms in on some window, showing it in finer detail.\n\n  For example: The month of a year, days or weeks of a quarter, smaller lat/long regions, etc.\n\n  This is different from the `:drill-thru/zoom` type, which is for showing the details of a single object.",
$CLJS.n(i8)?i8.H:null])),m8,!1],null),new $CLJS.h(null,2,[$CLJS.Nr,new $CLJS.Cc(function(){return h8},yua,$CLJS.zg([$CLJS.Jk,$CLJS.Ci,$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Oq,$CLJS.JK,$CLJS.lr,$CLJS.U5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.I6],null)],null),Kua,Oua,"metabase/lib/drill_thru/zoom_in_geographic.cljc",34,1,189,189,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[p8,
j8,new $CLJS.h(null,2,[$CLJS.eo,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ai],null),$CLJS.Tr,n8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.geographic]\n          \n\n  Return a `:drill-thru/zoom-in.geographic` drill if appropriate. See docstring\n  for [[metabase.lib.drill-thru.zoom-in-geographic]] for more information on what circumstances this is returned in\n  and what it means to apply this drill.",
$CLJS.n(h8)?h8.H:null])),m8,!0],null),new $CLJS.h(null,2,[$CLJS.Nr,new $CLJS.Cc(function(){return f8},lua,$CLJS.zg([$CLJS.Jk,$CLJS.Ci,$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Oq,$CLJS.JK,$CLJS.lr,$CLJS.U5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.K6],null)],null),kua,vua,"metabase/lib/drill_thru/zoom_in_bins.cljc",31,1,86,86,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[p8,j8,new $CLJS.h(null,
2,[$CLJS.eo,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ty,$CLJS.Ai],null),$CLJS.Tr,k8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.binning]\n          \n\n  Return a drill thru that 'zooms in' on a breakout that uses `:binning` if applicable.\n  See [[metabase.lib.drill-thru.zoom-in-bins]] docstring for more information.",
$CLJS.n(f8)?f8.H:null])),m8,!0],null)],null);
$CLJS.ava=function(){function a(d,e,f){try{return $CLJS.Wf.g($CLJS.xf,$CLJS.U0(d)?function(){var l=iua(f);return function u(t){return new $CLJS.ie(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var A=x,C=$CLJS.z(A),G=$CLJS.O(C),K=$CLJS.J.g(G,$CLJS.Nr),S=$CLJS.J.g(G,m8);if(x=$CLJS.y(function(X,T,da,oa,Oa,pb,Ra,Wa){return function kb(Ka){return new $CLJS.ie(null,function(Ja,bc,Jd,Fd){return function(){for(var ed=Ka;;)if(ed=$CLJS.y(ed)){if($CLJS.vd(ed)){var Qh=$CLJS.ic(ed),mz=$CLJS.D(Qh),Fl=$CLJS.le(mz);
a:for(var Qn=0;;)if(Qn<mz){var sr=$CLJS.hd(Qh,Qn);sr=Fd.j?Fd.j(d,e,sr):Fd.call(null,d,e,sr);$CLJS.n(sr)&&Fl.add(sr);Qn+=1}else{Qh=!0;break a}return Qh?$CLJS.oe($CLJS.qe(Fl),kb($CLJS.jc(ed))):$CLJS.oe($CLJS.qe(Fl),null)}Fl=$CLJS.z(ed);Fl=Fd.j?Fd.j(d,e,Fl):Fd.call(null,d,e,Fl);if($CLJS.n(Fl))return $CLJS.ae(Fl,kb($CLJS.Hc(ed)));ed=$CLJS.Hc(ed)}else return null}}(X,T,da,oa,Oa,pb,Ra,Wa),null,null)}}(v,C,G,K,S,A,x,l)($CLJS.n(function(){var X=S;return $CLJS.n(X)?l:X}())?l:new $CLJS.P(null,1,5,$CLJS.Q,[f],
null))))return $CLJS.bf.g(x,u($CLJS.Hc(v)));v=$CLJS.Hc(v)}else return null}},null,null)}($ua)}():null)}catch(l){var k=l;throw $CLJS.Th(["Error getting available drill thrus for query: ",$CLJS.p.h($CLJS.rU(k))].join(""),new $CLJS.h(null,3,[$CLJS.CE,d,$CLJS.DK,e,$CLJS.oM,f],null),k);}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();
$CLJS.bva=function(){function a(d,e){return b.j?b.j(d,-1,e):b.call(null,d,-1,e)}var b=null,c=function(){function d(f,k,l,m){var t=null;if(3<arguments.length){t=0;for(var u=Array(arguments.length-3);t<u.length;)u[t]=arguments[t+3],++t;t=new $CLJS.w(u,0,null)}return e.call(this,f,k,l,t)}function e(f,k,l,m){var t=$CLJS.Iz($CLJS.xx);$CLJS.n($CLJS.Hz("metabase.lib.drill-thru",t))&&$CLJS.Gz("metabase.lib.drill-thru",t,$CLJS.sV("Applying drill thru: %s",$CLJS.H([$CLJS.tV(new $CLJS.h(null,4,[$CLJS.CE,f,$CLJS.DK,
k,sua,l,$CLJS.Gr,m],null))])),null);return $CLJS.Ne.M($CLJS.G5,f,k,l,m)}d.A=3;d.B=function(f){var k=$CLJS.z(f);f=$CLJS.B(f);var l=$CLJS.z(f);f=$CLJS.B(f);var m=$CLJS.z(f);f=$CLJS.Hc(f);return e(k,l,m,f)};d.l=e;return d}();b=function(d,e,f,k){switch(arguments.length){case 2:return a.call(this,d,e);default:var l=null;if(3<arguments.length){l=0;for(var m=Array(arguments.length-3);l<m.length;)m[l]=arguments[l+3],++l;l=new $CLJS.w(m,0,null)}return c.l(d,e,f,l)}throw Error("Invalid arity: "+arguments.length);
};b.A=3;b.B=c.B;b.g=a;b.l=c.l;return b}();