var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var UV,VV,XV,YV,Zma,$V,aW,TV,bW,$ma,ana,bna,cna,fW,gW,iW,jW,kW,lW,mW,nW,ena,fna,pW,gna,qW,hna,sW,ina,jna,kna,lna,mna,QV;$CLJS.RV=function(a,b){if("string"===typeof b)return QV(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.SV=function(a,b,c){var d=$CLJS.xl(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Cb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
UV=function(a){var b=TV;return $CLJS.Ez(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};VV=function(a){if($CLJS.id(a))return a;throw $CLJS.Uh("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.oi,a],null));};
$CLJS.WV=function(a,b,c){return $CLJS.sd(c)?$CLJS.Wf.g(c,function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.hd(l,A),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,X=G;G=$CLJS.Yd.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[X,G],null));A+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var A=$CLJS.Yd.g(b,v),C=x;return a.g?a.g(A,C):a.call(null,A,C)}()],null),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):$CLJS.rd(c)?$CLJS.ck.g($CLJS.Qe(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.Yd.g(b,$CLJS.z(c)):b),c):c};XV=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.de(a):b;return $CLJS.n(b)?[$CLJS.de(a),"/",$CLJS.Xg(a)].join(""):$CLJS.Xg(a)};
YV=function(a,b){var c=$CLJS.rd(b)&&!$CLJS.uf(b)&&$CLJS.z(b)instanceof $CLJS.M;return c?$CLJS.pd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c};
Zma=function(a,b){return $CLJS.df(function(c){var d=$CLJS.ff;a:try{if($CLJS.ud(c)&&1<=$CLJS.D(c))try{var e=$CLJS.hk.j(c,0,1);if($CLJS.ud(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Qe($CLJS.E,a)(f))){var k=$CLJS.hk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Z;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.z($CLJS.y(d($CLJS.Ta,l)))},$CLJS.H([b]))};
$V=function(a,b){for(;;)switch(b=$CLJS.tk.h($CLJS.ff($CLJS.Ta,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.z(b),ZV.h?ZV.h(a):ZV.call(null,a);default:if($CLJS.n($CLJS.Me($CLJS.Qe(YV,a),b))){var c=a;b=Zma(a,b);a=c}else{c=$CLJS.cf.g(ZV,b);if($CLJS.E.g(c,b))return $CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};aW=function(a){var b=$CLJS.rd(a);return b?(b=!$CLJS.uf(a))?$CLJS.vz($CLJS.be,$CLJS.Va)($CLJS.z(a)):b:b};
TV=function(a){return $CLJS.n($CLJS.vz($CLJS.be,$CLJS.Va)(a))?$CLJS.mh.h($CLJS.Cz(XV(a).toLowerCase(),/_/,"-")):a};bW=function(a,b){var c=aW(b);return $CLJS.n(c)?(b=TV($CLJS.z(b)),$CLJS.pd(a)?(a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};$ma=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.Oh,$CLJS.Pd,$CLJS.Qi,TV,$CLJS.PM,TV],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ae([a,b]);b=cW.g?cW.g(b,dW):cW.call(null,b,dW);return d.call(c,b,a)})};
ana=function(a){a=$CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=TV(c);var d=$ma(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.Qi.h(a),$CLJS.zE)&&$CLJS.Sa($CLJS.PM.h(a))?$CLJS.R.j(a,$CLJS.PM,$CLJS.yF):a};bna=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=XV(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(ana(b),$CLJS.U,c)],null)}),a)};
cna=function(a){a=cW.h?cW.h(a):cW.call(null,a);return eW.h?eW.h(a):eW.call(null,a)};fW=function(a){return $CLJS.SV($CLJS.SV($CLJS.bb(function(b,c){return $CLJS.SV(b,c,$CLJS.mh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.aC,$CLJS.wga,$CLJS.CN,dna,$CLJS.Uy,$CLJS.xk],null)),$CLJS.dB,cna),$CLJS.RM,$CLJS.tU)};gW=function(a){return $CLJS.Ad(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,a,null],null):a};iW=function(a){return hW.h(gW(a))};
jW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return ZV($CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.g(hW,b)))};kW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,iW(b)],null),$CLJS.cf.g(hW,c))};lW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,iW(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
mW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,iW(a)],null)};nW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,hW.h(a)],null)};ena=function(a){return $CLJS.yf($CLJS.ul(eW,oW(a)))};fna=function(a){for(;;)if($CLJS.n(aW(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Be($CLJS.ck.g(gW,a))};
pW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.zE);return $CLJS.n(b)?$CLJS.dk.j(a,$CLJS.zE,hW):a};
gna=function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,pW(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,
2,5,$CLJS.Q,[l,pW(f)],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())};qW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.EP);return $CLJS.n(b)?$CLJS.dk.j(a,$CLJS.EP,gna):a};hna=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.hP);b=$CLJS.Sa(a)?rW.h?rW.h(b):rW.call(null,b):b;return $CLJS.n(a)?qW(b):b};sW=function(a){return $CLJS.pd(a)?$CLJS.y(a):null!=a};
ina=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.CE);var c=$CLJS.J.g(b,$CLJS.tM),d=$CLJS.J.g(b,$CLJS.QN),e=$CLJS.J.g(b,$CLJS.hP);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.QN);var l=$CLJS.VK($CLJS.Tj.g(f,$CLJS.QN),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,$CLJS.QN],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.dk.j(l,$CLJS.CE,rW):l,t=$CLJS.n(c)?$CLJS.dk.j(m,$CLJS.tM,$CLJS.Qe($CLJS.ck,eW)):m,u=$CLJS.n(e)?$CLJS.dk.j(t,$CLJS.hP,qW):t,v=$CLJS.O(u),x=$CLJS.J.g(v,$CLJS.hP);return $CLJS.n(x)?$CLJS.R.j(eW($CLJS.Tj.g(v,
$CLJS.hP)),$CLJS.hP,x):eW(v)}catch(A){if(A instanceof Error)throw m=A,$CLJS.Th($CLJS.eD("Error canonicalizing query: {0}",$CLJS.H([$CLJS.rU(m)])),new $CLJS.h(null,1,[$CLJS.CE,a],null),m);throw A;}};
jna=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.CE),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.VE);c=$CLJS.J.g(c,$CLJS.OD);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.Wf.j($CLJS.Sg,$CLJS.pB,$CLJS.y($CLJS.ff($CLJS.Ta,function k(e,f){try{if($CLJS.ud(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.ce(l,$CLJS.gF)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,m,$CLJS.Tj.g(t,$CLJS.mF)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.yU(k,e,f);throw l;}throw u;}}($CLJS.xf,b)))),$CLJS.tW.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,$CLJS.OD],null),$CLJS.ak.g($CLJS.yf,$CLJS.Qe($CLJS.bk,b)))):a};
kna=function(a){try{return jna(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.Th($CLJS.GD("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.CE,a],null),b);}throw c;}};
lna=function(a,b){var c=$CLJS.Wf.g($CLJS.gd(a),function(){return function f(e){return new $CLJS.ie(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.hd(l,A),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.Yd.g(b,G);C=uW.g?uW.g(C,K):uW.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));A+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var A=x,C=$CLJS.Yd.g(b,v);return uW.g?uW.g(A,C):uW.call(null,A,C)}();if(null!=u)return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Hc(k)));k=$CLJS.Hc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};mna=function(a,b){a=$CLJS.ck.g(function(c){var d=$CLJS.Yd.g(b,vW);return uW.g?uW.g(c,d):uW.call(null,c,d)},a);return $CLJS.n($CLJS.Me($CLJS.Ta,a))?a:null};
QV=function QV(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.yf(d);return $CLJS.ae(f,new $CLJS.ie(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),QV.g?QV.g(a,k):QV.call(null,a,k)):null},null,null))};
$CLJS.tW=function tW(a){switch(arguments.length){case 3:return tW.j(arguments[0],arguments[1],arguments[2]);case 4:return tW.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return tW.M(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return tW.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return tW.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.tW.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.z(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.tW.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.tW.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.z(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.tW.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.tW.M=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.z(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.tW.M($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.tW.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.z(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.tW.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.tW.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.z(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Ne.l($CLJS.tW,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Ne.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.tW.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);var e=$CLJS.B(d);d=$CLJS.z(e);var f=$CLJS.B(e);e=$CLJS.z(f);var k=$CLJS.B(f);f=$CLJS.z(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.tW.A=6;
var ZV=function ZV(a){for(;;){if($CLJS.sd(a))return $CLJS.cs(a,ZV);if($CLJS.yd(a))a=$CLJS.yf(a);else if($CLJS.ud(a)){if($CLJS.Sa($CLJS.Me($CLJS.Ta,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.z(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.S:null;switch(v){case "not":if($CLJS.dl(m)){var x=$CLJS.z(m),A=x instanceof $CLJS.M?x.S:null;switch(A){case "not":a=$CLJS.dd(m);continue;case "and":return $V($CLJS.$q,$CLJS.cf.g(function(){return function(C){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.br,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Hc(m)));case "or":return $V($CLJS.gr,$CLJS.cf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Hc(m)));default:return a}}else return a;case "and":return $V($CLJS.gr,t);case "or":return $V($CLJS.$q,t);default:return $CLJS.ck.g(ZV,a)}}else return a}},nna=new $CLJS.M(null,"value_field","value_field",-980977878),wW=new $CLJS.M(null,"ascending","ascending",-988350486),
xW=new $CLJS.M(null,"named","named",-422393479),yW=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),zW=new $CLJS.M(null,"descending","descending",-24766135),AW=new $CLJS.M(null,"datetime-field","datetime-field",21731696),ona=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),BW=new $CLJS.M(null,"field-literal","field-literal",-1295883554),CW=new $CLJS.M(null,"viz-settings","viz-settings",256055379),pna=new $CLJS.M(null,"rows","rows",850049680),qna=
new $CLJS.M(null,"special-fn","special-fn",1290649344),vW=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),dW=new $CLJS.M(null,"ignore-path","ignore-path",944069061),rna=new $CLJS.M(null,"label_field","label_field",-1573182765),sna=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),tna=new $CLJS.M(null,"joined-field","joined-field",-2048778268),dna=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var DW,una=$CLJS.Se($CLJS.N),vna=$CLJS.Se($CLJS.N),wna=$CLJS.Se($CLJS.N),xna=$CLJS.Se($CLJS.N),yna=$CLJS.J.j($CLJS.N,$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));DW=new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.ak.g(TV,$CLJS.z),yna,una,vna,wna,xna);DW.m(null,$CLJS.WE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WE,b],null);return null!=a?$CLJS.Yd.g(b,a):b});
DW.m(null,$CLJS.mA,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mA,a instanceof $CLJS.M?XV(a):a],null)});DW.m(null,$CLJS.AU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.Yd.g(DW.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AU,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AU,cW.g?cW.g(b,dW):cW.call(null,b,dW),TV(c)],null)});
DW.m(null,$CLJS.gF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=cW.g?cW.g(a,dW):cW.call(null,a,dW);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,b,function(){var d=$CLJS.n($CLJS.pA.h(c))?$CLJS.dk.j(c,$CLJS.pA,$CLJS.mh):c;d=$CLJS.n($CLJS.mF.h(c))?$CLJS.dk.j(d,$CLJS.mF,$CLJS.mh):d;return $CLJS.n($CLJS.sQ.h(c))?$CLJS.dk.j(d,$CLJS.sQ,function(e){return $CLJS.n($CLJS.zD.h(e))?$CLJS.dk.j(e,$CLJS.zD,$CLJS.mh):e}):d}()],null)});
DW.m(null,BW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[BW,b instanceof $CLJS.M?XV(b):b,$CLJS.mh.h(a)],null)});DW.m(null,AW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[AW,cW.g?cW.g(b,dW):cW.call(null,b,dW),$CLJS.Tr,TV(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[AW,cW.g?cW.g(b,dW):cW.call(null,b,dW),TV(c)],null)});
DW.m(null,$CLJS.bB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.Yd.g(DW.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bB,b,c,d],null)),cW.g?cW.g(a,dW):cW.call(null,a,dW)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bB,cW.g?cW.g(b,dW):cW.call(null,b,dW),$CLJS.Ad(c)?c:TV(c),TV(d)],null)});
DW.m(null,$CLJS.YA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YA,b,TV(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YA,$CLJS.Mu],null)});DW.m(null,$CLJS.XA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XA,b,TV(a)],null)});
DW.m(null,$CLJS.$A,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.$A,cW.g?cW.g(b,dW):cW.call(null,b,dW),c,TV(a)],null)});DW.m(null,$CLJS.cB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cB,cW.g?cW.g(b,dW):cW.call(null,b,dW),c,TV(a)],null)});
DW.m(null,$CLJS.ZA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZA,cW.g?cW.g(b,dW):cW.call(null,b,dW),TV(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZA,cW.g?cW.g(b,dW):cW.call(null,b,dW)],null)});
DW.m(null,$CLJS.aB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.aB,cW.g?cW.g(b,dW):cW.call(null,b,dW),TV(c),TV(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aB,cW.g?cW.g(b,dW):cW.call(null,b,dW),TV(c)],null)});
DW.m(null,$CLJS.WA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.WA,cW.g?cW.g(b,dW):cW.call(null,b,dW),cW.g?cW.g(c,dW):cW.call(null,c,dW),TV(a)],null)});DW.m(null,$CLJS.cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cj,b,a],null)});
DW.m(null,$CLJS.Oh,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[TV(a)],null),$CLJS.cf.h(function(c){return cW.g?cW.g(c,dW):cW.call(null,c,dW)}),b)});
var EW=function EW(a){if($CLJS.n($CLJS.vz($CLJS.be,$CLJS.Va)(a))){var c=TV(a);var d=new $CLJS.Rg(null,new $CLJS.h(null,18,[$CLJS.bn,null,$CLJS.Fq,null,$CLJS.uF,null,$CLJS.pF,null,$CLJS.Vr,null,$CLJS.hF,null,$CLJS.zF,null,$CLJS.AF,null,$CLJS.LE,null,$CLJS.SE,null,$CLJS.Eq,null,$CLJS.ME,null,$CLJS.tF,null,$CLJS.nF,null,$CLJS.yj,null,$CLJS.Nw,null,$CLJS.aF,null,$CLJS.sF,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(aW(a))?(a=$CLJS.z(a),EW.h?EW.h(a):EW.call(null,
a)):null},zna=new $CLJS.h(null,8,[$CLJS.Qi,TV,$CLJS.hP,function(a){a=UV(a);return $CLJS.y($CLJS.EP.h(a))?$CLJS.dk.j(a,$CLJS.EP,bna):a},$CLJS.CE,new $CLJS.h(null,6,[$CLJS.WE,function FW(a){if($CLJS.n($CLJS.vz($CLJS.be,$CLJS.Va)(a)))return TV(a);if($CLJS.n(bW(xW,a))){a=$CLJS.y(a);$CLJS.z(a);var c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[xW,FW.h?FW.h(a):FW.call(null,a)],null),c)}return $CLJS.n($CLJS.n(aW(a))?EW($CLJS.dd(a)):null)?$CLJS.ck.g(FW,a):cW.g?cW.g(a,
dW):cW.call(null,a,dW)},$CLJS.YO,function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[XV(u),cW.g?cW.g(t,dW):cW.call(null,t,dW)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=
$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[XV(l),cW.g?cW.g(f,dW):cW.call(null,f,dW)],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())},$CLJS.MQ,function(a){return $CLJS.yf(function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m);t=$CLJS.n(aW(t))?DW.h(t):$CLJS.Zd(DW.h($CLJS.Zd(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}l=$CLJS.z(e);return $CLJS.ae($CLJS.n(aW(l))?DW.h(l):$CLJS.Zd(DW.h($CLJS.Zd(l))),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())},$CLJS.GP,function(a){a=UV(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.hP);if($CLJS.n(b))return a=$CLJS.xU(a,new $CLJS.h(null,1,[$CLJS.hP,$CLJS.CE],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.hP],null),a=cW.g?cW.g(a,b):cW.call(null,a,b),$CLJS.xU(a,new $CLJS.h(null,1,[$CLJS.CE,$CLJS.hP],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.CE],null);return cW.g?cW.g(a,b):cW.call(null,a,b)},$CLJS.QN,new $CLJS.h(null,1,[vW,fW],null),$CLJS.CM,new $CLJS.h(null,1,[vW,function(a){a=cW.g?cW.g(a,$CLJS.CE):cW.call(null,a,$CLJS.CE);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.zD),d=$CLJS.J.g(b,$CLJS.OD);a=$CLJS.J.g(b,$CLJS.SD);b=$CLJS.n(c)?$CLJS.dk.j(b,$CLJS.zD,TV):b;d=$CLJS.n($CLJS.vz($CLJS.be,$CLJS.Va)(d))?$CLJS.dk.j(b,$CLJS.OD,TV):b;return $CLJS.n(a)?$CLJS.dk.j(d,$CLJS.SD,XV):d}],null)],null),$CLJS.Cx,
new $CLJS.h(null,1,[$CLJS.nN,$CLJS.Pd],null),$CLJS.tM,new $CLJS.h(null,1,[vW,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Qi),d=$CLJS.J.g(b,$CLJS.jI),e=$CLJS.J.g(b,$CLJS.Fi);a=$CLJS.J.g(b,yW);b=$CLJS.n(e)?$CLJS.dk.j(b,$CLJS.Fi,XV):b;c=$CLJS.n(c)?$CLJS.dk.j(b,$CLJS.Qi,TV):b;d=$CLJS.n(d)?$CLJS.dk.j(c,$CLJS.jI,function(f){return cW.g?cW.g(f,dW):cW.call(null,f,dW)}):c;d=$CLJS.n(a)?$CLJS.tW.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[yW,rna],null),function(f){return cW.g?cW.g(f,dW):cW.call(null,f,dW)}):d;return $CLJS.n(a)?
$CLJS.tW.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[yW,nna],null),function(f){return cW.g?cW.g(f,dW):cW.call(null,f,dW)}):d}],null),$CLJS.oM,function(a){return null==a?null:TV(a)},$CLJS.QN,new $CLJS.h(null,1,[vW,fW],null),CW,TV],null),cW=function cW(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return cW.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
cW.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.yf(b);b=$CLJS.y(c)?$CLJS.xz(zna,c):null;try{return $CLJS.id(b)?b.h?b.h(a):b.call(null,a):$CLJS.td(a)?a:$CLJS.sd(a)?$CLJS.Wf.g($CLJS.N,function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);A=TV(A);A=new $CLJS.P(null,
2,5,$CLJS.Q,[A,cW.l(x,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}m=$CLJS.z(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=TV(u);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[u,cW.l(m,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),u)]))],null),k($CLJS.Hc(l)))}return null}},null,null)}(a)}()):$CLJS.n(aW(a))?DW.h(a):$CLJS.rd(a)?$CLJS.ck.g(function(e){return cW.l(e,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),vW)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.Th($CLJS.eD("Error normalizing form: {0}",$CLJS.H([$CLJS.rU(d)])),new $CLJS.h(null,3,[$CLJS.Bi,a,$CLJS.Ik,c,qna,b],null),d);}throw e;}};cW.A=1;cW.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
var hW=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(aW(f))?$CLJS.z(f):null},e,a,b,c,d)}();hW.m(null,$CLJS.Oh,function(a){return a});
hW.m(null,$CLJS.gF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(bW($CLJS.gF,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return hW.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,c,$CLJS.Be($CLJS.sk.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,b,$CLJS.Be(a)],null)});
hW.m(null,$CLJS.WE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.od(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WE,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,b,a],null)});hW.m(null,$CLJS.QJ,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(aW(a))?hW.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,a,null],null)});
hW.m(null,BW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,b,new $CLJS.h(null,1,[$CLJS.pA,a],null)],null)});hW.m(null,sna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=iW(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=iW(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,a,$CLJS.R.j(c,$CLJS.DM,b)],null)});
hW.m(null,tna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=iW(a);return $CLJS.Ne.v($CLJS.FU,a,$CLJS.R,$CLJS.H([$CLJS.sO,b]))});
hW.m(null,AW,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=iW(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.pA);$CLJS.Sa(d)||$CLJS.IR.g(d,b)?a=$CLJS.Ne.v($CLJS.FU,a,$CLJS.R,$CLJS.H([$CLJS.mF,b])):(c=$CLJS.Iz($CLJS.Ax),$CLJS.n($CLJS.Hz("metabase.mbql.util",c))&&(b=$CLJS.eD("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.xh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.Gz("metabase.mbql.util",c,$CLJS.tv(),b):$CLJS.Gz("metabase.mbql.util",c,$CLJS.tv.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),hW.h(new $CLJS.P(null,3,5,$CLJS.Q,[AW,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
hW.m(null,$CLJS.AU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=iW(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,b,$CLJS.R.j(e,$CLJS.sQ,$CLJS.sk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.zD,c],null),$CLJS.n(d)?$CLJS.Ae([c,d]):null,a])))],null)});
for(var GW=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.$q,$CLJS.br],null)),HW=null,IW=0,JW=0;;)if(JW<IW){var KW=HW.X(null,JW);hW.m(null,KW,function(){return function(a){return jW(a)}}(GW,HW,IW,JW,KW));JW+=1}else{var LW=$CLJS.y(GW);if(LW){var MW=LW;if($CLJS.vd(MW)){var NW=$CLJS.ic(MW),Ana=$CLJS.jc(MW),Bna=NW,Cna=$CLJS.D(NW);GW=Ana;HW=Bna;IW=Cna}else{var OW=$CLJS.z(MW);hW.m(null,OW,function(){return function(a){return jW(a)}}(GW,HW,IW,JW,OW,MW,LW));GW=$CLJS.B(MW);HW=null;IW=0}JW=0}else break}
hW.m(null,$CLJS.OE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OE,iW(a),iW(b)],null),c)});hW.m(null,$CLJS.bB,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=iW(a);a=$CLJS.n(YV($CLJS.gF,a))?$CLJS.FU.l(c,$CLJS.Tj,$CLJS.H([$CLJS.mF])):c;return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bB,a],null),b)});
for(var PW=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.YE,$CLJS.rF,$CLJS.fF,$CLJS.ZE,$CLJS.Cq,$CLJS.wF,$CLJS.zq,$CLJS.Bq,$CLJS.vq,$CLJS.xq,$CLJS.JE,$CLJS.QE,$CLJS.HE,$CLJS.TE,$CLJS.IE],null)),QW=null,RW=0,SW=0;;)if(SW<RW){var TW=QW.X(null,SW);hW.m(null,TW,function(){return function(a){return kW(a)}}(PW,QW,RW,SW,TW));SW+=1}else{var UW=$CLJS.y(PW);if(UW){var VW=UW;if($CLJS.vd(VW)){var WW=$CLJS.ic(VW),Dna=$CLJS.jc(VW),Ena=WW,Fna=$CLJS.D(WW);PW=Dna;QW=Ena;RW=Fna}else{var XW=$CLJS.z(VW);hW.m(null,XW,
function(){return function(a){return kW(a)}}(PW,QW,RW,SW,XW,VW,UW));PW=$CLJS.B(VW);QW=null;RW=0}SW=0}else break}hW.m(null,pna,function(){return null});hW.m(null,$CLJS.tL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tL,hW.h(b),a],null)});
hW.m(null,xW,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=hW.h;var e=$CLJS.Q;b=hW.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,ona)?new $CLJS.h(null,1,[$CLJS.U,a],null):new $CLJS.h(null,1,[$CLJS.jD,a],null);return c.call(hW,new $CLJS.P(null,3,5,e,[$CLJS.tL,b,a],null))});
for(var YW=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nw,$CLJS.hF],null)),ZW=null,$W=0,aX=0;;)if(aX<$W){var bX=ZW.X(null,aX);hW.m(null,bX,function(){return function(a){return lW(a)}}(YW,ZW,$W,aX,bX));aX+=1}else{var cX=$CLJS.y(YW);if(cX){var dX=cX;if($CLJS.vd(dX)){var eX=$CLJS.ic(dX),Gna=$CLJS.jc(dX),Hna=eX,Ina=$CLJS.D(eX);YW=Gna;ZW=Hna;$W=Ina}else{var fX=$CLJS.z(dX);hW.m(null,fX,function(){return function(a){return lW(a)}}(YW,ZW,$W,aX,fX,dX,cX));YW=$CLJS.B(dX);ZW=null;$W=0}aX=0}else break}
for(var gX=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.aF,$CLJS.BF,$CLJS.LE,$CLJS.uF,$CLJS.sF,$CLJS.bn,$CLJS.yj,$CLJS.tF,$CLJS.ME],null)),hX=null,iX=0,jX=0;;)if(jX<iX){var kX=hX.X(null,jX);hW.m(null,kX,function(){return function(a){return mW(a)}}(gX,hX,iX,jX,kX));jX+=1}else{var lX=$CLJS.y(gX);if(lX){var mX=lX;if($CLJS.vd(mX)){var nX=$CLJS.ic(mX),Jna=$CLJS.jc(mX),Kna=nX,Lna=$CLJS.D(nX);gX=Jna;hX=Kna;iX=Lna}else{var oX=$CLJS.z(mX);hW.m(null,oX,function(){return function(a){return mW(a)}}(gX,hX,iX,jX,
oX,mX,lX));gX=$CLJS.B(mX);hX=null;iX=0}jX=0}else break}hW.m(null,$CLJS.SE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SE,iW(b),a],null)});
for(var pX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nF,$CLJS.pF],null)),qX=null,rX=0,sX=0;;)if(sX<rX){var tX=qX.X(null,sX);hW.m(null,tX,function(){return function(a){return nW(a)}}(pX,qX,rX,sX,tX));sX+=1}else{var uX=$CLJS.y(pX);if(uX){var vX=uX;if($CLJS.vd(vX)){var wX=$CLJS.ic(vX),Mna=$CLJS.jc(vX),Nna=wX,Ona=$CLJS.D(wX);pX=Mna;qX=Nna;rX=Ona}else{var xX=$CLJS.z(vX);hW.m(null,xX,function(){return function(a){return nW(a)}}(pX,qX,rX,sX,xX,vX,uX));pX=$CLJS.B(vX);qX=null;rX=0}sX=0}else break}
hW.m(null,$CLJS.zF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zF,hW.h(b),hW.h(a)],null)});
hW.m(null,$CLJS.PE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.Yd.g(hW.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PE,b],null)),cW.l(a,$CLJS.H([dW]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PE,$CLJS.yf(function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[hW.h(v),hW.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[hW.h(m),hW.h(k)],null),e($CLJS.Hc(f)))}return null}},null,null)}(b)}())],null)});
hW.m(null,$CLJS.yE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yE,hW.h(a),$CLJS.E.g(0,b)?1:hW.h(b)],null),$CLJS.cf.g(hW,c))});
var eW=function eW(a){if($CLJS.td(a))return $CLJS.Od(function(d,e,f){return $CLJS.R.j(d,e,eW.h?eW.h(f):eW.call(null,f))},a,a);if($CLJS.sd(a))return $CLJS.cs(a,eW);if($CLJS.n(aW(a))){var c=function(){try{return hW.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.Iz($CLJS.yx);if($CLJS.n($CLJS.Hz("metabase.mbql.normalize",e))){var f=$CLJS.GD("Invalid clause:");f instanceof Error?$CLJS.Gz("metabase.mbql.normalize",e,$CLJS.tv.l($CLJS.H([a])),f):$CLJS.Gz("metabase.mbql.normalize",e,$CLJS.tv.l($CLJS.H([f,
a])),null)}throw $CLJS.Th($CLJS.eD("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.rU(d)])),new $CLJS.h(null,1,[$CLJS.XQ,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.Wf.j($CLJS.Yd.g($CLJS.gd(c),$CLJS.z(c)),$CLJS.cf.h(eW),$CLJS.Hc(c)):c}return $CLJS.yd(a)?$CLJS.ck.g(eW,a):$CLJS.pd(a)?$CLJS.Wf.j($CLJS.gd(a),$CLJS.cf.h(eW),a):a},oW=function oW(a){return function f(d,e){try{if($CLJS.n(function(){var x=VV($CLJS.yd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Z)try{if($CLJS.n(function(){var A=VV($CLJS.be);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Z;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.Z)try{if($CLJS.ud(e)&&2<=$CLJS.D(e))try{var k=$CLJS.hk.j(e,0,2);if($CLJS.ud(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.cD($CLJS.be,$CLJS.Oe(new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.Fq,null,$CLJS.Vr,null,$CLJS.AF,null,$CLJS.Eq,null,
xW,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(EW(m)))return $CLJS.Wf.j($CLJS.xf,$CLJS.dD(oW),a);throw $CLJS.Z;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Z)try{if($CLJS.ud(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.hk.j(e,0,1);if($CLJS.ud(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw A;
}else throw d;else throw x;}}($CLJS.xf,a)},yX=function yX(a){return function f(d,e){try{var k=VV($CLJS.yd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Z)try{if($CLJS.ud(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.ce(m,$CLJS.dF)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,t],null))}throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,
$CLJS.XE))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XE,t],null));throw $CLJS.Z;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,wW))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,t],null));throw $CLJS.Z;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,zW))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XE,t],null));throw $CLJS.Z;}catch(X){if(X instanceof Error)if(m=X,m===
$CLJS.Z)try{var v=$CLJS.F(e,0);if($CLJS.ce(v,wW))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,t],null));throw $CLJS.Z;}catch(T){if(T instanceof Error)if(m=T,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,zW))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XE,t],null));throw $CLJS.Z;}catch(da){if(da instanceof Error)if(m=da,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,$CLJS.dF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,iW(t)],null);throw $CLJS.Z;}catch(oa){if(oa instanceof
Error)if(m=oa,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,$CLJS.XE))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XE,iW(t)],null);throw $CLJS.Z;}catch(Oa){if(Oa instanceof Error){t=Oa;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw Oa;}else throw m;else throw oa;}else throw m;else throw da;}else throw m;else throw T;}else throw m;else throw X;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{if($CLJS.ud(e)&&
0<=$CLJS.D(e))try{var x=$CLJS.hk.j(e,0,0);if($CLJS.ud(x)&&0===$CLJS.D(x))try{var A=$CLJS.hk.g(e,0);if($CLJS.E.g(A,a))return $CLJS.yf($CLJS.tk.h($CLJS.cf.g(yX,a)));throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)return $CLJS.WV(f,d,e);throw u;}throw K;}else throw l;else throw G;
}else throw l;else throw C;}}($CLJS.xf,a)},rW=$CLJS.ak.g(eW,function(a){var b=sW($CLJS.WE.h(a))?$CLJS.dk.j(a,$CLJS.WE,ena):a;b=sW($CLJS.VE.h(a))?$CLJS.dk.j(b,$CLJS.VE,fna):b;b=sW($CLJS.OD.h(a))?$CLJS.dk.j(b,$CLJS.OD,$CLJS.Qe($CLJS.ck,gW)):b;b=sW($CLJS.MQ.h(a))?$CLJS.dk.j(b,$CLJS.MQ,yX):b;return sW($CLJS.GP.h(a))?$CLJS.dk.j(b,$CLJS.GP,hna):b}),Pna=new $CLJS.h(null,3,[$CLJS.hP,$CLJS.Pd,$CLJS.CE,new $CLJS.h(null,2,[$CLJS.GP,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.hP);if($CLJS.n(b))return a=
$CLJS.xU(a,new $CLJS.h(null,1,[$CLJS.hP,$CLJS.CE],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.hP],null),a=uW.g?uW.g(a,b):uW.call(null,a,b),$CLJS.xU(a,new $CLJS.h(null,1,[$CLJS.CE,$CLJS.hP],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.CE],null);return uW.g?uW.g(a,b):uW.call(null,a,b)},$CLJS.CM,new $CLJS.h(null,1,[vW,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.CE],null);return uW.g?uW.g(a,b):uW.call(null,a,b)}],null)],null),CW,$CLJS.Pd],null),uW=function uW(a){switch(arguments.length){case 1:return uW.h(arguments[0]);
case 2:return uW.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};uW.h=function(a){return uW.g(a,$CLJS.xf)};uW.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.xz(Pna,b):null;return $CLJS.id(c)?c.h?c.h(a):c.call(null,a):$CLJS.td(a)?a:$CLJS.sd(a)?lna(a,b):$CLJS.rd(a)?mna(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.Th("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.Bi,a,$CLJS.Ik,b],null),d);throw d;}};uW.A=2;
$CLJS.zX=function(){var a=$CLJS.ak.l(uW,kna,ina,$CLJS.H([cW]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.Th($CLJS.eD("Error normalizing query: {0}",$CLJS.H([$CLJS.rU(c)])),new $CLJS.h(null,1,[$CLJS.CE,b],null),c);}throw d;}}}();$CLJS.AX=function AX(a,b){if($CLJS.Sa($CLJS.y(a)))a=(0,$CLJS.zX)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.as(a);b=$CLJS.Ae([$CLJS.fd(a),b]);f=AX.g?AX.g(f,b):AX.call(null,f,b);a=e.call(d,f,$CLJS.fd(a))}return a};