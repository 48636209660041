var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var T6,U6,V6,mta,nta,ota,Y6,Z6,a7,b7,pta,qta,rta,d7,e7,f7,sta,tta;T6=function(a){var b=new $CLJS.h(null,3,[$CLJS.vD,$CLJS.p.h($CLJS.FD()),$CLJS.pA,$CLJS.pA.h(a),$CLJS.mi,$CLJS.vz($CLJS.mi,$CLJS.pA)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mA,b,$CLJS.vz($CLJS.nD,$CLJS.U)(a)],null)};U6=function(a){return $CLJS.R.j(a,$CLJS.Rs,$CLJS.l4)};V6=function(a,b){return $CLJS.E.g($CLJS.iD.h(a),$CLJS.wl(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zD,$CLJS.hD,$CLJS.yD],null)))};
mta=function(a){return $CLJS.J0(a,new $CLJS.h(null,1,[$CLJS.zD,$CLJS.mh],null))};
nta=function(a,b){var c=$CLJS.Me(function(e){return $CLJS.nG(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HY,$CLJS.U],null));if($CLJS.n(c))return c;c=$CLJS.Iz($CLJS.Ax);if($CLJS.n($CLJS.Hz("metabase.lib.field",c))){var d=$CLJS.eD("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([a])),$CLJS.xh.l($CLJS.H([$CLJS.ck.g($CLJS.HY,b)]))]));d instanceof Error?$CLJS.Gz("metabase.lib.field",c,$CLJS.tv(),d):$CLJS.Gz("metabase.lib.field",
c,$CLJS.tv.l($CLJS.H([d])),null)}return null};
ota=function(a,b,c){if($CLJS.n(W6))return null;var d=W6;W6=!0;try{var e=$CLJS.B1(a,b),f=$CLJS.n(e)?$CLJS.DV(a,e):$CLJS.DV(a,b),k=function(){var m=$CLJS.X6.h(f);if($CLJS.n(m))return m;m=$CLJS.xz(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mU,$CLJS.AV],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.MU.h(f);if($CLJS.n(u))return u;u=$CLJS.dO.h(f);if($CLJS.n(u))return u;u=$CLJS.YO.h(f);return $CLJS.n(u)?u:$CLJS.OD.h(f)}())?$CLJS.R_.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.Iz($CLJS.Ax);if($CLJS.n($CLJS.Hz("metabase.lib.field",
m))){var t=$CLJS.eD("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.Gz("metabase.lib.field",m,$CLJS.tv(),t):$CLJS.Gz("metabase.lib.field",m,$CLJS.tv.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?nta(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.b3($CLJS.Tj.l(l,$CLJS.Fi,$CLJS.H([$CLJS.VJ,$CLJS.NV,$CLJS.HV])),null),$CLJS.U,function(){var m=$CLJS.HY.h(l);return $CLJS.n(m)?m:$CLJS.U.h(l)}()),
$CLJS.IJ,$CLJS.sY):l:null}finally{W6=d}};
Y6=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.sO);var f=$CLJS.I(c,2,null);c=$CLJS.sk.l($CLJS.H([function(){var k=$CLJS.pA.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.pA,k],null):null}(),function(){var k=$CLJS.vz($CLJS.mi,$CLJS.pA)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.mi,k],null):null}(),function(){var k=$CLJS.sQ.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.NV,k],null):null}(),function(){var k=$CLJS.mF.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.HV,k],null):null}(),$CLJS.Ad(f)?function(){var k=$CLJS.H1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Rs,$CLJS.GJ,$CLJS.U,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Rs,$CLJS.GJ,$CLJS.U,$CLJS.p.h(f)],null):function(){var k=ota(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Rs,$CLJS.GJ,$CLJS.U,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.b3(c,d):c};
Z6=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.HV);return $CLJS.n($CLJS.n(b)?$CLJS.Cd($CLJS.kG,b):b)?$CLJS.Xi:$CLJS.vz($CLJS.mi,$CLJS.pA)(a)};
$CLJS.$6=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.vD),l=$CLJS.J.g(f,$CLJS.pA),m=$CLJS.J.g(f,$CLJS.sQ),t=$CLJS.J.g(f,$CLJS.mi);e=$CLJS.J.g(f,$CLJS.sO);var u=$CLJS.J.g(f,$CLJS.DM),v=$CLJS.J.g(f,$CLJS.mF),x=$CLJS.sk.l;k=new $CLJS.h(null,2,[$CLJS.Rs,$CLJS.GJ,$CLJS.w0,k],null);f=$CLJS.jD.h(f);a=$CLJS.n(f)?f:$CLJS.e_.j(a,b,d);c=x.call($CLJS.sk,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.jD,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.mi,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.pA,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.HV,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.NV,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.UJ,u):m;return $CLJS.n(e)?$CLJS.b3(u,e):u};a7=function(a,b,c){return $CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.xk.h(d),c)},a))?$CLJS.ck.g(function(d){var e=$CLJS.Cd(d,b)?$CLJS.Tj.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.xk.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
b7=function(a){var b=$CLJS.n($CLJS.i1.h(a))?null:function(){var e=$CLJS.IJ.h(a),f=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.LY,null,$CLJS.SY,null,$CLJS.sY,null],null),null);return f.h?f.h(e):f.call(null,e)}(),c=$CLJS.sk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.vD,$CLJS.p.h($CLJS.FD()),$CLJS.pA,$CLJS.pA.h(a),$CLJS.mi,Z6(a)],null),function(){var e=function(){var f=$CLJS.Sa(b);return f?(f=$CLJS.Sa($CLJS.UJ.h(a)))?(f=$CLJS.Zj.g($CLJS.DY,$CLJS.IJ.h(a)))?$CLJS.WY.h(a):f:f:f}();return $CLJS.n(e)?new $CLJS.h(null,
1,[$CLJS.sO,e],null):null}(),function(){var e=$CLJS.b1(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.sO,e],null):null}(),function(){var e=$CLJS.HV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.mF,e],null):null}(),function(){var e=$CLJS.LV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.LV,e],null):null}(),function(){var e=$CLJS.NV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.sQ,e],null):null}(),function(){var e=$CLJS.UJ.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.DM,e],null):null}()])),
d=($CLJS.n(b)?$CLJS.vz($CLJS.HY,$CLJS.U):$CLJS.vz($CLJS.Fi,$CLJS.U))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,c,d],null)};pta=function(a,b){return $CLJS.ff(function(c){return $CLJS.E.g($CLJS.IJ.h(c),$CLJS.BY)},$CLJS.R_.v(a,b,$CLJS.DV(a,b),new $CLJS.h(null,3,[$CLJS.z_,!1,$CLJS.F_,!0,$CLJS.u_,!1],null)))};qta=new $CLJS.M("lib","simple-name","lib/simple-name",-734346338);rta=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);
$CLJS.c7=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);d7=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);e7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.X6=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);f7=new $CLJS.M("metabase.lib.field","simple-display-name","metabase.lib.field/simple-display-name",494797345);
sta=new $CLJS.M(null,"earliest","earliest",-1928154382);tta=new $CLJS.M(null,"latest","latest",24323665);$CLJS.K0.m(null,$CLJS.gF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mh.h(b),$CLJS.J0(c,new $CLJS.h(null,2,[$CLJS.mF,$CLJS.mh,$CLJS.sQ,mta],null)),a],null)});
var W6=!1,g7=function g7(a,b){var d=$CLJS.T0(a,e7.h(b));a=$CLJS.n(e7.h(d))?g7.g?g7.g(a,d):g7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.U),f=$CLJS.J.g(a,$CLJS.jD);return $CLJS.dk.j($CLJS.R.j($CLJS.dk.j($CLJS.R.j(b,qta,$CLJS.U.h(b)),$CLJS.U,function(k){return[$CLJS.p.h(e),".",$CLJS.p.h(k)].join("")}),f7,$CLJS.jD.h(b)),$CLJS.jD,function(k){return[$CLJS.p.h(f),": ",$CLJS.p.h(k)].join("")})};$CLJS.K_.m(null,$CLJS.GJ,function(a,b,c){return Z6(c)});
$CLJS.K_.m(null,$CLJS.gF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.mF);$CLJS.I(c,2,null);c=Y6(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.HV,d):c;return $CLJS.L_.j(a,b,c)});$CLJS.M_.m(null,$CLJS.GJ,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);return $CLJS.R.j(a,$CLJS.U,b)});$CLJS.M_.m(null,$CLJS.gF,function(a,b,c){var d=Y6(a,b,c);b=$CLJS.$6(a,b,d,c);return $CLJS.n(e7.h(b))?g7(a,b):b});
$CLJS.G_.m(null,$CLJS.GJ,function(a,b,c,d){function e(T){var da=$CLJS.S4(l,k);return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}function f(T){var da=$CLJS.Zz($CLJS.Cz($CLJS.Xg(u),"-"," "));return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}var k=$CLJS.O(c),l=$CLJS.J.g(k,$CLJS.NV),m=$CLJS.J.g(k,$CLJS.UJ),t=$CLJS.J.g(k,$CLJS.VJ),u=$CLJS.J.g(k,$CLJS.xk),v=$CLJS.J.g(k,$CLJS.WY),x=$CLJS.J.g(k,f7),A=$CLJS.J.g(k,$CLJS.U),C=$CLJS.J.g(k,$CLJS.r5),G=$CLJS.J.g(k,
$CLJS.jD),K=$CLJS.J.g(k,e7),S=$CLJS.$0.g($CLJS.uA,A);c=function(){if($CLJS.n(x))return x;if($CLJS.n($CLJS.n(K)?null==G||$CLJS.E.g(G,S):K)){a:{var T=$CLJS.O(k);var da=$CLJS.J.g(T,$CLJS.jD);T=$CLJS.J.g(T,e7);for(da=new $CLJS.Vd(null,da,null,1,null);;)if($CLJS.n(T)){T=$CLJS.T0(a,T);var oa=$CLJS.O(T);T=$CLJS.J.g(oa,$CLJS.jD);oa=$CLJS.J.g(oa,e7);da=$CLJS.Yd.g(da,T);T=oa}else break a}da=$CLJS.Le($CLJS.Ta,da)?$CLJS.ds(": ",da):null}else da=null;return $CLJS.n(da)?da:$CLJS.n(G)?G:"string"===typeof A?S:$CLJS.p.h(A)}();
var X=$CLJS.E.g(d,$CLJS.f_)&&-1==c.indexOf(" → ")?function(){if($CLJS.n(m)){var T=$CLJS.T0(a,m);$CLJS.n(T)?T=$CLJS.Y2($CLJS.jD.h($CLJS.h_.j(a,b,T))):(T=$CLJS.a1(a,t),T=$CLJS.e_.v(a,b,T,d))}else T=null;return $CLJS.n(T)?T:$CLJS.n(v)?v:$CLJS.b1(k)}():null;c=$CLJS.n(X)?[$CLJS.p.h(X)," → ",$CLJS.p.h(c)].join(""):c;return $CLJS.n(function(){var T=$CLJS.Zj.g(d,$CLJS.f_);return T?C:T}())?c:$CLJS.n($CLJS.n(u)?$CLJS.Zj.g(c,f(S)):u)?f(c):$CLJS.n($CLJS.n(l)?$CLJS.Zj.g(c,e(S)):l)?e(c):c});
$CLJS.G_.m(null,$CLJS.gF,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.sQ);var k=$CLJS.J.g(f,$CLJS.sO),l=$CLJS.J.g(f,$CLJS.mF);f=$CLJS.J.g(f,$CLJS.DM);$CLJS.I(c,2,null);c=Y6(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.WY,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.xk,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.NV,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.UJ,f):e;return $CLJS.n(e)?$CLJS.e_.v(a,b,e,d):$CLJS.GD("[Unknown Field]")});
$CLJS.H_.m(null,$CLJS.GJ,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.U)});$CLJS.H_.m(null,$CLJS.gF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=Y6(a,b,c);return $CLJS.n(c)?$CLJS.I_.j(a,b,c):"unknown_field"});
$CLJS.N_.m(null,$CLJS.GJ,function(a,b,c){return $CLJS.sk.l($CLJS.H([function(){var d=$CLJS.gH($CLJS.N_,$CLJS.Oh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),new $CLJS.h(null,2,[$CLJS.jD,$CLJS.e_.j(a,b,c),$CLJS.g_,$CLJS.e_.v(a,b,c,$CLJS.f_)],null),$CLJS.E.g($CLJS.IJ.h(c),$CLJS.LY)?function(){var d=$CLJS.VY.h(c);return $CLJS.n(d)?(d=$CLJS.$Z(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.MV,new $CLJS.h(null,2,[$CLJS.U,$CLJS.U.h(d),$CLJS.jD,$CLJS.U.h(d)],null)],null):null):null}():null]))});
$CLJS.n0.m(null,$CLJS.gF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.mF.h(b)});$CLJS.n0.m(null,$CLJS.GJ,function(a){return $CLJS.HV.h(a)});
$CLJS.m0.m(null,$CLJS.gF,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Cd($CLJS.kG,b),e=$CLJS.oB($CLJS.LV,$CLJS.mi,$CLJS.pA)(c);c=$CLJS.R.l(c,$CLJS.mF,b,$CLJS.H([$CLJS.mi,d?$CLJS.Xi:e,$CLJS.LV,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,c,a],null)}b=$CLJS.LV.h(c);c=$CLJS.n(b)?$CLJS.Tj.g($CLJS.R.j(c,$CLJS.mi,b),$CLJS.LV):c;c=$CLJS.Tj.g(c,$CLJS.mF);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,c,a],null)});
$CLJS.m0.m(null,$CLJS.GJ,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.HV,b,$CLJS.H([$CLJS.LV,$CLJS.oB($CLJS.LV,$CLJS.mi,$CLJS.pA)(a)])):$CLJS.Tj.l(a,$CLJS.HV,$CLJS.H([$CLJS.LV]))});$CLJS.p0.m(null,$CLJS.gF,function(a,b,c){return $CLJS.q0.j(a,b,Y6(a,b,c))});
$CLJS.p0.m(null,$CLJS.GJ,function(a,b,c){if($CLJS.Zj.g($CLJS.IJ.h(c),$CLJS.BY)){a=$CLJS.vz($CLJS.mi,$CLJS.pA)(c);b=null==c?null:$CLJS.RM.h(c);if(null==b)var d=null;else try{var e=$CLJS.Ak.h($CLJS.Qi.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,sta),l=$CLJS.J.g(f,tta),m=$CLJS.npa.l($CLJS.H([$CLJS.k0.h(k),$CLJS.k0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.wq.g?$CLJS.wq.g(1,m):$CLJS.wq.call(null,1,m))?$CLJS.zi:$CLJS.n($CLJS.wq.g?$CLJS.wq.g(31,m):$CLJS.wq.call(null,31,m))?$CLJS.Fj:$CLJS.n($CLJS.wq.g?
$CLJS.wq.g(365,m):$CLJS.wq.call(null,365,m))?$CLJS.Si:$CLJS.Yi}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.zz(a,$CLJS.Ak)?$CLJS.ypa:$CLJS.zz(a,$CLJS.yk)?$CLJS.xpa:$CLJS.zz(a,$CLJS.Fk)?$CLJS.wpa:$CLJS.xf;d=$CLJS.n(d)?a7(e,$CLJS.Oh,d):e;return $CLJS.n($CLJS.HV.h(c))?a7(d,$CLJS.m_,$CLJS.HV.h(c)):d}return $CLJS.xf});
$CLJS.P4.m(null,$CLJS.gF,function(a){var b=null==a?null:$CLJS.CD(a);b=null==b?null:$CLJS.sQ.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Rs,$CLJS.U4,$CLJS.H([$CLJS.T4,function(c,d){return Y6(c,d,a)}]))});$CLJS.P4.m(null,$CLJS.GJ,function(a){var b=null==a?null:$CLJS.NV.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Rs,$CLJS.U4,$CLJS.H([$CLJS.T4,$CLJS.Pe(a)]))});$CLJS.V4.m(null,$CLJS.gF,function(a,b){return $CLJS.DD(a,$CLJS.BD,$CLJS.H([$CLJS.sQ,b]))});
$CLJS.V4.m(null,$CLJS.GJ,function(a,b){return $CLJS.BD(a,$CLJS.NV,b)});$CLJS.W4.m(null,$CLJS.gF,function(a,b,c){return $CLJS.X4.j(a,b,Y6(a,b,c))});
$CLJS.W4.m(null,$CLJS.GJ,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.mi);var d=$CLJS.J.g(b,$CLJS.RM),e=$CLJS.J.g(b,$CLJS.pj);if($CLJS.Zj.g($CLJS.IJ.h(b),$CLJS.BY)){var f=function(){var m=null==a?null:$CLJS.S0(a);m=null==m?null:$CLJS.$y.h(m);return null==m?null:$CLJS.Cd(m,$CLJS.sQ)}(),k=$CLJS.xz(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,$CLJS.lj],null)),l=$CLJS.Q4(b);return function u(t){return new $CLJS.ie(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.vd(v)){var x=$CLJS.ic(v),A=$CLJS.D(x),
C=$CLJS.le(A);return function(){for(var K=0;;)if(K<A){var S=$CLJS.hd(x,K),X=C,T=S;S=V6(S,l)?$CLJS.R.j(T,$CLJS.m_,!0):T;X.add(S);K+=1}else return!0}()?$CLJS.oe($CLJS.qe(C),u($CLJS.jc(v))):$CLJS.oe($CLJS.qe(C),null)}var G=$CLJS.z(v);return $CLJS.ae(function(){var K=G;return V6(G,l)?$CLJS.R.j(K,$CLJS.m_,!0):K}(),u($CLJS.Hc(v)))}return null}},null,null)}($CLJS.Sa(function(){if($CLJS.n(f)){var t=$CLJS.bn.h(k);return $CLJS.n(t)?$CLJS.yj.h(k):t}return f}())?null:$CLJS.zz(e,$CLJS.Zi)?$CLJS.ck.g(U6,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.R4(),new $CLJS.h(null,2,[$CLJS.jD,$CLJS.GD("Bin every 0.1 degrees"),$CLJS.iD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.yD,$CLJS.yD,.1],null)],null),new $CLJS.h(null,2,[$CLJS.jD,$CLJS.GD("Bin every 1 degree"),$CLJS.iD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.yD,$CLJS.yD,1],null)],null),new $CLJS.h(null,2,[$CLJS.jD,$CLJS.GD("Bin every 10 degrees"),$CLJS.iD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.yD,$CLJS.yD,10],null)],null),new $CLJS.h(null,2,[$CLJS.jD,$CLJS.GD("Bin every 20 degrees"),$CLJS.iD,new $CLJS.h(null,
2,[$CLJS.zD,$CLJS.yD,$CLJS.yD,20],null)],null)],null)):$CLJS.zz(c,$CLJS.lj)&&!$CLJS.zz(e,$CLJS.Zh)?$CLJS.ck.g(U6,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.R4(),new $CLJS.h(null,2,[$CLJS.jD,$CLJS.GD("10 bins"),$CLJS.iD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.hD,$CLJS.hD,10],null)],null),new $CLJS.h(null,2,[$CLJS.jD,$CLJS.GD("50 bins"),$CLJS.iD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.hD,$CLJS.hD,50],null)],null),new $CLJS.h(null,2,[$CLJS.jD,$CLJS.GD("100 bins"),$CLJS.iD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.hD,$CLJS.hD,
100],null)],null)],null)):null)}return $CLJS.xf});$CLJS.mV.m(null,$CLJS.gF,function(a){return a});
$CLJS.mV.m(null,$CLJS.GJ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.IJ);switch(b instanceof $CLJS.M?b.S:null){case "source/aggregations":return b=new $CLJS.h(null,3,[$CLJS.vD,$CLJS.p.h($CLJS.FD()),$CLJS.mi,$CLJS.vz($CLJS.mi,$CLJS.pA)(a),$CLJS.xK,$CLJS.U.h(a)],null),a=$CLJS.w0.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,b,a],null);case "source/expressions":return T6(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.nD.h(a))?T6(a):b7(a);default:return b7(a)}});
$CLJS.uta=function(){function a(e,f,k){k=$CLJS.Be($CLJS.ck.g($CLJS.oV,k));var l=pta(e,f),m=$CLJS.Wf.j($CLJS.Sg,$CLJS.ol(function(t){return $CLJS.N1.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.xf);m=$CLJS.bk.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.cf.h($CLJS.oV),m):null;return $CLJS.PV.l(e,f,$CLJS.BD,$CLJS.H([$CLJS.OD,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.h7=function(){function a(d,e){return $CLJS.OD.h($CLJS.DV(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.vta=function(){function a(d,e){var f=$CLJS.R_.v(d,e,$CLJS.DV(d,e),new $CLJS.h(null,3,[$CLJS.z_,!1,$CLJS.F_,!1,$CLJS.u_,!1],null)),k=$CLJS.h7.g(d,e);return $CLJS.od(k)?$CLJS.ck.g(function(l){return $CLJS.R.j(l,$CLJS.JY,!0)},f):$CLJS.$2(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Y(d7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Xq,$CLJS.bj,$CLJS.vY,$CLJS.Zy],null));
$CLJS.Y(rta,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.c7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,d7],null)],null)],null));