var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./metabase.util.malli.registry.js");
'use strict';var GU,IU,JU,zma,NU,OU,PU,Bma,QU,RU,SU,UU,VU,WU,XU,YU,ZU,aV,bV,cV,dV,eV,hV,iV,Ama;GU=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.bk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.he(c)}),a)};
$CLJS.HU=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.Ne.g($CLJS.vB,function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){var k=f;if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v);$CLJS.pe(t,$CLJS.ED(x,function(){return function(A){return $CLJS.Tj.l(GU(A),$CLJS.pA,$CLJS.H([$CLJS.mi]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),e($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k);return $CLJS.ae($CLJS.ED(u,function(){return function(v){return $CLJS.Tj.l(GU(v),$CLJS.pA,$CLJS.H([$CLJS.mi]))}}(u,k,f,b)),e($CLJS.Hc(k)))}return null}},null,null)}(a)}())};
IU=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xf,a],null)],null);for(var c=$CLJS.xf;;){var d=$CLJS.md(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null),l=e,m=$CLJS.nd(a);a=function(t,u,v,x,A,C,G,K){return function(S){return $CLJS.Wf.j(v,$CLJS.cf.h(function(X,T,da,oa,Oa){return function(pb){var Ra=$CLJS.I(pb,0,null);pb=$CLJS.I(pb,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yd.g(Oa,Ra),pb],null)}}(t,u,v,x,A,C,G,K)),S)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.Yd.g(c,l)):a=$CLJS.sd(k)?a(k):$CLJS.rd(k)?a($CLJS.vl($CLJS.vr,k)):m}else return c}};JU=function(a,b,c){return $CLJS.ud(c)&&$CLJS.E.g(a,$CLJS.z(c))&&!$CLJS.Cd(b,$CLJS.J.g(c,2))};$CLJS.KU=function(a){var b=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.ak.g($CLJS.nD,$CLJS.dd)),$CLJS.YO.h(a));return IU($CLJS.Tj.l(a,$CLJS.CM,$CLJS.H([$CLJS.mU])),function(c){return JU($CLJS.mA,b,c)})};
$CLJS.LU=function(a){var b=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.ak.g($CLJS.vD,$CLJS.dd)),$CLJS.WE.h(a));return IU($CLJS.Tj.l(a,$CLJS.CM,$CLJS.H([$CLJS.mU])),function(c){return JU($CLJS.WE,b,c)})};zma=function(a){function b(d){return $CLJS.df(c,$CLJS.H([$CLJS.CM.h(d)]))}function c(d){return $CLJS.ae($CLJS.SD.h(d),$CLJS.df(b,$CLJS.H([$CLJS.mK.h(d)])))}return $CLJS.n($CLJS.MU.h(a))?$CLJS.Pe(!0):$CLJS.Wg(b(a))};
NU=function(a){if($CLJS.rd(a))for(var b=$CLJS.Pe(!1),c=$CLJS.y(a),d=$CLJS.z(c),e=$CLJS.B(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.y(u),x=$CLJS.z(v),A=$CLJS.B(v),C=x,G=A,K=$CLJS.vz(m,zma(C));f=$CLJS.z($CLJS.y($CLJS.ff($CLJS.Ta,function(S,X,T,da,oa,Oa){return function cb(Ra,Wa){try{if($CLJS.ud(Wa)&&3===$CLJS.D(Wa))try{var Ka=$CLJS.F(Wa,0);if($CLJS.ce(Ka,$CLJS.gF))try{var kb=$CLJS.F(Wa,1);if($CLJS.n($CLJS.sO.h(kb)))try{var Ja=$CLJS.F(Wa,1);if(null!=Ja?Ja.C&256||$CLJS.yc===Ja.mf||(Ja.C?0:$CLJS.Xa($CLJS.ub,
Ja)):$CLJS.Xa($CLJS.ub,Ja))try{var bc=$CLJS.J.j(Ja,$CLJS.sO,$CLJS.BU);if($CLJS.n($CLJS.Oe(da)(bc))){var Jd=$CLJS.J.g(Ja,$CLJS.sO);$CLJS.F(Wa,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(Oa),": no join named ",$CLJS.xh.l($CLJS.H([Jd]))].join("")],null)}throw $CLJS.Z;}catch(ed){if(ed instanceof Error){var Fd=ed;if(Fd===$CLJS.Z)throw $CLJS.Z;throw Fd;}throw ed;}else throw $CLJS.Z;}catch(ed){if(ed instanceof Error){Fd=ed;if(Fd===$CLJS.Z)throw $CLJS.Z;throw Fd;
}throw ed;}else throw $CLJS.Z;}catch(ed){if(ed instanceof Error){Fd=ed;if(Fd===$CLJS.Z)throw $CLJS.Z;throw Fd;}throw ed;}else throw $CLJS.Z;}catch(ed){if(ed instanceof Error){Fd=ed;if(Fd===$CLJS.Z)throw $CLJS.Z;throw Fd;}throw ed;}else throw $CLJS.Z;}catch(ed){if(ed instanceof Error){Fd=ed;if(Fd===$CLJS.Z)return $CLJS.yU(cb,Ra,Wa);throw Fd;}throw ed;}}}(f,k,l,K,m,t,u,v,x,A,C,G,b,0,a,a,c,d,e,d,e)($CLJS.xf,$CLJS.Tj.l(C,$CLJS.CM,$CLJS.H([Ama]))))));if($CLJS.n(f))return f;if($CLJS.y(G))k=t+1,f=K,l=G;
else return null}else return null};OU=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);PU=new $CLJS.r("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);Bma=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);QU=new $CLJS.r(null,"distinct-refs?","distinct-refs?",-2065255505,null);
RU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);SU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.TU=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);UU=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
VU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);WU=new $CLJS.r(null,"refs","refs",80480079,null);XU=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);YU=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);ZU=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.$U=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);aV=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);bV=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);cV=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);dV=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
eV=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.fV=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.MU=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.gV=new $CLJS.M(null,"filters","filters",974726919);hV=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);iV=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.jV=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Ama=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.Y(XU,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.$U],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hP,$CLJS.Ta],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gr,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.Vk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kN,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.oD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EP,new $CLJS.h(null,1,[$CLJS.wr,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eU],null)],null)],null));$CLJS.Y(bV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null));
$CLJS.Y(OU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.bn,1],null),bV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.ps,"Breakouts must be distinct"],null),new $CLJS.Cc(function(){return $CLJS.HU},PU,$CLJS.zg([$CLJS.Ci,$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[$CLJS.qU,QU,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[WU],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.HU)?$CLJS.HU.H:null]))],null)],null));
$CLJS.Y(cV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.bn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.ps,":fields must be distinct"],null),new $CLJS.Cc(function(){return $CLJS.HU},PU,$CLJS.zg([$CLJS.Ci,$CLJS.U,$CLJS.Dj,$CLJS.di,$CLJS.yi,$CLJS.Ri,$CLJS.Kj,$CLJS.ci,$CLJS.zj,$CLJS.Cj],[$CLJS.qU,QU,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.P(null,
1,5,$CLJS.Q,[WU],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.HU)?$CLJS.HU.H:null]))],null)],null));$CLJS.Y(dV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.EE],null),$CLJS.xj,$CLJS.sj],null)],null));
$CLJS.Y(aV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.bn,1],null),dV],null));
var kV=$CLJS.vz(function(a){a=$CLJS.z($CLJS.KU(a));if($CLJS.n(a)){var b=$CLJS.xz(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.xh.l($CLJS.H([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null},function(a){a=$CLJS.z($CLJS.LU(a));if($CLJS.n(a)){var b=$CLJS.xz(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null});$CLJS.Y(eV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,2,[$CLJS.ps,"Valid references for a single query stage",$CLJS.As,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.cj);return kV(a)}],null),$CLJS.Oe(kV)],null));
$CLJS.Y($CLJS.jV,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.TU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CM,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YO,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.VE,new $CLJS.h(null,1,[$CLJS.wr,!0],null),OU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.HF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OD,new $CLJS.h(null,1,[$CLJS.wr,!0],null),cV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gV,new $CLJS.h(null,1,[$CLJS.wr,!0],null),aV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MQ,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sK],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dO,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MU,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RJ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.ps,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Cd(a,$CLJS.GP)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,
new $CLJS.h(null,1,[$CLJS.ps,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Oe($CLJS.cD($CLJS.dO,$CLJS.MU))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eV],null)],null));$CLJS.Y(RU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LJ],null)],null)],null)],null));
$CLJS.Y(SU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RJ],null)],null)],null)],null));$CLJS.Y(VU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SU],null)],null));
$CLJS.Y(YU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.ps,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Oe($CLJS.vz($CLJS.dO,$CLJS.MU))],null)],null));$CLJS.Y(ZU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xq,$CLJS.$U,$CLJS.TU],null));
$CLJS.Y(Bma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,ZU],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.ui,$CLJS.Rs],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$U,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jV],null)],null)],null)],null));
$CLJS.Y(iV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,ZU],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.ui,$CLJS.Rs],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$U,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VU],null)],null)],null)],null));$CLJS.Y(UU,YU);
$CLJS.Y(hV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,2,[$CLJS.ps,"Valid references for all query stages",$CLJS.As,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.cj);return NU.h?NU.h(a):NU.call(null,a)}],null),$CLJS.Oe(NU)],null));
$CLJS.Y($CLJS.iK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UU],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hV],null)],null));
$CLJS.Y($CLJS.JK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.fV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,$CLJS.SJ,$CLJS.YJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iK],null)],null)],null),$CLJS.rma],null));