var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var bU,cU,dU,fU,gU,hU,iU,jU,kU,lU;bU=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);cU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);dU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.eU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);fU=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);gU=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);hU=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
iU=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);jU=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);kU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);lU=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.Y(bU,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ps,"Valid template tag :widget-type"],null),$CLJS.Zy],null),$CLJS.Ng($CLJS.XT)));$CLJS.Y(fU,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Xq,$CLJS.FL,$CLJS.uM,$CLJS.zE,$CLJS.Ls,$CLJS.tA,$CLJS.AO],null));
$CLJS.Y(iU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jD,$CLJS.oD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fi,new $CLJS.h(null,1,[$CLJS.wr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,$CLJS.oD,$CLJS.or],null)],null)],null));
$CLJS.Y(jU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.Vk],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WM,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.pr],null)],null)],null));
$CLJS.Y(gU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.zE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Li,new $CLJS.h(null,1,[$CLJS.wr,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zq,$CLJS.xj],null)],null)],null)],null));
$CLJS.Y(hU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.FL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rL,$CLJS.oD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SO,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.bK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TO,new $CLJS.h(null,1,[$CLJS.wr,!0],null),$CLJS.SJ],null)],null)],null));
$CLJS.Y(lU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cq,$CLJS.uM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aQ,$CLJS.RJ],null)],null)],null));$CLJS.Y(kU,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Xq],null),$CLJS.LT));
$CLJS.Y(cU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kU],null)],null)],null)],null));
$CLJS.Y(dU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fU],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.ui,$CLJS.Qi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uM,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rn,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cU],null)],null)],null)],null));
$CLJS.Y($CLJS.eU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.oD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.ps,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.U.h(b))},a)}],null)],null));